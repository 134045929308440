import { Module } from 'vuex';
import { TransactionStoreInterface } from '../../../../types/store/transactionStoreInterface';
import { TransactionInterface } from '../../../../types/transactionInterface';
import TransactionService from '../../../../services/management/transaction.service';

const getDefaultState = () => {
  return {
    list: [],
    total_transaction_amount: 0,
    listLoading: false,
  };
};

const transactionStore: Module<TransactionStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    listLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    listLoaded(state, payload: TransactionInterface[]) {
      state.list = payload;
    },
    totalsLoaded(state, payload: any) {
      state.total_transaction_amount = payload;
    },
  },
  actions: {
    getList({ commit }, data: any): any {
      commit('listLoading', true);
      return TransactionService.getList(data)
        .then((res: any) => {
          const { transactions, total_transaction_amount } = res.data;
          commit('listLoaded', transactions);
          commit('totalsLoaded', total_transaction_amount);
          return res;
        })
        .catch((error: any) => {
          return error;
        })
        .finally(() => {
          commit('listLoading', false);
        });
    },
    downloadCSV(context, data: any): any {
      return TransactionService.downloadCSV(data);
    },
  },
};

export default transactionStore;
