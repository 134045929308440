import ApiService from '../_helper/api.service';
import AdminApiResources from '../../constants/api/resources/wizard';

const HotelService = {
  getHotels(params: any) {
    return ApiService.get(AdminApiResources.hotels, {params: params});
  },

  getHotelList(params: any) {
    return ApiService.get(AdminApiResources.hotelList, {params});
  },

  getHotelDetails(params: any) {
    return ApiService.get(AdminApiResources.hotelDetails, {params: params});
  },

  toggleActive(params: any) {
    return ApiService.post(AdminApiResources.hotelActive, params);
  },

  togglePublished(params: {hotel_id: number; published: boolean}) {
    return ApiService.post(AdminApiResources.hotelPublished, params);
  },

  getGMOInfo(params: any) {
    return ApiService.get(AdminApiResources.paymentSystemGMO, {params});
  },

  activateGMO(data: any) {
    return ApiService.post(AdminApiResources.paymentSystemGMO, data);
  },
  updateLicenseType(params: any) {
    return ApiService.patch(AdminApiResources.updateLicenceType, 'patch', {params});
  },
  getPostingSuspension(params: any) {
    return ApiService.get(AdminApiResources.postingSuspension, {params});
  },
  updatePostingSuspension(params: any) {
    return ApiService.put(AdminApiResources.postingSuspension + '/' + params.id, params);
  },
};

export default HotelService;
