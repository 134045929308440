import Vue from 'vue';
import {BookingPaymentMethod} from '@/constants/paymentSystem';
import {BookingUserInterface} from '@/types/bookingInterface';

Vue.mixin({
  methods: {
    checkCustomerState(customer: BookingUserInterface, paymentMethod: BookingPaymentMethod): void {
      const guestData: BookingUserInterface = {...customer};
      ['invitation_code', 'zip_code', 'surname'].forEach((value) => {
        if (guestData.hasOwnProperty(value)) {
          delete guestData[value as keyof BookingUserInterface];
        }
      });
      if (
        !paymentMethod ||
        !(
          guestData &&
          Object.keys(guestData).every(
            (el) =>
              guestData[el as keyof BookingUserInterface] !== '' &&
              guestData[el as keyof BookingUserInterface] !== null,
          )
        )
      ) {
        window.open(window.location.pathname.split('/').splice(0, 3).join('/') + '#' + window.location.hash, '_self');
      }
    },
  },
});
