import qs from 'qs';
import axios, { AxiosRequestConfig } from 'axios';

const CORSService = {
  get(resource: any, config?: AxiosRequestConfig) {
    return axios.get(resource, config);
  },
  post(resource: any, data: any = {}) {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return axios.post(resource, qs.stringify(data), config);
  },
};

export default CORSService;
