import ApiService from '../_helper/api.service';
import AdminApiResources from '../../constants/api/resources/wizard';

const PersonnelService = {
  getUsers(params: any) {
    return ApiService.get(AdminApiResources.personnel, { params: params });
  },
  deactivateMFA(data: any) {
    return ApiService.put(AdminApiResources.personnel + '/mfa_deactivate', data);
  },
};

export default PersonnelService;
