import ApiService from '../_helper/api.service';
import ManagementApiResources from '../../constants/api/resources/management';

const Beds24 = {
  getAuth(hotel_id: number) {
    return ApiService.get(ManagementApiResources.beds24Auth, {params: {hotel_id}});
  },
  updateAuth(data: {hotel_id: number; username: string; password: string}) {
    return ApiService.put(ManagementApiResources.beds24UpdateAuth, data);
  },
  verifyAuth(data: {username: string; password: string}) {
    return ApiService.post(ManagementApiResources.beds24VerifyAuth, data);
  },
  verifyRoomTypeCode(data: any) {
    return ApiService.post(ManagementApiResources.beds24VerifyRoomTypeCode, data);
  },
};

export default Beds24;
