import Vue from 'vue';
import {ApiTokenCodes} from '../../constants/apiResponseCodes';

function IsJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const NotificationsService = {
  setError: (error: any) => {
    let message = error.message;
    if (error.response && error.response.data) {
      if (IsJsonString(error.response.data)) {
        const data = JSON.parse(error.response.data);
        if (data && data.code && data.code === ApiTokenCodes.TokenExpired) {
          return;
        }
        if (data && data.message) {
          message = data.message;
        }
      }
    }
    Vue.notify({
      group: 'notify',
      title: error.name,
      text: message,
      type: 'error',
      duration: 30000,
    });
  },
  setMessage: (message: string, isSuccess: any) => {
    const typeAlert = isSuccess ? 'success' : 'error';
    Vue.notify({
      group: 'notify',
      text: message,
      type: typeAlert,
      duration: 30000,
    });
  },
};

export default NotificationsService;
