import {Module} from 'vuex';
import {RakutenInterface} from '@/types/api/wizard/rakuten/rakutenInterface';
import RakutenService from '@/services/wizard/rakuten.service';
import {GenericPaginationListInterface} from '@/types/api/common/paginationGroupInterface';
import {RakutenStoreInterface} from '@/types/store/wizard/rakuten/rakutenStoreInterface';
import {RakutenTypes} from '@/constants/pages/managament/wizzard/rakutenTypes';
import {CampaignStoreInterface} from '@/types/store/campaignStoreInterface';

const getDefaultState = (): RakutenStoreInterface => {
  return {
    rakuten: {
      data: [] as RakutenInterface[],
      total: 0,
      loading: true,
      page: 0,
    } as GenericPaginationListInterface<RakutenInterface>,
  };
};

const rakutenStore: Module<RakutenStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState(state: RakutenStoreInterface) {
      const s = getDefaultState();
      state = {...s};
    },
    listLoading(state: RakutenStoreInterface, payload: boolean) {
      state.rakuten.loading = payload;
    },
    listLoaded(state: RakutenStoreInterface, payload: {data: RakutenInterface[]; total: number}) {
      state.rakuten.data = payload.data;
      state.rakuten.total = payload.total;
    },
    setPagination(state: RakutenStoreInterface, payload: number) {
      state.rakuten.page = payload;
    },
  },
  actions: {
    getList({commit}, data: number) {
      commit('listLoading', true);
      return RakutenService.getList(data)
        .then(({data}) => {
          commit('listLoaded', data);
        })
        .finally(() => {
          commit('setPagination', data), commit('listLoading', false);
        });
    },

    createRakutenType({state, dispatch}, data: RakutenInterface) {
      return RakutenService.createRakutenType(data).then(() => {
        dispatch('getList', state.rakuten.page);
      });
    },

    updateRakutenType({state, dispatch}, data: RakutenInterface) {
      return RakutenService.updateRakutenType(data).then(() => {
        dispatch('getList', state.rakuten.page);
      });
    },

    activateRakutenType({state, dispatch}, data) {
      return RakutenService.activateRakutenType(data).then(() => {
        dispatch('getList', state.rakuten.page);
      });
    },

    deleteRakutenType({state, dispatch}, data: {id: number}) {
      return RakutenService.deleteRakutenType(data).then(() => {
        dispatch('getList', state.rakuten.page);
      });
    },
  },
};

export default rakutenStore;
