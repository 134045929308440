import ApiService from '../_helper/api.service';
import AdminApiResources from '../../constants/api/resources/wizard';
import { ApiScope } from '../../constants/apiScope';
import { TokenService } from '../_helper/storage.service';

const AuthService = {
  login(data: any, rememberMe: boolean) {
    return ApiService.post(AdminApiResources.signIn, data).then(res => {
      if (res.data['access_token']) {
        if (res.data.scope[0] === ApiScope.MFALoginAdmin) {
          TokenService.saveToken(ApiScope.MFALoginAdmin, res.data['access_token']);
        } else if (res.data.scope[0] === ApiScope.Admin) {
          TokenService.saveToken(ApiScope.Admin, res.data['access_token']);
          if (rememberMe && res.data['refresh_token']) {
            TokenService.saveRefreshToken(ApiScope.Admin, res.data['refresh_token']);
          }
          TokenService.removeScopeData(ApiScope.MFALoginAdmin);
        }
      }
      return res.data;
    });
  },

  authComplete(data: any, rememberMe: boolean) {
    TokenService.removeScopeData(ApiScope.MFALoginAdmin);
    if (data['access_token']) {
      TokenService.saveToken(ApiScope.Admin, data['access_token']);
    }
    if (rememberMe && data['refresh_token']) {
      TokenService.saveRefreshToken(ApiScope.Admin, data['refresh_token']);
    }
    return data;
  },

  authRevoke() {
    const refreshToken = TokenService.getRefreshToken(ApiScope.Admin);
    if (refreshToken) {
      ApiService.post(AdminApiResources.signOut, { token: refreshToken });
    }
    const token = TokenService.getToken(ApiScope.Admin);
    if (token) {
      ApiService.post(AdminApiResources.signOut, { token });
      TokenService.removeScopeData(ApiScope.Admin);
    }
    return Promise.resolve(true);
  },

  appRegistration(params: any, rememberMe: boolean) {
    return ApiService.post(AdminApiResources.appVerifyAndSave, params).then(({ data }) => {
      return this.authComplete(data, rememberMe);
    });
  },

  appVerify(params: any, rememberMe: boolean) {
    return ApiService.post(AdminApiResources.appAuth, params).then(({ data }) => {
      return this.authComplete(data, rememberMe);
    });
  },

  phoneRegistration(params: any, rememberMe: boolean) {
    return ApiService.post(AdminApiResources.smsVerifyAndSave, params).then(({ data }) => {
      return this.authComplete(data, rememberMe);
    });
  },

  phoneVerify(params: any, rememberMe: boolean) {
    return ApiService.post(AdminApiResources.smsAuth, params).then(({ data }) => {
      return this.authComplete(data, rememberMe);
    });
  },
};

export default AuthService;
