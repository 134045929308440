import ApiService from '../_helper/api.service';
import CORSService from '../_helper/cors.service';
import LandingApiResources from '../../constants/api/resources/landing';
import {CheckCampaignActivationCodeInterface} from '@/types/api/landing/checkCampaignActivationCodeInterface';

const BookingService = {
  createBooking(data: any) {
    return ApiService.post(LandingApiResources.createBooking, data);
  },
  paymentOptions(links: string[]) {
    return ApiService.post(LandingApiResources.paymentOptions, {
      links,
    });
  },
  createExpBooking(data: any) {
    return ApiService.post(LandingApiResources.createExpBooking, data);
  },
  verifyBooking(data: any) {
    const config = {params: data};
    return ApiService.get(LandingApiResources.verifyBooking, config);
  },
  showBookingDetails(data: any) {
    const config = {params: data};
    return ApiService.get(LandingApiResources.showBookingDetails, config);
  },
  rejectBookingRequest(id: any) {
    const config = {params: {request_hid: id}};
    return ApiService.delete(LandingApiResources.rejectBookingRequest, config);
  },
  authorizeCharge(data: any) {
    return ApiService.post(LandingApiResources.authorizeCharge, data);
  },
  passwordInputScreenGMO(url: string, data: any) {
    return CORSService.post(url, data);
  },
  checkCampaignActivationCode(data: CheckCampaignActivationCodeInterface) {
    return ApiService.post(LandingApiResources.checkCampaignActivationCode, data);
  },
  smsVerification(data: any) {
    return ApiService.post(LandingApiResources.smsVerification, data);
  },
  smsCodeVerification(data: any) {
    return ApiService.post(LandingApiResources.smsCodeVerification, data);
  },
  getCustomerData() {
    return ApiService.get(LandingApiResources.getCustomerData);
  },
};

export default BookingService;
