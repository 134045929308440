import {Module} from 'vuex';

import {AmenityTypes} from '@/constants/amenityTypes';

import {SettingsInterface} from '@/types/store/customer/settings/settingsInterface';
import {CustomerStoreInterface} from '@/types/store/customer/customerStoreInterface';
import {AmenityInterface} from '@/types/store/amenityInterface';

import searchStore from './modules/search';
import hotelStore from './modules/hotels';
import userStore from '@/store/modules/customer/modules/user';

const defaultState = {
  settings: {
    show_map: false,
    is_popup_open: false,
    locales: {},
    is_search_pending: false,
    ab_testing_layout: 0,
    prefectures: {},
    is_calendar_open: false,
  } as SettingsInterface,
};

const store: Module<CustomerStoreInterface, any> = {
  namespaced: true,
  modules: {
    search: searchStore,
    hotels: hotelStore,
    user: userStore,
  },
  state: defaultState,
  getters: {
    hotelAmenities({settings}): AmenityInterface[] {
      return settings.amenities.filter((f: AmenityInterface) => f.amenity_type === AmenityTypes.hotel_amenity);
    },
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = {...state.settings, ...settings};
    },
    setContextSettings(state, settings) {
      state.settings = {...state.settings, ...settings};
    },
    triggerMap(state, value: boolean) {
      state.settings.show_map = value;
    },
    togglePopup(state, value: boolean) {
      state.settings.is_popup_open = value;
    },
    setSearchPending(state, value: boolean) {
      state.settings.is_search_pending = value;
    },
    // remove after AB testing
    setLayoutVariant(state, payload: 0 | 1) {
      state.settings.ab_testing_layout = payload;
    },
    toggleCalendar(state, value: boolean) {
      state.settings.is_calendar_open = value;
    },
  },
  actions: {
    init({state, dispatch}) {
      const defaultKeys = Object.keys(defaultState.settings).sort();
      const currentKeys = Object.keys(state.settings).sort();
      defaultKeys.forEach((key: string) => {
        if (!currentKeys.includes(key)) {
          dispatch('clearAll');
          return;
        }
      });
    },
    restoreSearchData({dispatch, commit}) {
      dispatch('search/resetState');
      dispatch('hotels/resetState');
      commit('triggerMap', false);
      commit('togglePopup', false);
    },
    clearAll({dispatch, commit}) {
      dispatch('search/resetState');
      commit('hotels/resetState');
    },
  },
};

export default store;
