import ApiService from '../_helper/api.service';
import ManagementApiResources from '../../constants/api/resources/management';

const RoomService = {
  getRooms(params: any) {
    return ApiService.get(ManagementApiResources.roomTypes, {params: params});
  },

  getRoomAvailability(params: any) {
    return ApiService.get(ManagementApiResources.roomAvailability, {params: params});
  },

  updateRoomAvailability(data: any) {
    return ApiService.put(ManagementApiResources.roomAvailability, data);
  },

  updateInventorySettings(data: any) {
    return ApiService.put(ManagementApiResources.inventorySettings, data);
  },

  addRoomType(data: any) {
    return ApiService.post(ManagementApiResources.roomTypes, data);
  },

  updateRoomType(data: any) {
    return ApiService.put(ManagementApiResources.roomTypes + '/details', data);
  },

  sortRoomImages(room_type_id: number, image_order: number) {
    return ApiService.patch(ManagementApiResources.roomTypes + '/sort_images', {
      room_type_id: room_type_id,
      image_order: image_order,
    });
  },

  validateRoomType(data: any) {
    return ApiService.post(ManagementApiResources.verifyRoomTLLincoln, data);
  },

  archiveRoomType(data: any) {
    return ApiService.post(ManagementApiResources.archiveRoomType, data);
  },
};

export default RoomService;
