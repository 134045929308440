export enum PaymentSystem {
  Stripe = 'stripe',
  GMO = 'gmo',
  JTB = 'jtb',
  Combined = 'combined',
  PayOnArrival = 'pay-on-arrival',
}

export enum BookingPaymentMethod {
  PayOnArrival = 'pay_on_arrival',
  CreditCard = 'credit_card',
}
