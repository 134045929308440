






import Vue from 'vue';
import {mapMutations} from 'vuex';
import {ModalTemplateOptions} from '@/types/modals/modalTemplateOptions';
import {modalGeneralMixin} from '@/mixins/modalGeneralMixin';

declare module 'vue/types/vue' {
  interface Vue {
    modalOption: ModalTemplateOptions;
    setDataProvide(array: string[]): void;
    openPopupProvide(key: string): void;
    closePopupProvide(key: string): void;
    updateLoaderProvide(status: boolean): void;
  }
}
export default Vue.extend({
  name: 'CustomerLandingApp',
  mixins: [modalGeneralMixin],
  props: {
    appContext: {
      type: Object,
      default: {} as any,
    },
  },
  data: () => ({
    modalOption: {},
  }),
  provide() {
    return {
      modalOption: this.modalOption,
      setDataProvide: (array: string[]) => this.setModalDataMixin(array),
      openPopupProvide: (key: string) => this.openPopupMixin(key),
      closePopupProvide: (key: string) => this.closePopupMixin(key),
      updateLoaderProvide: (status: boolean) => this.updatePopupLoaderMixin(status),
    };
  },
  created(): void {
    this.$store.dispatch('calendar/changeMaterialLocation');
    const settings: any = {};
    this.setLayoutVariant(1);

    // if (this.appContext.google_optimize_experiment_id) {
    //   (window as any).gtag('event', 'optimize.callback', {
    //     name: this.appContext.google_optimize_experiment_id,
    //     callback: (value: string) => {
    // REMOVE IT
    //    ||
    //    ||
    //    ||
    // \      /
    //  \    /
    //   \  /
    //    \/
    // this.setLayoutVariant(1);
    // console.warn('OptimizeCallback: hardcode experiment#', 1);
    // this.setLayoutVariant(value);
    // console.warn('OptimizeCallback: experiment#', value);
    // },
    // });
    // }
    const keys = ['google_ads_tracking', 'google_autocomplete_api_key', 'criteo_tracking', 'found_hotels'];
    keys.forEach((key) => {
      if (this.appContext.hasOwnProperty(key)) {
        settings[key] = this.appContext[key];
      }
    });
    if (Object.keys(settings).length > 0) {
      this.$store.commit('customer/setContextSettings', settings);
    }
  },
  methods: {
    ...mapMutations({
      setLayoutVariant: 'customer/setLayoutVariant',
    }),
  },
});
