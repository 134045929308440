import {Module} from 'vuex';
import {PersonnelStoreInterface} from '../../../../types/store/personnelStoreInterface';
import {PersonnelHotelInterface, PersonnelInterface} from '../../../../types/personnelInterface';
import PersonnelService from '../../../../services/management/personnel.service';
import {TranslationInterface} from '@/types/translationInterface';

const getDefaultState = () => {
  return {
    list: [] as Array<PersonnelInterface>,
    listLoading: false,
    userLoading: false,
    user: {} as PersonnelInterface,
    notifications: [] as Array<PersonnelHotelInterface>,
    notificationsLoading: false,
  };
};

const personnelStore: Module<PersonnelStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    usersLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    userLoading(state, payload: boolean) {
      state.userLoading = payload;
    },
    usersLoaded(state, payload: PersonnelInterface[]) {
      state.list = payload;
    },
    getUserDetails(state, payload: PersonnelInterface) {
      state.user = payload;
    },
    setNotifications(state, payload: any) {
      state.notifications = payload;
    },
    notificationsLoading(state, payload: boolean) {
      state.notificationsLoading = payload;
    },
  },
  actions: {
    getUsers({commit}, params: any): any {
      commit('usersLoading', true);
      return PersonnelService.getUsers(params)
        .then(({data}: any) => {
          commit('usersLoaded', data);
          return data;
        })
        .finally(() => {
          commit('usersLoading', false);
        });
    },
    getUserDetails({commit}, data: any): any {
      commit('userLoading', true);
      return PersonnelService.getUserDetails(data)
        .then(({data}: any) => {
          commit('getUserDetails', data);
          return data;
        })
        .finally(() => {
          commit('userLoading', false);
        });
    },
    addUser(context, data: any): any {
      return PersonnelService.addUser(data);
    },
    updateUser(context, data: any): any {
      return PersonnelService.updateUser(data);
    },
    deleteUser(context, data: any): any {
      return PersonnelService.deleteUser(data);
    },
    deactivateMFA(context, data: any): any {
      return PersonnelService.deactivateMFA(data);
    },
    deactivateUser(context, data: any): any {
      return PersonnelService.deactivateUser(data);
    },
    activateUser(context, params: any): any {
      return PersonnelService.activateUser(params);
    },
    getPermissions(context, params: any): any {
      return PersonnelService.getPermissions(params);
    },
    getNotifications(
      {commit},
      data = {
        page: 1,
        per_page: 99,
      },
    ): any {
      commit('notificationsLoading', true);
      return PersonnelService.getNotifications(data)
        .then(
          ({
            data,
          }: {
            data: {hotels: {email_notifications: boolean; id: number; name: TranslationInterface}; total: number};
          }) => {
            commit('setNotifications', data.hotels);
            return data;
          },
        )
        .finally(() => {
          commit('notificationsLoading', false);
        });
    },
    updateNotifications(context, data: any): any {
      return PersonnelService.updateNotifications(data);
    },
  },
};

export default personnelStore;
