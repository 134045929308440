import ApiService from '../_helper/api.service';
import AdminApiResources from '../../constants/api/resources/wizard';

const HotelGroupService = {
  getHotelGroups(params: any = {}) {
    return ApiService.get(AdminApiResources.hotelGroups, {params: params});
  },

  getHotelGroupDetails(id: number) {
    return ApiService.get(AdminApiResources.hotelGroupDetails, {params: {id}});
  },

  getUngroupedHotelsList(params: any = {}) {
    return ApiService.get(AdminApiResources.ungroupedHotels, {params: params});
  },

  createHotelGroup(data: any) {
    return ApiService.post(AdminApiResources.hotelGroups, data);
  },

  updateHotelGroup(data: any) {
    return ApiService.put(AdminApiResources.hotelGroups + '/' + data.id, data);
  },

  removeHotel(groupId: any, hotelId: any) {
    return ApiService.delete(AdminApiResources.hotelGroupHotels.replace(':hotel_group_id', groupId) + '/' + hotelId);
  },

  delete(id: number) {
    return ApiService.delete(AdminApiResources.hotelGroups + '/' + id);
  },

  getInvoice(params: any) {
    return ApiService.get(
      AdminApiResources.hotelGroups + '/' + params.id + '/invoice/' + params.year + '/' + params.month,
    );
  },

  getReport(params: any) {
    return ApiService.get(AdminApiResources.hotelGroups + '/report/' + params.year + '/' + params.month);
  },

  resendInvoice(params: any) {
    return ApiService.post(
      AdminApiResources.hotelGroups + '/' + params.id + '/send_invoice/' + params.year + '/' + params.month,
    );
  },

  getInfo(id: number) {
    return ApiService.get(AdminApiResources.hotelGroups + '/' + id);
  },
};

export default HotelGroupService;
