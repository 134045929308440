import Vue from 'vue';
import {PurposeOfStayList} from '@/constants/purposeOfStayList';

export default Vue.mixin({
  methods: {
    translateText(key: string): string {
      let stayIn: string = '';
      const POS: string[] = Object.values(PurposeOfStayList);
      if (POS.includes(key)) {
        stayIn = String(this.$t('common.customer.purpose-of-stay-list.' + key));
      } else stayIn = key;
      return stayIn;
    },
  },
});
