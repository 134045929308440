import Vue from 'vue';
import moment from 'moment';

Vue.mixin({
  methods: {
    /**
     * Number of nights for booking
     * @return number
     **/
    nightsCount(checkin: string | undefined, checkout: string | undefined): number {
      if (!checkin || !checkout) {
        return 1;
      }
      return moment(checkout.split('/').join('-')).diff(checkin.split('/').join('-'), 'days');
    },
  },
});
