import Vue from 'vue';

export default Vue.mixin({
  methods: {
    prefectureName(code: string, prefectures: string[]): string {
      let result: string = '';
      const prefecturesList: string[] = Object.values(prefectures);
      prefecturesList.forEach((prefecture: string, index: number) => {
        if (index === Number(code)) {
          result = String(this.$i18n.t('common.prefecture.' + prefecture));
        }
      });
      return result;
    },
    prefectureCode(name: string, prefectures: string[]): string {
      let result: string = '';
      const prefecturesList: string[] = Object.values(prefectures);
      prefecturesList.forEach((prefecture: string, index: number) => {
        if (this.$i18n.t('common.prefecture.' + prefecture) === name) {
          result = String(index);
        }
      });
      return result;
    },
  },
});
