export enum GTagActions {
  search_button = 'search_button',
  change_stay_date = 'change_stay_date',
  search_button_list = 'search_button_list',
  search_list_pager = 'search_list_pager',
  search_list_hotel = 'search_list_hotel',
  view_search_results = 'view_search_results',
  view_search_noresults = 'view_search_noresults',
  search_sort = 'search_sort',
  search_filter = 'search_filter',
  search_filter_price = 'search_filter_price',
  search_filter_amenities = 'search_filter_amenities',
  /* smoking filter */
  search_filter_smoke = 'search_filter_smoke',
  search_filter_nonsmoke = 'search_filter_nonsmoke',
  /* hotel facilities */
  search_filter_wifi = 'search_filter_wifi',
  search_filter_laundry = 'search_filter_laundry',
  search_filter_kitchencorner = 'search_filter_kitchencorner',
  search_filter_parking = 'search_filter_parking',
  search_filter_bigbath = 'search_filter_bigbath',
  /* room amenities */
  search_filter_tv = 'search_filter_tv',
  search_filter_kitchen = 'search_filter_kitchen',
  search_filter_washingmachine = 'search_filter_washingmachine',
  search_filter_refrigerator = 'search_filter_refrigerator',
  search_filter_restroom = 'search_filter_restroom',
  /* map events */
  search_button_map = 'search_button_map',
  search_map_hotel = 'search_map_hotel',
  search_map_pager = 'search_map_pager',
  search_map_moved = 'search_map_moved',
  search_map_zoomout = 'search_map_zoomout',
  search_map_zoomin = 'search_map_zoomin',
  /* Hotel details events */
  HotelMap = 'hotel_map',
  HotelSearch = 'hotel_search',
  HotelNearest = 'hotel_nearhotel',
  RoomDetail = 'room_detail',
  /* Bowsing history events */
  HistoryTop = 'history_top',
  HistorySearch = 'history_search',
  HistoryHotel = 'history_hotel',
  HistoryTopView = 'history_top_view',
  HistorySearchView = 'history_search_view',
  HistoryHotelView = 'history_hotel_view',
  /* Favorites events */
  FavoriteTop = 'favorite_top',
  FavoriteSearch = 'favorite_search',
  FavoriteLanding = 'favorite_landing',
  FavoriteTopView = 'favorite_top_view',
  FavoriteSearchView = 'favorite_search_view',
  FavoriteLandingView = 'favorite_landing_view',
  FavoritePage = 'favorite_page',
  /* Auth events */
  SignUp = 'sign_up',
  Login = 'login',
}

export enum GTagCategories {
  Search = 'search',
  Engagement = 'engagement',
  HotelLanding = 'hotel_landing',
  General = 'general',
  Ecommerce = 'ecommerce',
  Public = 'public',
  KwPopularView = 'kw_popular_view',
  KwPopularClick = 'kw_popular_click',
  KwHistoryView = 'kw_history_view',
  KwHistoryClick = 'kw_history_click',
}

export enum FacilitiesActions {
  laundry = GTagActions.search_filter_laundry,
  parking = GTagActions.search_filter_parking,
  free_wifi = GTagActions.search_filter_wifi,
  bath_house = GTagActions.search_filter_bigbath,
  shared_kitchen = GTagActions.search_filter_kitchencorner,
  refrigerator = GTagActions.search_filter_refrigerator,
  kitchen = GTagActions.search_filter_kitchen,
  washing_machine = GTagActions.search_filter_washingmachine,
  tv = GTagActions.search_filter_tv,
  separated_bathroom_and_toilet = GTagActions.search_filter_restroom,
}
