import ApiService from '../_helper/api.service';
import ManagementApiResources from '../../constants/api/resources/management';
import FileService from '../_helper/file.service';

const BookingService = {
  getBookingsList(data: any) {
    return ApiService.get(ManagementApiResources.bookings, data);
  },
  cancelBooking(data: any) {
    return ApiService.post(ManagementApiResources.cancelBooking, data);
  },
  getChangeBooking(data: any) {
    return ApiService.get(ManagementApiResources.changeBooking, {params: data});
  },
  setChangeBooking(data: any) {
    return ApiService.post(ManagementApiResources.changeBooking, data);
  },
  getCancellationInfo(data: any) {
    return ApiService.get(ManagementApiResources.cancelBooking, data);
  },
  downloadBookingCSV(data: any) {
    return ApiService.get(ManagementApiResources.downloadCSV, {
      headers: {
        Accept: 'text/csv; charset=utf-8',
        'Content-Type': 'text/csv; charset=utf-8',
      },
      params: data,
      responseType: 'text',
    }).then((res) => {
      let fileName = 'bookings.csv';
      FileService.saveCSV(res.data, fileName);
      return res;
    });
  },
};

export default BookingService;
