import {Module} from 'vuex';
import CampaignService from '@/services/wizard/campaign.service';

import {CampaignStoreInterface} from '@/types/store/campaignStoreInterface';
import {CreateCampaignInterface} from '@/types/api/wizard/campaigns/createCampaignInterface';
import {CampaignItemInterface} from '@/types/api/wizard/campaigns/campaignItemInterface';
import {GenericPaginationListInterface, PaginationGroupInterface} from '@/types/api/common/paginationGroupInterface';
import {
  BudgetHistoryInterface,
  BudgetInterface,
  HistoryItemInterface,
} from '@/types/api/wizard/campaigns/budgetInterface';
import {BookingItemInterface} from '@/types/api/wizard/campaigns/bookingItemInterface';

const getDefaultState = (): CampaignStoreInterface => {
  return {
    campaigns: {
      data: [],
      total: 0,
      loading: false,
    },
    bookings: {
      data: [],
      total: 0,
      loading: false,
    },
  };
};

const campaignStore: Module<CampaignStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: CampaignStoreInterface) {
      const s = getDefaultState();
      state = {...s};
    },
    campaignsLoaded(state: CampaignStoreInterface, payload: GenericPaginationListInterface<CampaignItemInterface>) {
      state.campaigns = payload;
    },
    bookingsLoaded(state: CampaignStoreInterface, payload: GenericPaginationListInterface<BookingItemInterface>) {
      state.bookings = payload;
    },
    campaignsLoading(state: CampaignStoreInterface, payload: boolean) {
      state.campaigns.loading = payload;
    },
    bookingsLoading(state: CampaignStoreInterface, payload: boolean) {
      state.bookings.loading = payload;
    },
  },
  actions: {
    getCampaignList({commit}, page: number) {
      commit('campaignsLoading', true);
      return CampaignService.getCampaign(page)
        .then(({data}: {data: GenericPaginationListInterface<CampaignItemInterface>}) => {
          commit('campaignsLoaded', {...data, loading: false});
        })
        .catch((error: any) => {
          commit('campaignsLoading', false);
          return error;
        });
    },
    getCampaignsBookings({commit}, data: PaginationGroupInterface) {
      commit('bookingsLoading', true);
      return CampaignService.getCampaignsBookings(data)
        .then(({data}: {data: GenericPaginationListInterface<BookingItemInterface>}) => {
          commit('bookingsLoaded', {...data, loading: false});
        })
        .catch((error: any) => {
          commit('bookingsLoading', false);
          return error;
        });
    },
    getCampaignDetails(_context, campaign_id: number) {
      return CampaignService.getCampaignsDetails(campaign_id)
        .then(({data}: {data: CampaignItemInterface}) => data)
        .catch((error) => error);
    },
    getBudget(_context, campaign_id: number) {
      return CampaignService.getBudget(campaign_id)
        .then(({data}: {data: BudgetInterface}) => data)
        .catch((error: any) => error);
    },
    getBudgetHistoryList(_context, data: BudgetHistoryInterface) {
      return CampaignService.getBudgetHistoryList(data).then(
        ({data}: {data: GenericPaginationListInterface<HistoryItemInterface>}) => {
          return data;
        },
      );
    },
    changeBudget(_context, data: {campaign_id: number; new_budget: number}) {
      return CampaignService.changeBudget(data);
    },
    createCampaign(_context, data: CreateCampaignInterface) {
      return CampaignService.createCampaign(data);
    },
    editCampaign(_context, data: CreateCampaignInterface) {
      return CampaignService.editCampaign(data);
    },
    deleteCampaign(_context, campaign_id: number) {
      return CampaignService.deleteCampaign(campaign_id);
    },
    campaignsActivation(_context, data: {campaign_id: number; is_active: boolean}) {
      return CampaignService.campaignsActivation(data);
    },
    downloadCSV() {
      return CampaignService.downloadCSV();
    },
  },
};

export default campaignStore;
