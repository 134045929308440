import {Module} from 'vuex';
import {HotelInterfaceDashboard} from '@/types/hotelInterface';
import HotelService from '@/services/landing/hotel.service';

const getDefaultState = () => {
  return {
    address: {
      en: '',
      ja: '',
    },
    name: {
      en: '',
      ja: '',
    },
    hotel_hid: '',
    logo_url: '',
    phone: '',
    updated_at: '',
    checkin_from: '',
    checkin_until: '',
    checkout_until: '',
    cancellation_policy: {},
    max_room_types_capacity: 0,
  } as HotelInterfaceDashboard;
};

const hotelStore: Module<HotelInterfaceDashboard, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = {...getDefaultState()};
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setHotel(state, hotel) {
      state = Object.assign(state, hotel);
    },
  },
  actions: {
    resetStore({commit}, data) {
      if (data && data.current_hotel) {
        commit('setHotel', data.current_hotel);
      }
    },
    updateHotel({commit}, data) {
      commit('setHotel', data);
    },
    getCancellationPolicy(context, id) {
      return HotelService.showCancellationPolicy(id);
    },
  },
};

export default hotelStore;
