const LandingApiResources = {
  hotelInfo: 'landing/hotel_info',
  hotelReview: 'landing/hotel_reviews',
  cancellationPolicy: 'landing/cancellation_policy',
  createBooking: 'landing/booking_request',
  paymentOptions: 'landing/payment_options',
  createExpBooking: 'landing/booking_request_expedia',
  authorizeCharge: 'landing/authorize_charge',
  rejectBookingRequest: 'landing/reject_booking_request',
  roomTypeInfo: 'landing/room_types',
  roomAvailability: 'landing/availability_by_day',
  expediaRoomAvailability: 'landing/expedia_availability_by_day',
  gmoLegalInfo: 'landing/gmo_legal_information',
  verifyBooking: 'landing/verify_email',
  showBookingDetails: 'landing/booking_request_details',
  smsVerification: 'landing/sms_verification/send',
  smsCodeVerification: 'landing/sms_verification/test',
  checkCampaignActivationCode: 'landing/check_campaign_activation_code',
  recommendedHotels: 'landing/recommended_hotels',
  placesPredictions: 'landing/places/predict_mix',
  getCustomerData: 'landing/customer_data',
  roomData: 'landing/room_data',
  geocoding: 'landing/geocoding',
};
export default LandingApiResources;
