import Vue from 'vue';
import {GTagActions, GTagCategories} from '@/constants/gtagActions';

declare global {
  interface Window {
    gtag(event: string, action: string, params: {event_category: string; event_label: string | number} | any): void;
  }
}

export default Vue.mixin({
  methods: {
    hotelGTagSwitcher(gTag: GTagActions): void {
      switch (gTag) {
        case GTagActions.HotelNearest:
          this.gtagEventTrigger(gTag, 'click', GTagCategories.HotelLanding);
          break;
        case GTagActions.HistoryTop:
        case GTagActions.HistorySearch:
        case GTagActions.HistoryHotel:
          this.gtagEventTrigger('click', 'click', gTag);
          break;
        case GTagActions.FavoriteTop:
        case GTagActions.FavoriteSearch:
        case GTagActions.FavoriteLanding:
        case GTagActions.FavoritePage:
          this.gtagEventTrigger('click', 'click', gTag);
          break;
        default:
          break;
      }
    },
    gtagEventTrigger(action: string, label: string | number = 'click', category = GTagCategories.Search): void {
      if (window && typeof window.gtag !== 'undefined') {
        window.gtag('event', action, {
          event_category: category,
          event_label: label,
        });
      }
    },
  },
});
