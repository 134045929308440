import Vue from 'vue';

Vue.mixin({
  methods: {
    prepareFormData(formData: FormData, data: any, previousKey: string = ''): any {
      if (data instanceof Object) {
        Object.keys(data).forEach((key) => {
          const value = data[key];
          const self: any = this;
          if (previousKey) {
            key = `${previousKey}[${key}]`;
          }
          if (value instanceof Object && !Array.isArray(value)) {
            if (value.constructor !== File) {
              return self.prepareFormData(formData, value, key);
            }
          }
          if (Array.isArray(value)) {
            value.forEach((val) => {
              const newKey = `${key}[]`;
              if (val instanceof Object) {
                if (val.constructor !== File) {
                  return self.prepareFormData(formData, val, newKey);
                }
              }
              if (val) {
                formData.append(newKey, val);
              }
            });
          } else if (value !== undefined) {
            formData.append(key, value);
          }
        });
      }
    },
  },
});
