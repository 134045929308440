import {Module} from 'vuex';
import {
  UserStoreInterface,
  UserDataInterface,
  UserDataPresetInterface,
  HotelDetailsInterface,
} from '@/types/store/customer/user/userStoreInterface';
import CustomerService from '@/services/customer/customer.service';
import moment from 'moment';
import {PaginationParams} from '@/types/pages/common/paginationParams';
import {EnquiryFormInterface} from '@/types/pages/customer/enquiryFormInterface';
import {HotelInterfaceCustomer} from '@/types/hotelInterface';
import {AutocompleteAddressInterface} from '@/types/store/customer/search/autocompleteAddressInterface';

const initialForm = {
  id: null,
  name: '',
  gender: '',
  email: '',
  phone: '',
  invitation_code: '',
  address: null,
  prefecture_id: null, //prefecture_id for customer profile data, same as prefecture_code for bookings,
  is_email_verified: false,
  is_phone_verified: false,
  is_allow_campaign_notification: false,
} as UserDataInterface;

const getDefaultState = (): UserStoreInterface => {
  return {
    isLoggedIn: false,
    email_verify_token: null,
    data: {...initialForm} as UserDataInterface,
    total_points: 0,
    points: {
      fixed_point_for_inviting: 0,
      fixed_point_for_joining: 0,
    },
    favouriteHotelsHids: [] as string[],
    favouriteHotels: [],
    hotelsHistory: {
      data: [],
      loading: false,
      total: 0,
    },
    autocompleteAddress: [] as AutocompleteAddressInterface[],
    loading: true,
  };
};

const userStore: Module<UserStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    logIn(state) {
      state.isLoggedIn = true;
    },
    logOut(state: any) {
      const s = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key as keyof UserStoreInterface];
      });
    },
    setUserData(state: UserStoreInterface, payload: UserDataInterface) {
      Object.keys(payload).forEach((key) => {
        state.data[key] = payload[key] === null ? '' : payload[key];
      });
    },
    pushNewAddress(state: UserStoreInterface, payload: AutocompleteAddressInterface) {
      if (state.autocompleteAddress.findIndex((item) => item.place_id === payload.place_id) !== -1) {
        return;
      }
      if (state.autocompleteAddress.length === 5) {
        state.autocompleteAddress.splice(0, 1);
      }
      state.autocompleteAddress.push(payload);
    },
    setTotalPoints(state: UserStoreInterface, payload: number) {
      state.total_points = payload;
    },
    checkYourEmail(state: UserStoreInterface, payload: boolean) {
      state.email_verify_token = payload ? null : moment().add(5, 'minutes');
    },
    checkEmailExpiredToken(state: UserStoreInterface) {
      if (state.email_verify_token && moment().isAfter(moment(state.email_verify_token))) {
        state.email_verify_token = null;
      }
    },
    setPhoneVerification(state: any, payload: boolean) {
      state.data.is_phone_verified = payload;
    },
    loading(state: UserStoreInterface, payload: boolean) {
      state.loading = payload;
    },
    setPointsForInviteAndJoin(state: UserStoreInterface, payload: {[key: string]: number}) {
      state.points = payload;
    },
    setFavouritesList(state: UserStoreInterface, payload: string[]) {
      state.favouriteHotelsHids = payload;
    },
    updateFavouritesList(state: UserStoreInterface, payload: []) {
      state.favouriteHotels = payload;
    },
    setHotelToHistory(state, payload) {
      state.hotelsHistory.data.unshift(payload);
    },
    deleteHotelInHistory(state, payload) {
      state.hotelsHistory.data.splice(payload, 1);
    },
    updateHotelHistoryCreatedAt(state, payload) {
      state.hotelsHistory.data[payload].created_at = moment().format();
      if (state.hotelsHistory.data.length > 1) {
        state.hotelsHistory.data.unshift(...state.hotelsHistory.data.splice(payload, 1));
      }
    },
    updateHotelHistory(state, payload) {
      state.hotelsHistory.data = payload;
    },
    addFavorite(state, payload: string) {
      const hotel = {
        hotel_hid: payload,
        created_at: moment().format(),
      };
      if (state.favouriteHotels.length > 19) {
        state.favouriteHotels.pop();
      }
      state.favouriteHotels.unshift(hotel);
    },
    deleteFavorite(state, payload: string) {
      state.favouriteHotels.splice(state.favouriteHotels.map((hotel) => hotel.hotel_hid).indexOf(payload), 1);
    },
  },
  getters: {
    isHotelInHistory: (state) => () => {
      return state.hotelsHistory.data.map((item: HotelDetailsInterface) => item.hotel_hid);
    },
    getterFavoriteHotelsHid: (state) => () => {
      return state.favouriteHotels.map((item: HotelDetailsInterface) => item.hotel_hid);
    },
  },
  actions: {
    signIn({commit, dispatch}, data: {email: string; password: string}): any {
      return CustomerService.customerSignIn(data).then(async () => {
        await dispatch('getUserData').then(() => {
          commit('logIn');
        });
      });
    },
    getHotelsHistory(context, data: {hotel_hids: HotelInterfaceCustomer[]}) {
      return CustomerService.getHotelsHistory(data);
    },
    addHotelToHistory({commit}, hotelData: HotelInterfaceCustomer) {
      commit('setHotelToHistory', hotelData);
    },
    removeHotelInHistory({commit}, hotelData: HotelInterfaceCustomer) {
      commit('deleteHotelInHistory', hotelData);
    },
    updateHotelHistory({commit}, index: number) {
      commit('updateHotelHistoryCreatedAt', index);
    },
    signUpOnBooking(context, data: UserDataPresetInterface): any {
      return CustomerService.signUpOnBooking(data);
    },
    signUp(context, data: any): any {
      return CustomerService.customerSignUp(data);
    },

    /*hotels history*/

    getHotelsHistoryAuthorized(context, data: any) {
      return CustomerService.getHotelsHistoryAuthorized(data);
    },

    addHotelToHistoryAuthorized(context, data: {hotel_hids: []}) {
      return CustomerService.addHotelToHistoryAuthorized(data);
    },

    removeHotelFromHistoryAuthorized(context, data: {hotel_hid: string}) {
      return CustomerService.removeHotelFromHistoryAuthorized(data);
    },

    customerRegistration(context, data: {email: string}): any {
      return CustomerService.customerRegistration(data.email);
    },
    getEmailByLink(context, data: {confirmation_token: string}): any {
      return CustomerService.getEmailByLink(data.confirmation_token);
    },
    customerCompleteRegistration(context, data: UserDataPresetInterface): any {
      return CustomerService.customerCompleteRegistration(data);
    },
    signOut({commit}): any {
      return CustomerService.authRevoke().then(() => {
        commit('logOut');
      });
    },
    sendInstruction(context, data: {email: string}): any {
      return CustomerService.sendResetInstruction(data.email);
    },
    profileUpdate({commit}, data: UserDataInterface): any {
      return CustomerService.profileUpdate(data).then(({data}: {data: UserDataInterface}) => {
        commit('setUserData', data);
        return data;
      });
    },
    profilePasswordUpdate(
      context,
      data: {current_password: string; new_password: string; confirm_password: string},
    ): any {
      return CustomerService.profilePasswordUpdate(data);
    },
    updatePassword(context, data: {token: string; password: string}): any {
      return CustomerService.updatePassword(data);
    },
    getUserData({commit}): any {
      commit('loading', true);
      return CustomerService.getUserData().then(({data}: {data: UserDataInterface}) => {
        commit('setUserData', data);
        commit('loading', false);
        return data;
      });
    },
    getInvitation({commit, dispatch}): any {
      return (
        dispatch('getRakutenHistory', {params: {page: 1}}).then(({data}) => {
          commit('setTotalPoints', data.total_points);
        }) && dispatch('getUserData')
      );
    },
    verifyInvitationCode(context, data: string) {
      return CustomerService.verifyInvitationCode(data);
    },
    deleteAccount({commit}): any {
      return CustomerService.deleteAccount().then(() => {
        commit('logOut');
      });
    },
    getBookingDetails(context, data: string) {
      return CustomerService.getBookingDetails(data);
    },
    getBookings(context, data: {params: PaginationParams}): any {
      return CustomerService.getBookings(data);
    },
    getRakutenHistory(context, data: {params: PaginationParams}) {
      return CustomerService.getRakutenHistory(data);
    },
    verifyEmailCustomer(context, data: {email: string; code: string}) {
      return CustomerService.verifyEmailCustomer(data);
    },
    emailCustomerCodeVerification({commit, dispatch}, data: {email: string; code: string}): any {
      return CustomerService.emailCustomerCodeVerification(data).then(async () => {
        await dispatch('getUserData').then(() => {
          commit('logIn');
        });
      });
    },
    emailCustomerResendVerification(context, data: {email: string}): any {
      return CustomerService.emailCustomerResendVerification(data.email);
    },
    accountCreationRevoke(context, data: {email: string}) {
      return CustomerService.accountCreationRevoke(data.email);
    },
    smsCustomerVerification({commit}, data: {phone: string}): any {
      return CustomerService.smsCustomerVerification(data.phone);
    },
    smsCustomerCodeVerification({commit}, data: {phone: string; code: string}): any {
      return CustomerService.smsCustomerCodeVerification(data);
    },
    getPoinsForInviteAndJoin({commit}) {
      return CustomerService.getPoinsForInviteAndJoin().then(({data}) => {
        const res = {
          fixed_point_for_inviting: 0,
          fixed_point_for_joining: 0,
        } as {[key: string]: number};
        res.fixed_point_for_inviting = data.fixed_point_for_inviting ? data.fixed_point_for_inviting.points : 0;
        res.fixed_point_for_joining = data.fixed_point_for_joining ? data.fixed_point_for_joining.points : 0;
        commit('setPointsForInviteAndJoin', res);
      });
    },
    sendEnquiryForm({commit}, data: EnquiryFormInterface) {
      return CustomerService.sendEnquiryForm(data);
    },
    addFavourite({dispatch, state, commit}, data: {hotel_hids: []}) {
      if (state.isLoggedIn) {
        return CustomerService.addFavourite(data).then(() => {
          dispatch('getFavouritesHid');
        });
      } else {
        commit('addFavorite', data);
      }
    },
    deleteFavourite({dispatch, state, commit}, data: string) {
      if (state.isLoggedIn) {
        return CustomerService.deleteFavourite(data).then(() => {
          dispatch('getFavouritesHid');
        });
      } else {
        commit('deleteFavorite', data);
      }
    },
    checkExpireFavorite({commit, dispatch, state}) {
      commit(
        'updateFavouritesList',
        state.favouriteHotels.filter((f) => f.hasOwnProperty('hotel_hid')),
      );
      state.favouriteHotels.forEach((el) => {
        moment(el.created_at).add(1, 'month').isBefore(moment()) && dispatch('deleteFavourite', el.hotel_hid);
      });
    },
    getFavouritesList({commit}, data: {page: number; per_page: number}) {
      return CustomerService.getFavouritesList(data).then(({data}) => {
        return data;
      });
    },
    getFavouritesHid({commit}) {
      return CustomerService.getFavouritesHid().then(({data}) => {
        commit('setFavouritesList', data);
      });
    },
  },
};

export default userStore;
