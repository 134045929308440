import qs from 'qs';
import axios, {AxiosRequestConfig} from 'axios';
import ApiBaseUrl from '../../constants/api/apiBaseURL';
import CustomerApiResources from '@/constants/api/resources/customer';
import ManagementApiResources from '../../constants/api/resources/management';
import AdminApiResources from '../../constants/api/resources/wizard';
import {ApiScope} from '../../constants/apiScope';
import {ApiTokenCodes, ApiUnavailableReasonCodes} from '../../constants/apiResponseCodes';
import {TokenService} from './storage.service';
import NotificationsService from './notifications.service';
import store from '@/store';

const axiosInstance = axios.create({
  baseURL: ApiBaseUrl,
  timeout: 60 * 1000,
  withCredentials: true,
  transformResponse: [
    function (data) {
      return data;
    },
  ],
});

function checkActivationCode(url: string = ''): boolean {
  const requestScope: Array<string> = url.split(window.location.origin);
  return !!requestScope.length && requestScope[1] === '/api/landing/check_campaign_activation_code';
}

function getScope(url: string = ''): ApiScope {
  url = url.replace(ApiBaseUrl, '');
  const parts = url.split('/');
  return parts[0] as ApiScope;
}

function isSecurityScope(url: string = ''): boolean {
  url = url.replace(ApiBaseUrl, '');
  const parts = url.split('/');
  return (parts[1] as string) === 'security_mfa' && (parts[2] as string) !== 'index';
}

function logout(scope: string) {
  if (store.state.management.impersonate && store.state.management.impersonate.isLoggedIn) {
    store.dispatch('management/user/stopImpersonate');
  } else {
    const dispatchPath: string = `${scope === ApiScope.Landing ? 'customer' : scope}/user/signOut`;
    store.dispatch(dispatchPath).then(() => {
      switch (scope) {
        case ApiScope.Wizard:
          location.reload();
          break;
        case ApiScope.Landing:
          const pathScope: string[] = window.location.pathname.split('/');
          if (pathScope.length && pathScope.length > 2) {
            window.open(window.location.origin + '/landing/' + pathScope[2], '_self', 'noopener');
          }
          break;
        case ApiScope.Customer:
          window.open('/', '_self');
          break;
        default:
      }
    });
  }
}

axiosInstance.interceptors.request.use((config) => {
  const scope = getScope(config.url);
  const useCustomerToken = [
    'booking_request',
    'booking_request_expedia',
    'search',
    'customer_data',
    'room_types',
    'cheapest_room_rate_by_day',
    'availability_by_day',
  ].some((value) => config.url!.includes(value));
  let token = null;
  if (scope === ApiScope.Management) {
    if (TokenService.hasScopeData(ApiScope.ImpersonateSession)) {
      token = TokenService.getToken(ApiScope.ImpersonateSession);
    } else if (TokenService.hasScopeData(ApiScope.MFALoginManager)) {
      token = TokenService.getToken(ApiScope.MFALoginManager);
    } else if (TokenService.hasScopeData(ApiScope.ManagerSecurity) && isSecurityScope(config.url)) {
      token = TokenService.getToken(ApiScope.ManagerSecurity);
    } else {
      token = TokenService.getToken(ApiScope.Manager);
    }
  } else if (scope === ApiScope.Wizard) {
    if (TokenService.hasScopeData(ApiScope.MFALoginAdmin)) {
      token = TokenService.getToken(ApiScope.MFALoginAdmin);
    } else if (TokenService.hasScopeData(ApiScope.AdminSecurity) && isSecurityScope(config.url)) {
      token = TokenService.getToken(ApiScope.AdminSecurity);
    } else {
      token = TokenService.getToken(ApiScope.Admin);
    }
  } else if (scope === ApiScope.Customer || useCustomerToken) {
    token = TokenService.getToken(ApiScope.Customer);
  }
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  config.paramsSerializer = (params) => {
    return qs.stringify(params, {arrayFormat: 'brackets'});
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && (!response.config.responseType || response.config.responseType === 'json')) {
      response.data = JSON.parse(response.data);
    }
    if (response.data && response.data.message) {
      NotificationsService.setMessage(response.data.message, response.data.success);
    }
    return response;
  },
  (error) => {
    let scope = getScope(error.config.url);
    const status = error.response ? error.response.status : null;
    if (checkActivationCode(error.config.url)) {
      return Promise.reject(error);
    }
    const data = JSON.parse(error.response.data) ? JSON.parse(error.response.data) : null;
    switch (status) {
      case 401:
        if (error.response.data) {
          try {
            if (data && data.code) {
              if (
                data.code === ApiTokenCodes.TokenRevoked ||
                data.code === ApiTokenCodes.TokenUnknown ||
                data.code === ApiTokenCodes.InvalidScope
              ) {
                TokenService.removeScopeData(scope);
                logout(scope);
                return Promise.reject(error);
              }
              if (data.code !== ApiTokenCodes.TokenExpired) {
                return Promise.reject(error);
              }
            }
          } catch (e) {
            return Promise.reject(error);
          }
        }
        if (error.config && !error.config.__isRetryRequest) {
          if (scope === ApiScope.Management && TokenService.hasScopeData(ApiScope.ImpersonateSession)) {
            scope = ApiScope.ImpersonateSession;
          } else if (scope === ApiScope.Management && !TokenService.hasScopeData(ApiScope.ImpersonateSession)) {
            scope = ApiScope.Manager;
          } else if (scope === ApiScope.Wizard) {
            scope = ApiScope.Admin;
          } else if (scope === ApiScope.Landing && TokenService.hasScopeData(ApiScope.Customer)) {
            scope = ApiScope.Customer;
          }
          const refreshToken = TokenService.getRefreshToken(scope);
          if (!refreshToken) {
            if (scope === ApiScope.Manager) {
              if (TokenService.hasScopeData(ApiScope.MFALoginManager)) {
                TokenService.removeScopeData(ApiScope.MFALoginManager);
              } else if (TokenService.hasScopeData(ApiScope.ManagerSecurity)) {
                TokenService.removeScopeData(ApiScope.ManagerSecurity);
              }
            } else if (scope === ApiScope.Admin) {
              if (TokenService.hasScopeData(ApiScope.MFALoginAdmin)) {
                TokenService.removeScopeData(ApiScope.MFALoginAdmin);
              } else if (TokenService.hasScopeData(ApiScope.AdminSecurity)) {
                TokenService.removeScopeData(ApiScope.AdminSecurity);
              }
            } else if (scope === ApiScope.Customer) {
              TokenService.removeScopeData(ApiScope.Customer);
            }
            TokenService.removeScopeData(scope);
            location.reload();
            return Promise.reject(error);
          }
          const data = {
            refresh_token: refreshToken,
          };
          let url;
          switch (scope) {
            case ApiScope.Admin:
              url = AdminApiResources.refreshAdminToken;
              break;
            case ApiScope.Manager:
              url = ManagementApiResources.refreshManagerToken;
              break;
            case ApiScope.ImpersonateSession:
              url = ManagementApiResources.refreshImpersonateToken;
              break;
            case ApiScope.Customer:
              url = CustomerApiResources.refreshCustomerToken;
              break;
            default:
              url = ManagementApiResources.refreshManagerToken;
          }
          return axiosInstance.post(url, data).then(
            ({data: {access_token, refresh_token}}) => {
              if (access_token) {
                TokenService.saveToken(scope, access_token);
              }
              if (refresh_token) {
                TokenService.saveRefreshToken(scope, refresh_token);
              }
              error.config.__isRetryRequest = true;
              error.config.params = {...error.config.params, ...{access_token: access_token}};
              return axiosInstance.request(error.config);
            },
            (refreshError) => Promise.reject(refreshError),
          );
        }
        break;
      case 413:
        if (
          error.config.url.indexOf('api/management/hotels') !== -1 ||
          error.config.url.indexOf('api/management/room_types') !== -1
        ) {
          const message: string = '一度アップロード写真のサイズが大きいすぎるため、失敗しました。';
          NotificationsService.setMessage(message, false);
        } else {
          NotificationsService.setError(error);
        }
        break;
      case 404: {
        const errorResponse = JSON.parse(error.response.data);
        if (
          [ApiUnavailableReasonCodes.InvalidPassword, ApiUnavailableReasonCodes.UnknownCustomerEmail].includes(
            errorResponse.code,
          )
        ) {
          return Promise.reject(error);
        } else {
          NotificationsService.setError(error);
        }
        break;
      }
      case 403: {
        const errorResponse = JSON.parse(error.response.data);
        if ([ApiUnavailableReasonCodes.CustomerNotConfirmedEmail].includes(errorResponse.code)) {
          return Promise.reject(error);
        } else {
          NotificationsService.setError(error);
        }
        break;
      }
      default:
        NotificationsService.setError(error);
    }
    return Promise.reject(error);
  },
);

const ApiService = {
  removeHeader() {
    axiosInstance.defaults.headers.common = {};
  },

  get(resource: any, config?: AxiosRequestConfig) {
    return axiosInstance.get(resource, config);
  },

  post(resource: any, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance.post(resource, data, config);
  },

  put(resource: any, data: any, config?: AxiosRequestConfig) {
    return axiosInstance.put(resource, data, config);
  },

  patch(resource: any, data: any, config?: AxiosRequestConfig) {
    return axiosInstance.patch(resource, data, config);
  },

  delete(resource: any, config?: AxiosRequestConfig) {
    return axiosInstance.delete(resource, config);
  },

  customRequest(data: any) {
    return axiosInstance(data);
  },
};

export default ApiService;
