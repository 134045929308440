







import Vue from 'vue';
import LoaderResponse from '@/components/common/LoaderResponse.vue';

export default Vue.extend({
  name: 'CustomButton',
  components: {LoaderResponse},
  props: {
    isBtnLoading: {
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
    },
    isRipple: {
      type: Boolean,
      default: true,
    },
    isRaised: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick(event: any) {
      if (this.isRipple) {
        const button = event.currentTarget;

        const circle = document.createElement('span');
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
        circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
        circle.classList.add('ripple');
        const ripple = button.getElementsByClassName('ripple')[0];

        if (ripple) {
          ripple.remove();
        }

        button.appendChild(circle);
        setTimeout(() => {
          this.$emit('click');
        }, 300);
      } else {
        this.$emit('click');
      }
    },
  },
});
