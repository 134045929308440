import {Module} from 'vuex';
import {UserStoreInterface} from '../../../../types/store/userStoreInterface';
import {PermissionsInterface} from '../../../../types/permissionsInterface';

const defaultState = {
  isLoggedIn: false,
  paymentSystem: undefined,
  paymentMethods: undefined,
  paymentSystemConnect: false,
  source: undefined,
  companyName: undefined,
  permissions: undefined,
};

const impersonateStore: Module<UserStoreInterface, any> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    resetState(state: any) {
      const s: any = {...defaultState};
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setUserData(state: any, payload: any) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    setPermissions(state, payload: PermissionsInterface) {
      state.permissions = payload;
    },
  },
  actions: {
    init({state, dispatch}) {
      const defaultKeys = Object.keys(defaultState).sort();
      const currentKeys = Object.keys(state).sort();
      defaultKeys.forEach((key: string) => {
        if (!currentKeys.includes(key)) {
          dispatch('resetState');
        }
      });
    },
    setUserData({dispatch, commit}, payload: any) {
      return dispatch('management/personnel/getPermissions', null, {root: true}).then(({data}) => {
        return new Promise((resolve, reject) => {
          commit('setPermissions', data);
          commit('setUserData', {
            isLoggedIn: true,
            paymentSystem: payload.payment_system,
            paymentMethods: payload.payment_methods,
            paymentSystemConnect: !!payload.payment_system_connect,
            source: payload.source,
            companyName: payload.company_name,
          });
          resolve();
        });
      });
    },
  },
};

export default impersonateStore;
