export enum CampaignTypes {
  go_to = 'go_to',
  percentage_due_to_usage_commission = 'percentage_due_to_usage_commission',
  percentage_due_to_hotel_revenue = 'percentage_due_to_hotel_revenue',
  cashback_voucher = 'cashback_voucher',
  amount_with_activation_code = 'amount_with_activation_code',
}

export enum CampaignTab {
  edit = 'edit',
  budget = 'budget',
  history = 'history',
}
