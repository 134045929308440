import {CancelPenaltiesInterface} from '@/types/api/common/expediaParamsInterface';
import moment from 'moment';

declare module 'vue/types/vue' {
  interface Vue {
    showCancellationPenalties(
      cancel_penalties: CancelPenaltiesInterface[][],
      refundable: boolean,
      partially?: boolean,
    ): string;
  }
}

export const cancellationMixin = {
  methods: {
    showCancellationPenalties(
      cancel_penalties: CancelPenaltiesInterface[][],
      refundable: boolean,
      partially = false,
    ): string {
      let message = '';
      if (cancel_penalties.length) {
        const penalties = cancel_penalties[0];
        if (refundable || partially) {
          const key = partially && moment().isSameOrAfter(cancel_penalties[0][0].start) ? 'end' : 'start';
          if (refundable || (partially && moment().isSameOrBefore(cancel_penalties[0][0].start))) {
            message += (this as any).$t('common.expedia_cancellation.message_1', {
              date: (this as any).dateParser(penalties[0][key], true, true),
            });
          }
          penalties.forEach((data) => {
            if (Object.keys(data).some((s) => ['amount', 'nights', 'percent'].includes(s))) {
              const beforeMessage: string = (this as any).$t('common.expedia_cancellation.message_2', {
                date: (this as any).dateParser(data.end, true, true),
              });
              if (data.hasOwnProperty('amount') && +data.amount! > 0) {
                message +=
                  beforeMessage +
                  (this as any).$t('common.expedia_cancellation.message_3', {
                    amount: data.amount + data.currency,
                  });
              } else if (data.hasOwnProperty('nights') && +data.nights! > 0) {
                message +=
                  beforeMessage + (this as any).$t('common.expedia_cancellation.message_4', {nights: data.nights});
              } else if (data.hasOwnProperty('percent') && +data.percent!.split('%')[0] > 0) {
                message +=
                  beforeMessage + (this as any).$t('common.expedia_cancellation.message_5', {percent: data.percent});
              }
            }
          });
          message += (this as any).$t('common.expedia_cancellation.message_6');
        } else {
          message += (this as any).$t('common.expedia_cancellation.message_7');
        }
      }
      return message;
    },
  },
};
