export enum Source {
  WithoutSource = 'without_source',
  TlLincoln = 'tl_lincoln',
  Temairazu = 'temairazu',
  Neppan = 'neppan',
  Rakutsu = 'rakutsu',
  Beds24 = 'beds24',
  expedia = 'expedia',
  mh = 'MH',
}
