export enum ApiScope {
  // General part
  Dashboard = 'dashboard',
  Landing = 'landing',
  Management = 'management',
  Wizard = 'wizard',
  // Token scope
  Customer = 'customer',
  Manager = 'manager',
  Admin = 'admin',
  ImpersonateSession = 'impersonate_session',
  MFALoginAdmin = 'mfa_login_admin',
  MFALoginManager = 'mfa_login_manager',
  ManagerSecurity = 'manager_security',
  AdminSecurity = 'admin_security',
}

export type ApiScopeType =
  | ApiScope.Dashboard
  | ApiScope.Customer
  | ApiScope.Landing
  | ApiScope.Management
  | ApiScope.Manager
  | ApiScope.Wizard
  | ApiScope.Admin
  | ApiScope.ImpersonateSession
  | ApiScope.MFALoginAdmin
  | ApiScope.MFALoginManager
  | ApiScope.ManagerSecurity
  | ApiScope.AdminSecurity;
