import {Module} from 'vuex';
import {BookingStoreInterface} from '../../../../types/store/bookingStoreInterface';
import {BookingInterface} from '../../../../types/bookingInterface';
import BookingService from '../../../../services/management/booking.service';

const getDefaultState = () => {
  return {
    list: [],
    listLoading: false,
    total: '',
  };
};

const bookingStore: Module<BookingStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    bookingLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    bookingLoaded(state, payload: BookingInterface[]) {
      state.list = payload;
    },
    totalPages(state, payload: string) {
      state.total = payload;
    },
  },
  actions: {
    getList({commit}, data: any): any {
      commit('bookingLoading', true);
      BookingService.getBookingsList(data)
        .then((res) => {
          commit('bookingLoaded', res.data.data);
          commit('totalPages', res.data.total);
        })
        .finally(() => {
          commit('bookingLoading', false);
        });
    },
    cancelBooking(context, data: any): any {
      return BookingService.cancelBooking(data);
    },
    getChangeBooking(context, data: any): any {
      return BookingService.getChangeBooking(data).then((res) => {
        return res.data;
      });
    },
    setChangeBooking(context, data: any): any {
      return BookingService.setChangeBooking(data);
    },
    getCancellationInfo(context, data: any): any {
      return BookingService.getCancellationInfo(data);
    },
    downloadCSV(context, data: any): any {
      return BookingService.downloadBookingCSV(data);
    },
  },
};

export default bookingStore;
