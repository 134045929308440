import Vue from 'vue';
import {Location} from 'vue-router';

Vue.mixin({
  methods: {
    redirectTo(path: string, target: '_blank' | '_self'): void {
      window.open(path, target);
    },
    replaceUrlForTabs(location: Location): void {
      if (window.location.pathname.includes(location.path!)) return;
      this.$router.replace(location);
    },
  },
});
