import Vue from 'vue';

Vue.filter('address', (value: any, lang: string = 'ja') => {
  const addrArr = [
    value.address[lang],
    value.city_name[lang],
    value.district_name[lang] !== value.city_name[lang] && value.district_name.ja !== '東京23区'
      ? value.district_name[lang]
      : undefined,
    value.prefecture_name[lang],
  ];
  return lang === 'ja'
    ? `〒${value.zip_code} ${addrArr.reverse().filter(Boolean).join('')}`
    : `${value.zip_code}, ${addrArr.filter(Boolean).join(', ')}`;
});
