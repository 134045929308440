const CustomerApiResources = {
  hotelsArray: 'landing/search',
  places: 'landing/places/',
  predictPlaces: 'landing/places/predict',
  customerEmailByLink: 'customer/link_valid_check',
  customerRegistration: 'customer/registration',
  completeRegistration: 'customer/complete_registration',
  customerSignUp: 'customer/signup',
  customerSignIn: 'customer/auth/token',
  signOut: 'customer/auth/revoke',
  sendResetInstruction: 'customer/send_reset_password',
  updatePassword: 'customer/set_new_password',
  sendEnquiryForm: '/customer/send_enquiry_form',
  /* registration and email verification on booking page*/
  signUpOnBooking: 'customer/sign_up_by_booking',
  emailCustomerCodeVerification: 'customer/verify_email',
  emailCustomerResendVerification: 'customer/resend_code',
  accountCreationRevoke: 'landing/reject_customer',
  /* profile api */
  getUserData: 'customer/profile',
  profileUpdate: 'customer/profile/update',
  refreshCustomerToken: 'customer/auth/refresh_access_token',
  deleteAccount: 'customer/delete_account',
  profilePasswordUpdate: 'customer/password_update',
  getBookings: 'customer/profile/bookings',
  profileVerifyEmail: 'customer/profile/verify_updated_email',
  verifyInvitationCode: '/customer/check_invitation_code',
  getRakutenHistory: 'customer/profile/rakuten_points',
  smsCustomerVerification: 'customer/sms_verification/send',
  smsCustomerCodeVerification: 'customer/sms_verification/test',
  getPoinsForInviteAndJoin: 'customer/profile/rakuten_point_types/point_for_inviting_and_joining',
  /* hotel reviews api */
  hotelReview: 'customer/hotel_reviews',
  linkValidCheck: 'customer/hotel_reviews/link_valid_check',
  getHotelsGroup: 'landing/hotel_groups',
  getFAQItems: 'landing/faq_search',
  /* favourite hotels api */
  favourites: 'customer/favorite_hotels',
  favouritesHid: 'customer/favorite_hotels_list',
  getHotelsForHotelsHistory: 'landing/hotels_details',
  HotelsHistoryAuthorized: 'customer/history_hotels',
  getCategoriesHotels: 'landing/categories',
};
export default CustomerApiResources;
