import ApiService from '../_helper/api.service';
import DashboardApiResources from '../../constants/api/resources/dashboard';

const BookingService = {
  getCancelBookingInfoByUser(cancellation_token: string) {
    const config = { params: { cancellation_token } };
    return ApiService.get(DashboardApiResources.cancelBooking, config);
  },
  getChangeBookingInfoByUser(changepoint_token: string) {
    const config = { params: { changepoint_token } };
    return ApiService.get(DashboardApiResources.changeBooking, config);
  },
  cancelBookingByUser(data: any) {
    return ApiService.post(DashboardApiResources.cancelBooking, data);
  },
  changeBookingByUser(data: any) {
    return ApiService.post(DashboardApiResources.changeBooking, data);
  },
};

export default BookingService;
