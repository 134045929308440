import Vue from 'vue';
import {RatePlan, RoomInterface} from '@/types/roomInterface';
import {BookingPaymentMethod} from '@/constants/paymentSystem';
import {PaymentMethods} from '@/constants/paymentMethods';
import {RatePlansInterface} from '@/types/api/landing/roomInterface';

export default Vue.mixin({
  methods: {
    /**
     * Method return RatePlan filtered by include breakfast and payment method(optional)
     * @return RatePlan or undefined
     **/

    getRoomTypePlan(
      roomType: RoomInterface,
      inclBreakfast: boolean,
      paymentMethod?: BookingPaymentMethod,
      refundable?: boolean,
      planHid?: string,
    ): RatePlan | undefined {
      if (roomType && roomType.rate_plans) {
        return roomType.rate_plans.find((plan: RatePlan) => {
          const breakfastCheck: boolean = plan.incl_breakfast === inclBreakfast;
          const paymentCheck: boolean = plan.payment_method === paymentMethod;
          const refundCheck: boolean = refundable !== undefined ? plan.refundable === refundable : true;
          const planHidCheck: boolean = planHid !== undefined ? plan.rate_plan_hid === planHid : true;
          return breakfastCheck && paymentCheck && refundCheck && planHidCheck;
        });
      }
      return undefined;
    },

    hasSeparateUnitedPlan(ratePlans: RatePlansInterface[], paymentMethod: PaymentMethods): boolean {
      const findSeparateUnitedPlan: number = ratePlans.findIndex(
        (plan) => plan.united_plan && plan.payment_method !== paymentMethod,
      );
      return findSeparateUnitedPlan !== -1;
    },
  },
});
