import ApiService from '../_helper/api.service';
import ManagementApiResources from '../../constants/api/resources/management';
import {PaginationGroupInterface} from '@/types/api/common/paginationGroupInterface';

const PersonnelService = {
  getUsers(params: any) {
    return ApiService.get(ManagementApiResources.personnel, {params: params});
  },

  getUserDetails(params: any) {
    return ApiService.get(ManagementApiResources.personnel + '/details', {params: params});
  },

  addUser(data: any) {
    return ApiService.post(ManagementApiResources.personnel, data);
  },

  updateUser(data: any) {
    return ApiService.put(ManagementApiResources.personnel, data);
  },

  deleteUser(data: any) {
    return ApiService.delete(ManagementApiResources.personnel, data);
  },

  deactivateMFA(data: any) {
    return ApiService.put(ManagementApiResources.personnel + '/mfa_deactivate', data);
  },

  deactivateUser(data: any) {
    return ApiService.put(ManagementApiResources.personnel + '/deactivate', data);
  },

  activateUser(data: any) {
    return ApiService.put(ManagementApiResources.personnel + '/activate', data);
  },

  getPermissions(params: any) {
    return ApiService.get(ManagementApiResources.personnel + '/permissions', params);
  },

  getNotifications(params: PaginationGroupInterface) {
    return ApiService.get(ManagementApiResources.personnel + '/notifications', {params});
  },

  updateNotifications(data: any) {
    return ApiService.put(ManagementApiResources.personnel + '/notifications', data);
  },
};

export default PersonnelService;
