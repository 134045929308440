import Vue from 'vue';
import moment from 'moment';
import { DateFormat } from '../constants/dateFormat';

Vue.filter('date', (value: number, format: string = DateFormat.VueDate) => {
  return value ? moment(new Date(value)).format(format) : null;
});

Vue.filter('dateTime', (value: number, format: string = DateFormat.VueDateTime) => {
  return value ? moment(new Date(value)).format(format) : null;
});

Vue.filter('dateMonth', (value: number, format: string = DateFormat.VueDateMonth) => {
  return value ? moment(new Date(value)).format(format) : null;
});

Vue.filter('dateShort', (value: number, format: string = DateFormat.VueDateMonthDay) => {
  return value ? moment(new Date(value)).format(format) : null;
});

Vue.filter('nextDay', (value: Date) => {
  return value ? new Date(value.getTime() + 24 * 60 * 60 * 1000) : null;
});

Vue.filter('prevDay', (value: Date) => {
  return value ? new Date(value.getTime() - 24 * 60 * 60 * 1000) : null;
});

Vue.filter('addDay', (value: Date, days: number = 1, format: string = DateFormat.ServerDate) => {
  return value
    ? moment(value)
        .add(days, 'days')
        .format(format)
    : null;
});
