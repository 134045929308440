import Vue from 'vue';
import {BookingPaymentMethod} from '@/constants/paymentSystem';

export default Vue.mixin({
  methods: {
    accountPaymentMethod(paymentMethods: string[]): 'combined' | 'gmo' | 'pay-on-arrival' {
      if (paymentMethods.length > 1) {
        return 'combined';
      }
      return paymentMethods[0] === BookingPaymentMethod.CreditCard ? 'gmo' : 'pay-on-arrival';
    },
  },
});
