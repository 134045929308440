import ApiService from '../_helper/api.service';
import ManagementApiResources from '../../constants/api/resources/management';
import AdminApiResources from '../../constants/api/resources/wizard';
import {ApiScope} from '../../constants/apiScope';
import {TokenService} from '../_helper/storage.service';

const ClientService = {
  getAccounts(params: any = {}) {
    return ApiService.get(AdminApiResources.accounts, {params: params});
  },

  updateAccount(params: any = {}) {
    return ApiService.put(AdminApiResources.accounts, params);
  },

  getAccountDetails(account_id: number) {
    return ApiService.get(AdminApiResources.accountDetails, {params: {account_id}});
  },

  createAccount(data: any) {
    return ApiService.post(AdminApiResources.accounts, data);
  },

  sendInvitation(account_id: number) {
    return ApiService.get(AdminApiResources.sendInvitation, {params: {account_id}});
  },

  sendPasswordUpdate(account_id: number) {
    return ApiService.get(AdminApiResources.sendPasswordUpdate, {params: {account_id}});
  },

  impersonate(account_id: number) {
    return ApiService.get(AdminApiResources.impersonate, {params: {account_id}}).then(({data}) => {
      TokenService.saveToken(ApiScope.ImpersonateSession, data.access_token);
      TokenService.saveRefreshToken(ApiScope.ImpersonateSession, data.refresh_token);
      TokenService.removeScopeData(ApiScope.Manager);
      return ApiService.get(ManagementApiResources.accountState).then((res) => {
        return res.data;
      });
    });
  },
};

export default ClientService;
