import {Module} from 'vuex';
import {RoomStoreInterface} from '../../../../types/store/roomStoreInterface';
import {RoomInterface} from '../../../../types/roomInterface';
import RoomService from '../../../../services/management/room.service';
import Beds24 from '@/services/management/beds24.service';

const getDefaultState = () => {
  return {
    total: 0,
    list: [],
    listLoading: false,
    item: null,
  };
};

const roomStore: Module<RoomStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    roomsLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    roomsCount(state, payload: number) {
      state.total = payload;
    },
    roomsLoaded(state, payload: RoomInterface[]) {
      state.list = payload;
    },
    roomLoaded(state, payload: RoomInterface) {
      state.item = payload;
    },
  },
  actions: {
    getRoomTypes({commit}, params: any): any {
      commit('roomsLoading', true);
      return RoomService.getRooms(params)
        .then(({data}) => {
          commit('roomsLoading', false);
          commit('roomsLoaded', data.data);
          commit('roomsCount', data.total);
          return data;
        })
        .catch(() => {
          commit('roomsLoading', false);
        });
    },
    addRoomType({commit}, data: any): any {
      return RoomService.addRoomType(data).then((res: any) => {
        if (res.data) {
          commit('roomLoaded', res.data);
        }
        return res.data;
      });
    },
    sortRoomImages({commit}, data: any): any {
      return RoomService.sortRoomImages(data.room_type_id, data.image_order).then((res: any) => {
        if (res.data) {
          commit('roomLoaded', res.data);
        }
        return res.data;
      });
    },
    updateRoomType({commit}, data: any): any {
      return RoomService.updateRoomType(data).then((res: any) => {
        if (res.data) {
          commit('roomLoaded', res.data);
        }
        return res.data;
      });
    },
    updateInventorySettings(context, data: any) {
      return RoomService.updateInventorySettings(data);
    },
    verifyRoomTypeCode(context, data: any): any {
      return Beds24.verifyRoomTypeCode(data);
    },
    validateRoomType(context, data: any): any {
      return RoomService.validateRoomType(data);
    },
    archiveRoomType(context, data: any): any {
      return RoomService.archiveRoomType(data);
    },
    getRoomAvailability(context, params: any): any {
      return RoomService.getRoomAvailability(params);
    },
    updateRoomAvailability(context, data: any): any {
      return RoomService.updateRoomAvailability(data);
    },
  },
};

export default roomStore;
