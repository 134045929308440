import {Module} from 'vuex';

import {ManagementStoreInterface} from '../../../types/store/managementStoreInterface';
import {PermissionsInterface} from '../../../types/permissionsInterface';
import {Source} from '../../../constants/source';
import {PaymentSystem} from '../../../constants/paymentSystem';
import hotelStore from './modules/hotel';
import userStore from './modules/user';
import impersonateStore from './modules/impersonate';
import transactionStore from './modules/transaction';
import bookingStore from './modules/booking';
import reviewStore from './modules/review';
import roomStore from './modules/room';
import personnelStore from './modules/personnel';

const defaultState: ManagementStoreInterface = {
  settings: {
    amenities: [],
    bed_types: [],
    sources: [],
    manager_roles: [],
    prefectures: {},
    jtb_urls: {},
    translations_en: '',
    translations_ja: '',
    request_size_limit_mb: 20,
    mfa_test_mode: true,
  },
  sidebarCollapsed: false,
  lastSignSession: '',
  formHaveChanges: false,
  loading: false,
};

const store: Module<ManagementStoreInterface, any> = {
  namespaced: true,
  modules: {
    user: userStore,
    impersonate: impersonateStore,
    transaction: transactionStore,
    hotel: hotelStore,
    booking: bookingStore,
    review: reviewStore,
    room: roomStore,
    personnel: personnelStore,
  },
  state: defaultState,
  getters: {
    isLoggedIn(state): Boolean {
      if (state.impersonate && state.impersonate.isLoggedIn) {
        return state.impersonate.isLoggedIn;
      }
      return !!state.user && state.user.isLoggedIn;
    },
    permissions(state): PermissionsInterface {
      let permissions;
      if (state.impersonate && state.impersonate.permissions) {
        permissions = state.impersonate.permissions;
      } else if (state.user && state.user.permissions) {
        permissions = state.user.permissions;
      }
      return permissions ? permissions : {};
    },
    source(state): Source | undefined {
      if (state.impersonate && state.impersonate.source) {
        return state.impersonate.source;
      }
      return state.user ? state.user.source : undefined;
    },
    paymentSystem(state): PaymentSystem | undefined {
      if (state.impersonate && state.impersonate.paymentSystem) {
        return state.impersonate.paymentSystem;
      }
      return state.user && state.user.paymentSystem;
    },
    paymentMethods(state): Array<string> | undefined {
      if (state.impersonate && state.impersonate.paymentMethods && state.impersonate.paymentMethods.length) {
        return state.impersonate.paymentMethods;
      }
      return state.user ? state.user.paymentMethods : undefined;
    },
    // Management Sources
    isExpedia(state, getters): boolean {
      return getters.source === Source.expedia;
    },
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = {...settings};
    },
    setContextSettings(state, settings) {
      state.settings = {...state.settings, ...settings};
    },
    setBedTypes(state, bed_types) {
      state.settings.bed_types = bed_types;
    },
    toggleSidebar(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    setLastSignSession(state, payload: string) {
      state.lastSignSession = payload;
    },
    formHaveChanges(state, payload: boolean) {
      state.formHaveChanges = payload;
    },
    loadingStatus(state, payload: boolean) {
      state.loading = payload;
    },
  },
  actions: {
    init({state, dispatch}) {
      const defaultKeys = Object.keys(defaultState.settings).sort();
      const currentKeys = Object.keys(state.settings).sort();
      defaultKeys.forEach((key: string) => {
        if (!currentKeys.includes(key)) {
          dispatch('clearAll');
          return;
        }
      });
      dispatch('user/init');
      dispatch('impersonate/init');
    },
    toggleSidebar({commit}) {
      commit('toggleSidebar');
    },
    clearAll({commit}) {
      commit('user/resetState');
      commit('impersonate/resetState');
      commit('transaction/resetState');
      commit('booking/resetState');
      commit('room/resetState');
      commit('hotel/resetState');
      commit('personnel/resetState');
    },
  },
};

export default store;
