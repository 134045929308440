import ApiService from '../_helper/api.service';
import AdminApiResources from '../../constants/api/resources/wizard';
import FileService from '@/services/_helper/file.service';

const TransactionService = {
  getTransactions(params: any) {
    return ApiService.get(AdminApiResources.transactions, {params: params});
  },

  downloadTransactionsCSV(data: any, isExpedia = false) {
    return ApiService.get(isExpedia ? AdminApiResources.transactionsExpediaCSV : AdminApiResources.transactionsCSV, {
      headers: {
        Accept: 'text/csv; charset=utf-8',
        'Content-Type': 'text/csv; charset=utf-8',
      },
      params: data,
      responseType: 'text',
    }).then((res) => {
      let contentDisposition = res.headers['content-disposition'].split('filename');
      let fileName = 'transactions.csv';
      if (contentDisposition.length > 1) {
        contentDisposition = contentDisposition[1].split('"');
        if (contentDisposition.length > 2) {
          fileName = (data.type === 'system-commission' ? 'システム利用料_' : '決済手数料_') + contentDisposition[1];
        }
      }
      FileService.saveCSV(res.data, fileName);
      return res;
    });
  },
};

export default TransactionService;
