import {Module} from 'vuex';

import {AdminStoreInterface} from '@/types/store/adminStoreInterface';
import accountStore from './modules/account';
import hotelStore from './modules/hotel';
import transactionStore from './modules/transaction';
import campaignStore from './modules/campaign';
import userStore from './modules/user';
import personnelStore from './modules/personnel';
import hotelGroupStore from './modules/hotelGroup';
import landingCMSStore from './modules/landingCMS';
import rakutenStore from './modules/rakuten';

const defaultState = {
  settings: {
    amenities: [],
    bed_types: [],
    sources: [],
    payment_systems: [],
    prefectures: {},
    jtb_rates: {},
    translations_en: '',
    translations_ja: '',
    admin_roles: [],
    mfa_test_mode: true,
    campaign_types: [],
  },
  sidebarCollapsed: false,
  showDraft: false,
};

const store: Module<AdminStoreInterface, any> = {
  namespaced: true,
  modules: {
    account: accountStore,
    hotel: hotelStore,
    personnel: personnelStore,
    transaction: transactionStore,
    campaign: campaignStore,
    user: userStore,
    hotelGroup: hotelGroupStore,
    landingCMS: landingCMSStore,
    rakuten: rakutenStore,
  },
  state: defaultState,
  mutations: {
    setSettings(state, settings) {
      state.settings = {...settings};
    },
    toggleSidebar(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    toggleDraftClients(state, payload: boolean) {
      state.showDraft = payload;
    },
  },
  actions: {
    init({state, dispatch}) {
      const defaultKeys = Object.keys(defaultState.settings).sort();
      const currentKeys = Object.keys(state.settings).sort();
      defaultKeys.forEach((key: string) => {
        if (!currentKeys.includes(key)) {
          dispatch('clearAll');
          return;
        }
      });
    },
    toggleSidebar({commit}) {
      commit('toggleSidebar');
    },
    clearAll({commit}) {
      commit('account/resetState');
      commit('hotel/resetState');
      commit('personnel/resetState');
      commit('transaction/resetState');
      commit('campaign/resetState');
      commit('user/resetState');
      commit('hotelGroup/resetState');
      commit('landingCMS/resetState');
      commit('rakutenStore/resetState');
    },
  },
};

export default store;
