import Vue from 'vue';

Vue.mixin({
  methods: {
    isAboveServerTime: (day: Date, tzone: number) => {
      /*     current time in minutes  +  server time + time diff to meridian time (GMT+3 is -180) */
      return day.getHours() * 60 + tzone * 60 + day.getTimezoneOffset() >= 1440; // 1440 is 24 hours in minutes format
    },
  },
});
