export const groupByMixin = {
  methods: {
    groupBy(array: Array<any>, key: string): Array<any> {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
      }, {});
    },
  },
};
