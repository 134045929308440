import ApiService from '../_helper/api.service';
import LandingApiResources from '../../constants/api/resources/landing';
import {HotelReviewRequestInterface} from '@/types/api/common/hotelReviewInterface';
import {AxiosPromise} from 'axios';
import {getRoomAvailabilityParams} from '@/types/api/landing/getRoomAvailabilityParams';
import {GooglePrediction} from '@/types/googleMapsInterface';

interface PredictionParams {
  input: string;
  language?: string;
}

const HotelService = {
  showCancellationPolicy(hid: string) {
    const config = {
      params: {
        hotel_hid: hid,
      },
    };
    return ApiService.get(LandingApiResources.cancellationPolicy, config);
  },

  getPopularHotels(hid: string) {
    const config = {
      params: {
        hotel_hid: hid,
      },
    };
    return ApiService.get(LandingApiResources.recommendedHotels, config);
  },
  getLegalInfo(hid: string) {
    const config = {params: {hotel_hid: hid}};
    return ApiService.get(LandingApiResources.gmoLegalInfo, config);
  },

  getRoomTypeInfo(data: any) {
    const config = {params: data};
    return ApiService.get(LandingApiResources.roomTypeInfo, config);
  },

  getRoomAvailabilityHash(data: getRoomAvailabilityParams) {
    const config = {params: data};
    return ApiService.get(LandingApiResources.roomAvailability, config);
  },
  getExpediaRoomAvailabilityHash(data: getRoomAvailabilityParams) {
    const config = {params: data};
    return ApiService.get(LandingApiResources.expediaRoomAvailability, config);
  },
  getRoomData(data: getRoomAvailabilityParams) {
    const config = {params: data};
    return ApiService.get(LandingApiResources.roomData, config);
  },
  getHotelReview(data: HotelReviewRequestInterface) {
    const config = {
      params: {...data},
    };
    return ApiService.get(LandingApiResources.hotelReview, config);
  },

  getPlacesPredictions(data: PredictionParams): AxiosPromise<Array<GooglePrediction>> {
    return ApiService.post(LandingApiResources.placesPredictions, data);
  },
};

export default HotelService;
