export enum expediaImageSize {
  first = '1000px',
  second = '350px',
  third = '200px',
  fourth = '70px',
}

export const expediaImageMixins = {
  methods: {
    imageSize(images: any): string {
      const isMobile: boolean = window.innerWidth <= 640;
      let currentSize = '';
      switch (true) {
        case !isMobile && !!images[expediaImageSize.first]: {
          currentSize = images[expediaImageSize.first];
          break;
        }
        case !isMobile && !!images[expediaImageSize.second]: {
          currentSize = images[expediaImageSize.second];
          break;
        }
        case !isMobile && !!images[expediaImageSize.third]: {
          currentSize = images[expediaImageSize.third];
          break;
        }
        case !isMobile && !!images[expediaImageSize.fourth]: {
          currentSize = images[expediaImageSize.fourth];
          break;
        }
        case isMobile && !!images[expediaImageSize.second]: {
          currentSize = images[expediaImageSize.second];
          break;
        }
        case isMobile && !!images[expediaImageSize.first]: {
          currentSize = images[expediaImageSize.first];
          break;
        }
        case isMobile && !!images[expediaImageSize.third]: {
          currentSize = images[expediaImageSize.third];
          break;
        }
        case isMobile && !!images[expediaImageSize.fourth]: {
          currentSize = images[expediaImageSize.fourth];
          break;
        }
      }
      return currentSize;
    },
  },
};
