import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    setModalDataMixin(array: string[]): void;
    openPopupMixin(key: string): void;
    closePopupMixin(key: string): void;
    updatePopupLoaderMixin(status: boolean): void;
  }
}
export const modalGeneralMixin = {
  methods: {
    setModalDataMixin(array: string[]): void {
      const self: any = this;
      array.forEach((key: string) => {
        Vue.set(self.$data.modalOption, key, false);
      });
    },
    openPopupMixin(key: string): void {
      const self: any = this;
      self.$data.modalOption[key] = true;
    },
    closePopupMixin(key: string): void {
      const self: any = this;
      self.$data.modalOption[key] = false;
    },
    updatePopupLoaderMixin(status: boolean): void {
      const self: any = this;
      self.$data.modalOption.loading = status;
    },
  },
};
