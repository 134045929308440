import ApiService from '../_helper/api.service';
import ManagementApiResources from '../../constants/api/resources/management';
import axios from 'axios';
import {UpdateHotelParamsInterface} from '@/types/pages/management/updateHotelParamsInterface';
import {DictionariesInterface} from '@/types/api/common/dictionariesInterface';
import {HotelCreateInterface} from '@/types/hotelInterface';
import {PaginationGroupInterface} from '@/types/api/common/paginationGroupInterface';

const HotelService = {
  getHotels(params: PaginationGroupInterface & {search: String}) {
    return ApiService.get(ManagementApiResources.hotels, {params});
  },

  getHotelDetails(id: number): Promise<any> {
    const config = {params: {hotel_id: id}};
    return ApiService.get(ManagementApiResources.hotelDetails, config);
  },

  addHotel(data: HotelCreateInterface) {
    const config = {headers: {'Content-Type': 'multipart/form-data'}};
    return ApiService.post(ManagementApiResources.hotels, data, config);
  },

  updateHotel(data: UpdateHotelParamsInterface) {
    const config = {headers: {'Content-Type': 'multipart/form-data'}};
    return ApiService.put(ManagementApiResources.hotels + '/details', data, config);
  },

  setHotelPublishStatus(data: any) {
    return ApiService.put(ManagementApiResources.hotels + '/set_publish_status', data);
  },

  getHotelRoomTypes(hotel_id: number) {
    const config = {params: {hotel_id}};
    return ApiService.get(ManagementApiResources.hotelRoomTypes, config);
  },

  getCancellationPolicy(id: number) {
    const config = {params: {hotel_id: id}};
    return ApiService.get(ManagementApiResources.cancellationPolicy, config);
  },

  updateCancellationPolicy(data: any) {
    return ApiService.post(ManagementApiResources.cancellationPolicy, data);
  },

  updateHotelAllowBookingToday(data: any) {
    return ApiService.put(ManagementApiResources.hotelAllowBookingToday, data);
  },

  getHotelCheckin(id: any) {
    const config = {params: {hotel_id: id}};
    return ApiService.get(ManagementApiResources.hotelCheckin, config);
  },

  updateHotelCheckin(data: any) {
    return ApiService.put(ManagementApiResources.hotelCheckin, data);
  },

  getTLLincoln(data: any) {
    const config = {params: data};
    return ApiService.get(ManagementApiResources.getAuthTLLincoln, config);
  },

  checkTLLincoln(data: any) {
    return ApiService.post(ManagementApiResources.verifyAuthTLLincoln, data);
  },

  updateTLLincoln(data: any) {
    return ApiService.put(ManagementApiResources.updateAuthTLLincoln, data);
  },

  checkHotelTLLincoln(data: any) {
    return ApiService.post(ManagementApiResources.verifyHotelTLLincoln, data);
  },

  getTemairazu(data: any) {
    const config = {params: data};
    return ApiService.get(ManagementApiResources.getAuthTemairazu, config);
  },

  getRakutsu(data: any) {
    const config = {params: data};
    return ApiService.get(ManagementApiResources.getAuthRakutsu, config);
  },

  getNeppan(data: any) {
    const config = {params: data};
    return ApiService.get(ManagementApiResources.getAuthNeppan, config);
  },

  getGMOInfo(id: number) {
    const config = {params: {hotel_id: id}};
    return ApiService.get(ManagementApiResources.dataGMO, config);
  },

  updateGMOInfo(data: any) {
    return ApiService.put(ManagementApiResources.dataGMO, data);
  },

  getPriceSettings(data: any) {
    const config = {params: data};
    return ApiService.get(ManagementApiResources.priceSettings, config);
  },

  updatePriceSettings(data: any) {
    return ApiService.put(ManagementApiResources.priceSettings, data);
  },

  updateRatePlanPriceSettings(data: any) {
    return ApiService.put(ManagementApiResources.ratePlanPriceSettings, data);
  },

  getBookingRoomOptions(data: any) {
    return ApiService.get(ManagementApiResources.bookingRoomOptions, {params: data});
  },

  getBookingPlanOptions(data: any) {
    return ApiService.get(ManagementApiResources.bookingPlanOptions, {params: data});
  },

  getDictionaries(params: DictionariesInterface) {
    return axios.get(window.location.origin + '/dictionaries', {params});
  },
};

export default HotelService;
