import {Module} from 'vuex';
import {AxiosPromise} from 'axios';
import {WizardHotelStoreInterface} from '@/types/store/hotelStoreInterface';
import {HotelInterfaceWizard, HotelNameInterface} from '@/types/hotelInterface';
import HotelService from '@/services/wizard/hotel.service';
import CampaignService from '@/services/wizard/campaign.service';
import {AmpaignStateInterface} from '@/types/api/wizard/campaigns/сampaignStateInterface';

const getDefaultState = () => {
  return {
    list: [],
    listLoading: false,
    licenseType: '',
  };
};

const hotelStore: Module<WizardHotelStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    listLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    listLoaded(state, payload: HotelInterfaceWizard[]) {
      state.list = payload;
    },
    setLicenseType(state, payload) {
      state.licenseType = payload;
    },
  },
  actions: {
    getList({commit}, data: any): any {
      commit('listLoading', true);
      return HotelService.getHotels(data)
        .then(({data}: {data: {data: HotelInterfaceWizard[]; total: number}}) => {
          commit('listLoaded', data);
          return data;
        })
        .catch((error: any) => {
          return error;
        })
        .finally(() => {
          commit('listLoading', false);
        });
    },
    getHotelsNames(
      state,
      data = {
        page: 1,
        per_page: 99,
        account_id: -1,
        search: '',
      },
    ): any {
      return HotelService.getHotelList(data).then(({data}: {data: {total: number; hotels: HotelNameInterface[]}}) => {
        return data;
      });
    },
    toggleCampaignState(state, data: AmpaignStateInterface): AxiosPromise {
      return CampaignService.toggleCampaignState(data);
    },
    getDetails(state, data: any): AxiosPromise {
      return HotelService.getHotelDetails(data);
    },
    toggleActive(state, data: any): AxiosPromise {
      return HotelService.toggleActive(data);
    },
    togglePublished(
      state,
      data: {
        hotel_id: number;
        published: boolean;
      },
    ): AxiosPromise {
      return HotelService.togglePublished(data);
    },
    getGMOInfo(state, data: any): AxiosPromise {
      return HotelService.getGMOInfo(data);
    },
    activateGMO(state, data: any): AxiosPromise {
      return HotelService.activateGMO(data);
    },
    updateType({commit}, data): any {
      return HotelService.updateLicenseType(data)
        .then(({data}) => {
          commit('setLicenseType', data);
          return data;
        })
        .catch((error: any) => {
          return {error: error};
        });
    },
    getPostingSuspension(state, data: any): AxiosPromise {
      return HotelService.getPostingSuspension(data);
    },
    updateSuspendList(context, data): any {
      return HotelService.updatePostingSuspension(data);
    },
  },
};

export default hotelStore;
