import landingCMSService from '@/services/wizard/landingCMS.service';
import {Module} from 'vuex';

const getDefaultState = () => {
  return {
    list: [],
    listLoading: false,
    postLoading: false,
    formHaveChanges: false,
    loading: false,
    authData: null,
  };
};
const landingCMSStore: Module<any, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    hotelsGroupLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    hotelsGroupLoaded(state, payload) {
      state.list = payload;
    },
    loadingStatus(state, payload: boolean) {
      state.loading = payload;
    },
    postHotelBlockLoading(state, payload: boolean) {
      state.postLoading = payload;
    },
  },
  actions: {
    getHotelsGroup({commit}) {
      commit('hotelsGroupLoading', true);
      return landingCMSService
        .getHotelGroups()
        .then(({data}: any) => {
          commit('hotelsGroupLoaded', data);
          return data;
        })
        .finally(() => {
          commit('hotelsGroupLoading', false);
        });
    },
    getHotelGroup({commit}) {
      commit('hotelsGroupLoading', true);
      return landingCMSService.getHotelGroups().finally(() => {
        commit('hotelsGroupLoading', false);
      });
    },
    getHotelBlock(context, data: string) {
      return landingCMSService.getHotelBlock(data);
    },
    postHotelGroup(context, groupName: string) {
      return landingCMSService.postHotelGroup(groupName);
    },
    putHotelGroup(context, data: any) {
      return landingCMSService.putHotelGroup(data);
    },
    isPublishHotelGroup({commit}, data) {
      if (data.status) {
        return landingCMSService.unpublishHotelGroup(data.id);
      } else return landingCMSService.publishHotelGroup(data.id);
    },
    deleteHotelGroup(context, id: number) {
      return landingCMSService.deleteHotelGroup(id);
    },
    deleteHotelBlock(context, id: number) {
      return landingCMSService.deleteHotelBlock(id);
    },
    postHotelBlock({commit}, data) {
      commit('postHotelBlockLoading', true);
      return landingCMSService.postHotelBlock(data).finally(() => {
        commit('postHotelBlockLoading', false);
      });
    },
    editHotelBlock({commit}, data) {
      commit('postHotelBlockLoading', true);
      return landingCMSService.putHotelBlock(data).finally(() => {
        commit('postHotelBlockLoading', false);
      });
    },
    hotelGroupOrder({commit}, data) {
      commit('postHotelBlockLoading', true);
      return landingCMSService.hotelGroupOrder(data).finally(() => {
        commit('postHotelBlockLoading', false);
      });
    },
    hotelBlockOrder({commit}, data) {
      commit('postHotelBlockLoading', true);
      return landingCMSService.hotelBlockOrder(data).finally(() => {
        commit('postHotelBlockLoading', false);
      });
    },
  },
};
export default landingCMSStore;
