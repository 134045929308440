import { Module } from 'vuex';
import { PersonnelStoreInterface } from '../../../../types/store/personnelStoreInterface';
import { PersonnelInterface } from '../../../../types/personnelInterface';
import PersonnelService from '../../../../services/wizard/personnel.service';

const getDefaultState = () => {
  return {
    list: [] as Array<PersonnelInterface>,
    listLoading: false,
  };
};

const personnelStore: Module<PersonnelStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    usersLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    usersLoaded(state, payload: PersonnelInterface[]) {
      state.list = payload;
    },
  },
  actions: {
    getUsers({ commit }, params: any): any {
      commit('usersLoading', true);
      return PersonnelService.getUsers(params)
        .then(({ data }: any) => {
          commit('usersLoaded', data);
          return data;
        })
        .finally(() => {
          commit('usersLoading', false);
        });
    },
    deactivateMFA(context, data: any): any {
      return PersonnelService.deactivateMFA(data);
    },
  },
};

export default personnelStore;
