import { Module } from 'vuex';
import { DashboardBookingStoreInterface } from '../../../../types/store/bookingStoreInterface';
import { ChangeBookingInterface } from '../../../../types/api/dashboard/changeBookingInterface';
import BookingService from '../../../../services/dashboard/booking.service';

const getDefaultState = () => {
  return {
    changed: {} as ChangeBookingInterface,
  };
};

const bookingStore: Module<DashboardBookingStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    setChangedBooking(state, data) {
      state.changed = data;
    },
  },
  actions: {
    setChangedItem({ commit }, data: ChangeBookingInterface) {
      commit('setChangedBooking', data);
    },
    getCancelBookingInfo(context, token: string): any {
      return BookingService.getCancelBookingInfoByUser(token);
    },
    getChangeBookingInfo(context, token: string): any {
      return BookingService.getChangeBookingInfoByUser(token);
    },
    cancelBooking(context, data: any): any {
      return BookingService.cancelBookingByUser(data);
    },
    changeBooking(context, data: any): any {
      return BookingService.changeBookingByUser(data);
    },
  },
};

export default bookingStore;
