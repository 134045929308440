const ManagementApiResources = {
  signIn: 'management/auth/token',
  signOut: 'management/auth/revoke',
  refreshManagerToken: 'management/auth/refresh_access_token',
  refreshImpersonateToken: 'management/auth/refresh_impersonate_token',
  mfaResetEmail: 'management/auth/mfa_reset_email',
  appSecret: 'management/auth/app_secret',
  appVerifyAndSave: 'management/auth/app_verify_and_save',
  smsSend: 'management/auth/sms_send',
  smsVerifyAndSave: 'management/auth/sms_verify_and_save',
  appAuth: 'management/auth/mfa_app_authentication',
  smsAuth: 'management/auth/mfa_sms_authentication',
  resetMFA: 'management/auth/mfa_reset',
  user: 'management/user',
  setPassword: 'management/user/set_password',
  setNewPassword: 'management/set_new_password',
  sendResetPassword: 'management/send_reset_password',
  completeSignUp: 'management/complete_signup',
  accountState: 'management/account/state',
  connectStripe: 'management/account/stripe_connect',
  dataStripe: 'management/account/stripe_data',
  generateUniquePlanCode: 'management/account/unique_plan_code',
  tosAcceptance: 'management/account/tos_acceptance',
  dataGMO: 'management/gmo',
  hotels: 'management/hotels',
  hotelDetails: 'management/hotels/details',
  hotelRoomTypes: 'management/hotels/room_types',
  roomTypes: 'management/room_types',
  roomAvailability: 'management/inventory_settings',
  archiveRoomType: 'management/room_types/archive',
  bookingRoomOptions: 'management/hotels/booking_room_options',
  bookingPlanOptions: 'management/hotels/booking_plan_options',
  bookings: 'management/bookings',
  transactions: 'management/transactions',
  transactionsCSV: 'management/transactions/download_csv',
  cancelBooking: 'management/bookings/cancel',
  downloadCSV: 'management/bookings/download_csv',
  changeBooking: 'management/bookings/change',
  cancellationPolicy: 'management/cancellation_policy',
  hotelAllowBookingToday: 'management/hotels/details',
  getAuthTLLincoln: 'management/tl_lincoln/auth',
  verifyAuthTLLincoln: 'management/tl_lincoln/verify_auth',
  updateAuthTLLincoln: 'management/tl_lincoln/update_auth',
  verifyHotelTLLincoln: 'management/tl_lincoln/verify_hotel_code',
  verifyRoomTLLincoln: 'management/tl_lincoln/verify_room_type',
  getAuthTemairazu: 'management/temairazu/auth',
  getAuthRakutsu: 'management/rakutsu/auth',
  getAuthNeppan: 'management/neppan/auth',
  priceSettings: 'management/price_settings',
  ratePlanPriceSettings: 'management/price_settings/rate_plan',
  inventorySettings: 'management/inventory_settings',
  personnel: 'management/managers',
  securityToken: 'management/security/token',
  securityMFA: 'management/security_mfa/index',
  securityApp: 'management/security_mfa/app_verify_and_save',
  securitySMSSend: 'management/security_mfa/sms_send',
  securitySMS: 'management/security_mfa/sms_verify_and_save',
  setHotelPublishStatus: 'management/hotels/set_publish_status',
  beds24Auth: 'management/beds24/auth',
  beds24UpdateAuth: 'management/beds24/update_auth',
  beds24VerifyAuth: 'management/beds24/verify_auth',
  beds24VerifyRoomTypeCode: 'management/beds24/verify_room_type_code',
  getHotelReviews: 'management/hotel_reviews',
  getReviewDetails: 'management/hotel_reviews/show_review',
  publishReview: 'management/hotel_reviews/publish',
  unpublishReview: 'management/hotel_reviews/unpublish',
  reviewReplies: 'management/review_replies',
  hotelCheckin: 'management/hotels/checkin_today',
};
export default ManagementApiResources;
