import Vue from 'vue';
import {HotelInterfaceCustomer} from '@/types/hotelInterface';

const criteo_account_id = 95090;

export default Vue.mixin({
  methods: {
    criteoViewHome(): void {
      (window as any).criteo_q = (window as any).criteo_q || [];
      (window as any).criteo_q.push(
        {event: 'setAccount', account: criteo_account_id},
        {event: 'setSiteType', type: 'd'},
        {event: 'setEmail', email: ''},
        {event: 'viewHome'},
      );
    },
    criteoViewList(hotels: HotelInterfaceCustomer[], checkin: string, checkout: string, guests_number: number): void {
      var item_list: string[] = [];
      hotels.slice(0, 3).forEach((hotel) => {
        item_list.push(hotel.hotel_hid);
      });
      (window as any).criteo_q = (window as any).criteo_q || [];
      (window as any).criteo_q.push(
        {event: 'setAccount', account: criteo_account_id},
        {event: 'setSiteType', type: 'd'},
        {event: 'setEmail', email: ''},
        {event: 'viewList', item: item_list},
        {
          event: 'viewSearch',
          checkin_date: checkin || '',
          checkout_date: checkout || '',
          nbra: guests_number,
          nbrr: '1',
        },
      );
    },
    criteoViewItem(hotel_hid: string, checkin: string, checkout: string, guests_number: number): void {
      (window as any).criteo_q = (window as any).criteo_q || [];
      (window as any).criteo_q.push(
        {event: 'setAccount', account: criteo_account_id},
        {event: 'setSiteType', type: 'd'},
        {event: 'setEmail', email: ''},
        {event: 'viewItem', item: hotel_hid},
        {event: 'viewSearch', checkin_date: checkin, checkout_date: checkout, nbra: guests_number, nbrr: '1'},
      );
    },
    criteoViewBasket(hotel_hid: string, price: number, checkin: string, checkout: string, guests_number: number): void {
      (window as any).criteo_q = (window as any).criteo_q || [];
      (window as any).criteo_q.push(
        {event: 'setAccount', account: criteo_account_id},
        {event: 'setSiteType', type: 'd'},
        {event: 'setEmail', email: ''},
        {
          event: 'viewSearch',
          checkin_date: checkin || '',
          checkout_date: checkout || '',
          nbra: guests_number,
          nbrr: '1',
        },
        {event: 'viewBasket', item: [{id: hotel_hid, price: price, quantity: 1}]},
      );
    },
    criteoTrackTransaction(
      transaction_id: string,
      hotel_hid: string,
      price: number,
      checkin: string,
      checkout: string,
      guests_number: number,
    ): void {
      (window as any).criteo_q = (window as any).criteo_q || [];
      (window as any).criteo_q.push(
        {event: 'setAccount', account: criteo_account_id},
        {event: 'setSiteType', type: 'd'},
        {event: 'setEmail', email: ''},
        {
          event: 'viewSearch',
          checkin_date: checkin || '',
          checkout_date: checkout || '',
          nbra: guests_number,
          nbrr: '1',
        },
        {event: 'trackTransaction', id: transaction_id, item: [{id: hotel_hid, price: price, quantity: 1}]},
      );
    },
  },
});
