import Vue from 'vue';

Vue.mixin({
  methods: {
    debounce(callback: any, delay: number): Function {
      let timeoutID: any = null;
      return () => {
        clearTimeout(timeoutID);
        const args = arguments;
        const that = this;
        timeoutID = setTimeout(function() {
          callback.apply(that, args);
        }, delay);
      };
    },
  },
});
