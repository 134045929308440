import {ApiScope} from '@/constants/apiScope';
import {PerPage} from '@/constants/paginationEnums';
import CustomerApiResources from '@/constants/api/resources/customer';

import {UserDataInterface, UserDataPresetInterface} from '@/types/store/customer/user/userStoreInterface';
import {PaginationParams} from '@/types/pages/common/paginationParams';
import {EnquiryFormInterface} from '@/types/pages/customer/enquiryFormInterface';
import {LandingSearchInterface} from '@/types/api/customer/landingSearchInterface';
import {HotelInterfaceCustomer} from '@/types/hotelInterface';
import {HotelReviewInterface} from '@/types/api/common/hotelReviewInterface';

import ApiService from '@/services/_helper/api.service';
import {TokenService} from '@/services/_helper/storage.service';
import {AxiosResponse} from 'axios';

const CustomerService = {
  authRevoke() {
    const refreshToken = TokenService.getRefreshToken(ApiScope.Customer);
    if (refreshToken) {
      ApiService.post(CustomerApiResources.signOut, {token: refreshToken});
    }
    const token = TokenService.getToken(ApiScope.Customer);
    if (token) {
      ApiService.post(CustomerApiResources.signOut, {token});
      TokenService.removeScopeData(ApiScope.Customer);
    }
    return Promise.resolve(true);
  },
  getCategoriesHotels(params: {prefecture_id: string; small_area_id?: string}) {
    return ApiService.get(CustomerApiResources.getCategoriesHotels, {params});
  },
  getHotelsGroup() {
    return ApiService.get(CustomerApiResources.getHotelsGroup);
  },
  getHotels(data: any) {
    return ApiService.post(CustomerApiResources.hotelsArray, data);
  },
  getPlaceDetails(placeId: string, locale = 'ja') {
    return ApiService.get(CustomerApiResources.places + placeId, {params: {locale}});
  },
  getPlacesPredictions(data: {input: string; language: string; types: Array<any>}) {
    return ApiService.post(CustomerApiResources.predictPlaces, data);
  },
  customerRegistration(email: string) {
    return ApiService.post(CustomerApiResources.customerRegistration, {email});
  },
  getEmailByLink(confirmation_token: string) {
    return ApiService.get(CustomerApiResources.customerEmailByLink, {params: {confirmation_token}});
  },
  customerCompleteRegistration(data: UserDataPresetInterface) {
    return ApiService.put(CustomerApiResources.completeRegistration, data);
  },
  sendResetInstruction(email: string) {
    return ApiService.post(CustomerApiResources.sendResetInstruction, {email});
  },
  updatePassword(data: {token: string; password: string}) {
    return ApiService.post(CustomerApiResources.updatePassword, data);
  },
  getUserData() {
    return ApiService.get(CustomerApiResources.getUserData);
  },
  async customerSignIn(data: {email: string; password: string}) {
    return await ApiService.post(CustomerApiResources.customerSignIn, data).then((res: AxiosResponse) => {
      setCustomerTokens(res);
      return res;
    });
  },
  customerSignUp(data: any) {
    return ApiService.post(CustomerApiResources.customerSignUp, data);
  },
  signUpOnBooking(data: UserDataPresetInterface) {
    return ApiService.post(CustomerApiResources.signUpOnBooking, data);
  },
  profileUpdate(data: UserDataInterface) {
    return ApiService.put(CustomerApiResources.profileUpdate, data);
  },
  profilePasswordUpdate(data: {current_password: string; new_password: string; confirm_password: string}) {
    return ApiService.put(CustomerApiResources.profilePasswordUpdate, data);
  },
  deleteAccount() {
    return ApiService.delete(CustomerApiResources.deleteAccount);
  },
  accountCreationRevoke(email: string) {
    return ApiService.delete(CustomerApiResources.accountCreationRevoke, {params: {email}});
  },
  getBookings(data: {params: PaginationParams}) {
    return ApiService.get(CustomerApiResources.getBookings, {params: data});
  },
  getBookingDetails(bookingCode: string) {
    return ApiService.get(CustomerApiResources.getBookings + '/' + bookingCode);
  },
  getRakutenHistory(data: {params: PaginationParams}) {
    data.params.per_page = PerPage.default;
    return ApiService.get(CustomerApiResources.getRakutenHistory, data);
  },
  verifyInvitationCode(invitation_code: string) {
    return ApiService.get(CustomerApiResources.verifyInvitationCode, {params: {invitation_code}});
  },
  verifyEmailCustomer(data: {email: string; code: string}) {
    return ApiService.put(CustomerApiResources.profileVerifyEmail, data);
  },
  emailCustomerResendVerification(email: string) {
    return ApiService.post(CustomerApiResources.emailCustomerResendVerification, {email});
  },
  async emailCustomerCodeVerification(data: {email: string; code: string}) {
    return await ApiService.post(CustomerApiResources.emailCustomerCodeVerification, data).then(
      (res: AxiosResponse) => {
        setCustomerTokens(res);
        return res;
      },
    );
  },
  checkReviewLink(booking_code: string) {
    return ApiService.get(CustomerApiResources.linkValidCheck, {params: {booking_code}});
  },
  setHotelReview(data: HotelReviewInterface) {
    return ApiService.post(CustomerApiResources.hotelReview, data);
  },
  smsCustomerVerification(phone: string) {
    return ApiService.post(CustomerApiResources.smsCustomerVerification, {phone});
  },
  smsCustomerCodeVerification(data: {phone: string; code: string}) {
    return ApiService.post(CustomerApiResources.smsCustomerCodeVerification, data);
  },
  getPoinsForInviteAndJoin() {
    return ApiService.get(CustomerApiResources.getPoinsForInviteAndJoin);
  },
  sendEnquiryForm(data: EnquiryFormInterface) {
    return ApiService.post(CustomerApiResources.sendEnquiryForm, data);
  },
  getFAQItems(data: LandingSearchInterface) {
    return ApiService.post(CustomerApiResources.getFAQItems, data);
  },
  addFavourite(data: {hotel_hids: []}) {
    return ApiService.post(CustomerApiResources.favourites, data);
  },
  deleteFavourite(hotel_hid: string) {
    return ApiService.delete(CustomerApiResources.favourites, {params: {hotel_hid}});
  },
  getFavouritesList(params: {page: number; per_page: number}) {
    return ApiService.get(CustomerApiResources.favourites, {params});
  },
  getFavouritesHid() {
    return ApiService.get(CustomerApiResources.favouritesHid);
  },
  getHotelsHistory(data: {hotel_hids: HotelInterfaceCustomer[]}) {
    return ApiService.get(CustomerApiResources.getHotelsForHotelsHistory, {params: data});
  },
  /*hotels history*/

  getHotelsHistoryAuthorized(data: any) {
    return ApiService.get(CustomerApiResources.HotelsHistoryAuthorized, {params: data});
  },

  addHotelToHistoryAuthorized(data: {hotel_hids: []}) {
    return ApiService.post(CustomerApiResources.HotelsHistoryAuthorized, data);
  },

  removeHotelFromHistoryAuthorized(data: {hotel_hid: string}) {
    return ApiService.delete(CustomerApiResources.HotelsHistoryAuthorized, {params: data});
  },
};

export default CustomerService;

function setCustomerTokens(res: AxiosResponse) {
  if (res.data.scope[0] === ApiScope.Customer) {
    if ('access_token' in res.data) {
      TokenService.saveToken(ApiScope.Customer, res.data.access_token);
    }
    if ('refresh_token' in res.data) {
      TokenService.saveRefreshToken(ApiScope.Customer, res.data.refresh_token);
    }
  }
}
