import Vue from 'vue';

Vue.mixin({
  methods: {
    scrollToInvalid() {
      const invalidField = true;
      if (invalidField) {
        const firstInvalidField = document.querySelector('.md-invalid');
        if (firstInvalidField) {
          firstInvalidField.scrollIntoView({behavior: 'smooth'});
        }
      }
    },
  },
});
