import {ApiScope} from '../../constants/apiScope';

const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const TokenService = {
  getScopeData(scope: ApiScope): any {
    const store = localStorage.getItem(scope);
    return store ? JSON.parse(store) : {};
  },

  hasScopeData(scope: ApiScope): boolean {
    const data = this.getScopeData(scope);
    return !!Object.keys(data).length;
  },

  removeScopeData(scope: ApiScope): void {
    localStorage.removeItem(scope);
  },

  saveToken(scope: ApiScope, accessToken: string): void {
    const data = this.getScopeData(scope);
    data[TOKEN_KEY] = accessToken;
    localStorage.setItem(scope, JSON.stringify(data));
  },

  getToken(scope: ApiScope): string | null {
    const data = this.getScopeData(scope);
    return data && data[TOKEN_KEY] ? data[TOKEN_KEY] : null;
  },

  saveRefreshToken(scope: ApiScope, refreshToken: string): void {
    const data = this.getScopeData(scope);
    data[REFRESH_TOKEN_KEY] = refreshToken;
    localStorage.setItem(scope, JSON.stringify(data));
  },

  getRefreshToken(scope: ApiScope): string | null {
    const data = this.getScopeData(scope);
    return data && data[REFRESH_TOKEN_KEY] ? data[REFRESH_TOKEN_KEY] : null;
  },
};

export {TokenService};
