import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index';

Vue.use(Router);

const meta = {
  guestArea: true,
  title: 'Monthly Hotel（マンスリーホテル）',
  metaTags: [
    {
      name: 'description',
      content: 'Monthly Hotel（マンスリーホテル）',
    },
    {
      property: 'og:description',
      content: 'Monthly Hotel（マンスリーホテル）',
    },
  ],
};
const searchMeta = {...meta};
delete searchMeta.title;
const searchAlias: string[] = [...Array(4)].map(
  (a, b) => [...Array(b + 1)].map((e, i) => `:category_${i + 1}`).join('/') + '/search/:place_id',
);

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
  linkExactActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "customer-landing" */ '@/pages/common/public/ContactUs.vue'),
      name: 'contact-us',
      meta,
    },
    {
      path: '*',
      redirect: { name: 'contact-us' }
    },
  ],
});
function createCanonicalTag(params: {place_id: string}) {
  const canonicalTag: HTMLElement | null = document.getElementById('canonical');
  if (canonicalTag) {
    canonicalTag.remove();
  }
  let categoriesUrl: string = window.location.pathname.split('/search')[0];
  if (categoriesUrl.split('/').length > 2 || categoriesUrl.split('/').some((e: string) => e.length > 4)) {
    categoriesUrl = '';
  }
  const pathname = `${categoriesUrl}/search/${params.place_id ? params.place_id : ''}`;
  const result: HTMLElementTagNameMap['link'] = document.createElement('link');
  result.id = 'canonical';
  result.rel = 'canonical';
  result.href = `${window.location.origin}${pathname}`;
  document.head.appendChild(result);
}

router.beforeEach((to, from, next) => {
  /* private user pages checker */
  const privateArea = to.matched.some((record: any) => record.meta.profileArea);
  if (privateArea && !store.state.customer.user.isLoggedIn) {
    return next({
      path: '/',
    });
  }
  if (to.path === '/' || to.path.includes('/search')) {
    if (to.path.includes('/search')) {
      createCanonicalTag(to.params as {place_id: string});
      if (!to.params.place_id) {
        router.replace('/');
      }
    }
    let store: any = localStorage.getItem('vuex');
    if (store) {
      store = JSON.parse(store);
      if (
        store.customer.settings.google_ads_tracking &&
        store.customer.settings.google_ads_tracking.google_analytics_id
      ) {
        if (window && typeof (window as any).gtag !== 'undefined') {
          (window as any).gtag('config', store.customer.settings.google_ads_tracking.google_analytics_id, {
            page_title: to.name,
            page_path: to.path,
            custom_map: {
              dimension1: 'customer_type',
              dimension2: 'customer_mail',
            },
          });
        }
      }
    }
  }
  const nearestTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (nearestTitle) {
    document.title = nearestTitle.meta.title;
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el: any) => el.parentNode.removeChild(el));
  if (!nearestMeta) {
    return next();
  }
  nearestMeta.meta.metaTags
    .map((tagDef: any) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
    .forEach((tag: any) => document.head.appendChild(tag));
  next();
});

export default router;
