import Vue from 'vue';
import { DateFormat } from '../constants/dateFormat';

Vue.mixin({
  methods: {
    getDayOfWeek(days: number, dateFrom: Date, format: DateFormat | string = DateFormat.VueDayOfWeek): string {
      const dayNumber = Vue.filter('addDay')(dateFrom, days, format);
      return `shortDays.${Object.keys(this.$t('shortDays'))[dayNumber]}`;
    },
    getDayOfMonth(days: number, dateFrom: Date, format: DateFormat | string = DateFormat.VueDateMonthDay): string {
      return Vue.filter('addDay')(dateFrom, days, format);
    },
  },
});
