import Vue from 'vue';
import {AppScope, AppScopes} from '@/constants/appScope';
import {LocaleInterface} from '@/types/settingsInterface';

Vue.mixin({
  methods: {
    getScope(): AppScope | undefined {
      let scope;
      if (this.$route.meta.guestArea) {
        return AppScopes[4];
      }
      for (let i = 0; i < AppScopes.length; i++) {
        if (window.location.pathname.includes(AppScopes[i])) {
          scope = AppScopes[i];
        }
      }
      return scope;
    },
    getBrowserLanguage(): string {
      return window.navigator.languages
        ? window.navigator.languages[0]
        : window.navigator.language || (window.navigator as any).userLanguage;
    },
    getScopeLanguages(): LocaleInterface {
      const scope = this.getScope();
      if (scope && this.$store.state[AppScope.Customer] && scope === AppScope.Profile) {
        return this.$store.state[AppScope.Customer].settings.locales;
      }
      if (scope && this.$store.state[scope] && scope !== AppScope.Profile) {
        return this.$store.state[scope].settings.locales;
      }

      return {} as LocaleInterface;
    },
    getDefaultLocaleByBrowser(): Object {
      let locale = this.getBrowserLanguage();
      locale = locale.replace('-', '_').toLowerCase();
      const languages = this.getScopeLanguages();
      const keys = Object.keys(languages);
      if (process.env.RAILS_ENV === 'production') {
        return 'ja';
      } else {
        for (let language of keys) {
          if (locale.indexOf(language) !== -1) {
            return language;
          }
        }
      }
      return 'ja';
    },
    i18nTag(): string {
      const languages = this.getScopeLanguages();
      if (languages[this.$i18n.locale]) {
        return languages[this.$i18n.locale].tag;
      }
      const locale = this.getDefaultLocaleByBrowser();
      return languages[locale].tag;
    },
    includeEnglishLocale(): boolean {
      return Object.keys(this.$store.state.management.settings.locales).includes('en');
    },
    isEnglishLocale(): boolean {
      return this.i18nTag() === 'en';
    },
  },
});
