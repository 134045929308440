export const AdminApiResources = {
  signIn: 'wizard/auth/token',
  signOut: 'wizard/auth/revoke',
  refreshAdminToken: 'wizard/auth/refresh_access_token',
  resetMFA: 'wizard/auth/mfa_reset',
  mfaResetEmail: 'wizard/auth/mfa_reset_email',
  appSecret: 'wizard/auth/app_secret',
  appVerifyAndSave: 'wizard/auth/app_verify_and_save',
  smsSend: 'wizard/auth/sms_send',
  smsVerifyAndSave: 'wizard/auth/sms_verify_and_save',
  appAuth: 'wizard/auth/mfa_app_authentication',
  smsAuth: 'wizard/auth/mfa_sms_authentication',
  securityToken: 'wizard/security/token',
  securityMFA: 'wizard/security_mfa/index',
  securityApp: 'wizard/security_mfa/app_verify_and_save',
  securitySMSSend: 'wizard/security_mfa/sms_send',
  securitySMS: 'wizard/security_mfa/sms_verify_and_save',
  user: 'wizard/user',
  setPassword: 'wizard/user/set_password',
  admins: 'wizard/admins',
  setNewPassword: 'wizard/admins/set_new_password',
  sendResetPassword: 'wizard/admins/send_reset_password',
  permissions: 'wizard/admins/permissions',
  adminResetPassword: 'wizard/admins/send_reset_password_instructions',
  adminDeactivate: 'wizard/admins/deactivate',
  adminActivate: 'wizard/admins/activate',
  adminUpdate: 'wizard/admins/change_role',
  personnel: 'wizard/managers',
  accounts: 'wizard/accounts',
  accountDetails: 'wizard/accounts/details',
  sendInvitation: 'wizard/accounts/send_invitation',
  sendPasswordUpdate: 'wizard/accounts/send_password_update',
  impersonate: 'wizard/accounts/impersonate',
  hotels: 'wizard/hotels',
  hotelList: 'wizard/hotels/list',
  hotelDetails: 'wizard/hotels/details',
  hotelActive: 'wizard/hotels/toggle_active',
  hotelPublished: 'wizard/hotels/toggle_published',
  paymentSystemGMO: 'wizard/hotels/gmo_payment_system',
  transactions: 'wizard/transactions',
  transactionsCSV: 'wizard/transactions/download_csv',
  transactionsExpediaCSV: 'wizard/transactions/download_expedia_csv',
  toggleCampaignState: 'wizard/campaigns/hotels',
  campaigns: 'wizard/campaigns',
  campaignsBookings: 'wizard/campaigns/bookings',
  campaignsDetails: 'wizard/campaigns/show_details',
  campaignsBudget: 'wizard/campaigns/budget',
  campaignsActivation: 'wizard/campaigns/activation',
  getBudgetHistoryList: 'wizard/campaigns/discount_change_history',
  campaignsCSV: 'wizard/campaigns/csv',
  hotelGroups: 'wizard/hotel_groups',
  hotelGroupDetails: 'wizard/hotel_groups/details',
  hotelGroupHotels: 'wizard/hotel_groups/:hotel_group_id/hotels',
  ungroupedHotels: 'wizard/hotel_groups/ungrouped_hotels',
  landingCMSHotelGroups: 'wizard/landing_cms/hotel_groups',
  editHotelGroup: 'wizard/landing_cms/hotel_groups',
  publishHotelGroup: 'wizard/landing_cms/publish_hotel_group',
  hotelBlock: 'wizard/landing_cms/hotel_blocks',
  getHotelBlock: 'wizard/landing_cms/hotel_info',
  unpublishHotelGroup: 'wizard/landing_cms/unpublish_hotel_group',
  updateLicenceType: 'wizard/hotels/license_type',
  hotelGroupOrder: 'wizard/landing_cms/hotel_group_order',
  hotelBlockOrder: 'wizard/landing_cms/hotel_block_order',
  rakuten: 'wizard/rakuten_point_types',
  activateRakuten: 'wizard/rakuten_point_types/activation',
  postingSuspension: 'wizard/posting_suspensions',
};
export default AdminApiResources;
