import {Module} from 'vuex';
import {ApiScope} from '../../../../constants/apiScope';
import {UserStoreInterface} from '../../../../types/store/userStoreInterface';
import {AccountRoleInterface} from '../../../../types/accountInterface';
import {PasswordInterface} from '../../../../types/api/userInterface';
import {PermissionsInterface} from '../../../../types/permissionsInterface';
import AuthService from '../../../../services/wizard/auth.service';
import SecurityService from '../../../../services/wizard/security.service';
import UserService from '../../../../services/wizard/user.service';

const getDefaultState = () => {
  return {
    isLoggedIn: false,
    rememberMe: false,
    isRoot: false,
    list: [],
    listTotal: 0,
    listLoading: false,
    permissions: undefined,
    MFAMethod: undefined,
    phoneNumber: '',
    isInMFARecoveryProcess: false,
    isMFARecoveryParamsExpired: false,
  };
};

const userStore: Module<UserStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    changeSign(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    usersLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    usersLoaded(state, payload: AccountRoleInterface[]) {
      state.list = payload;
    },
    usersTotal(state, payload: number) {
      state.listTotal = payload;
    },
    setPermissions(state, payload: PermissionsInterface) {
      state.permissions = payload;
    },
    setMFAMethod(state, payload: string) {
      state.MFAMethod = payload;
    },
    setPhoneNumber(state, payload: string) {
      state.phoneNumber = payload;
    },
    setRememberMe(state, payload: boolean) {
      state.rememberMe = payload;
    },
    setMFARecoveryProcess(state, payload: boolean) {
      state.isInMFARecoveryProcess = payload;
    },
    setMFARecoveryExpired(state, payload: boolean) {
      state.isMFARecoveryParamsExpired = payload;
    },
    checkRoot(state, payload: boolean) {
      state.isRoot = payload;
    },
  },
  getters: {
    permissions(state): PermissionsInterface {
      return state.permissions ? state.permissions : {};
    },
  },
  actions: {
    signIn({dispatch, commit}, data: any) {
      const {rememberMe, ...cred} = data;
      return AuthService.login(cred, rememberMe).then((res) => {
        commit('setRememberMe', rememberMe);
        if (res.hasOwnProperty('is_mfa_recovery_params_expired')) {
          commit('setMFARecoveryExpired', res.is_mfa_recovery_params_expired);
        }
        if (res.hasOwnProperty('is_in_mfa_recovery_process')) {
          commit('setMFARecoveryProcess', res.is_in_mfa_recovery_process);
        }
        if (res.hasOwnProperty('is_a_root')) {
          commit('checkRoot', res.is_a_root);
        }
        return dispatch('authResponse', res);
      });
    },
    signOut({commit, dispatch}) {
      return AuthService.authRevoke().then((res) => {
        commit('management/impersonate/resetState', null, {root: true});
        dispatch('wizard/clearAll', null, {root: true});
        return res;
      });
    },
    getUserInfo() {
      return UserService.getUserInfo();
    },
    appRegistration({dispatch, state}, data: any) {
      return AuthService.appRegistration(data, state.rememberMe as boolean).then((res: any) => {
        return dispatch('getPermissions', res);
      });
    },
    appVerify({dispatch, state}, data: any) {
      return AuthService.appVerify(data, state.rememberMe as boolean).then((res: any) => {
        return dispatch('getPermissions', res);
      });
    },
    phoneRegistration({dispatch, state}, data: any) {
      return AuthService.phoneRegistration(data, state.rememberMe as boolean).then((res: any) => {
        return dispatch('getPermissions', res);
      });
    },
    phoneVerify({dispatch, state}, data: any) {
      return AuthService.phoneVerify(data, state.rememberMe as boolean).then((res: any) => {
        return dispatch('getPermissions', res);
      });
    },
    getList({commit}, data: any): any {
      commit('usersLoading', true);
      return UserService.getAdmins(data)
        .then((res: any) => {
          commit('usersLoaded', res.data.data);
          commit('usersTotal', res.data.total);
          return res.data;
        })
        .catch((error: any) => {
          return error;
        })
        .finally(() => {
          commit('usersLoading', false);
        });
    },
    resendEmail(context, params: any) {
      return UserService.adminResetPassword(params);
    },
    create(context, data): any {
      return UserService.createAdmin(data);
    },
    deactivate(context, params: any) {
      return UserService.deactivateAdmin(params);
    },
    activate(context, params: any): any {
      return UserService.activateAdmin(params);
    },
    update(context, params: any) {
      return UserService.updateAdmin(params);
    },
    deactivateMFA(context, data: any): any {
      return UserService.deactivateMFA(data);
    },
    sendResetPassword(context, email: string) {
      return UserService.sendResetPassword(email);
    },
    setNewPassword({dispatch}, data: PasswordInterface) {
      return UserService.setNewPassword(data).then((res) => {
        return dispatch('authResponse', res);
      });
    },
    updatePassword(context, data: PasswordInterface) {
      return UserService.updatePassword(data);
    },
    authResponse({dispatch, commit}, res: any) {
      if (res.scope && res.scope[0] === ApiScope.MFALoginAdmin) {
        commit('setMFAMethod', res.mfa_method);
        commit('setPhoneNumber', res.phone_number);
        return res;
      } else {
        return dispatch('getPermissions', res);
      }
    },
    getPermissions({commit}, res: any): any {
      return UserService.getPermissions().then(({data}) => {
        commit('setPermissions', data);
        commit('changeSign', true);
        return res;
      });
    },
    resetMFAMethod({commit}, data: any) {
      return UserService.resetMFAMethod(data).then((res) => {
        commit('setMFAMethod', undefined);
        commit('setPhoneNumber', '');
        return res;
      });
    },
    getSecretCode() {
      return SecurityService.getSecretCode();
    },
    sendSMS(context, data: any) {
      return SecurityService.sendSMS(data);
    },
    getCurrentMethod() {
      return SecurityService.getCurrentMethod();
    },
    getSecurityToken(context, data: any) {
      return SecurityService.getSecurityToken(data);
    },
    sendSecuritySMS(context, data: any) {
      return SecurityService.sendSecuritySMS(data);
    },
    updateSecurityApp(context, data: any) {
      return SecurityService.updateSecurityApp(data);
    },
    updateSecurityPhone(context, data: any) {
      return SecurityService.updateSecurityPhone(data);
    },
    sendMFAResetEmail() {
      return SecurityService.sendMFAResetEmail();
    },
  },
};

export default userStore;
