import Vue from 'vue';
import {RatePlan, RoomInterface} from '@/types/roomInterface';
import {BookingDataInterface} from '@/types/bookingInterface';

export default Vue.mixin({
  methods: {
    bookingPriceCalculation(
      room: RoomInterface,
      booking: BookingDataInterface,
      campaign: boolean,
      daily?: boolean,
    ): number {
      if (room && room.rate_plans) {
        const plan: RatePlan | undefined = room.rate_plans.find((plan: RatePlan) => {
          return plan.incl_breakfast === booking.incl_breakfast;
        });
        if (plan && plan.amount) {
          if (daily && plan.daily_amount) {
            return plan.daily_amount;
          }
          if (plan.amount_with_discount) {
            return campaign ? plan.amount_with_discount : plan.amount;
          }
        }
      }
      return 0;
    },
  },
});
