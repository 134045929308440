export enum ApiTokenCodes {
  TokenExpired = 'token_expired',
  InvalidToken = 'invalid_token',
  InvalidScope = 'invalid_scope',
  TokenRevoked = 'token_revoked',
  TokenUnknown = 'token_unknown',
  UserTemporaryBlocked = 'user_temporary_blocked',
}

export enum ApiUnavailableReasonCodes {
  NoActiveRoomsToBook = 'no_active_rooms_to_book',
  InclBreakfastOptionUnavailable = 'incl_breakfast_option_unavailable',
  BookingPeriodUnavailable = 'booking_period_unavailable',
  GuestsNumberOptionUnavailable = 'guests_number_option_unavailable',
  VerificationSmsTestingFailure = 'verification_sms_testing_failure',
  VerificationSmsInvalidPhoneNumber = 'verification_sms_invalid_phone_number',
  VerificationSmsSendingFailure = 'verification_sms_sending_failure',
  VerificationSmsAlreadySent = 'verification_sms_already_sent',
  GmoApiError = 'gmo_api_error',
  InvalidPassword = 'invalid_password',
  UnknownCustomerEmail = 'unknown_customer_email',
  CustomerNotConfirmedEmail = 'customer_not_confirmed',
}
