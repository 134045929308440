import Vue from 'vue';
import i18n from '@/plugins/i18n';

function valueYen(local: string): string {
  return String(i18n.t('common.currency.jpy', local));
}

Vue.filter('numeric', (value: number) => {
  return (value || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
});

Vue.filter('currency', (value: number | string, currency: string = valueYen('')) => {
  if (!value) {
    return null;
  }
  return currency === valueYen('ja') ? value + currency : currency + value;
});
