import {Module} from 'vuex';
import {ManagementHotelStoreInterface, HotelSearchInterface} from '@/types/store/hotelStoreInterface';
import {HotelCreateInterface, HotelInterfaceManagement} from '@/types/hotelInterface';
import HotelService from '@/services/management/hotel.service';
import {UpdateHotelParamsInterface} from '@/types/pages/management/updateHotelParamsInterface';
import {ApiTokenCodes} from '@/constants/apiResponseCodes';
import Beds24 from '@/services/management/beds24.service';
import {DictionariesInterface, DictionariesPromiseInterface} from '@/types/api/common/dictionariesInterface';

const getDefaultState = () => {
  return {
    list: [],
    listLoading: false,
    item: null,
    authData: null,
  };
};

const hotelStore: Module<ManagementHotelStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    hotelsLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    hotelsLoaded(state: any, payload: HotelInterfaceManagement[]) {
      state.list = payload;
    },
    hotelLoaded(state: any, payload: HotelInterfaceManagement) {
      state.item = payload;
    },
    setAuthData(state, payload) {
      state.authData = payload;
    },
  },
  actions: {
    getHotels(
      {commit},
      data = {
        page: 1,
        per_page: 99,
        search: '',
      },
    ): any {
      commit('hotelsLoading', true);
      return HotelService.getHotels(data)
        .then(({data}: {data: {hotels: HotelInterfaceManagement[]; total: number}}) => {
          commit('hotelsLoading', false);
          commit('hotelsLoaded', data.hotels);
          return data;
        })
        .catch((error: any) => {
          commit('hotelsLoading', false);
          if (error.response && error.response.data) {
            const data = JSON.parse(error.response.data);
            if (data.code === ApiTokenCodes.TokenUnknown) {
              commit('management/impersonate/resetState', null, {root: true});
            }
          }
        });
    },
    getHotelDetails({commit}, id: number): any {
      return HotelService.getHotelDetails(id)
        .then((res) => {
          commit('hotelLoaded', res.data);
          return res.data;
        })
        .catch((error: any) => {
          if (error.response && error.response.data) {
            const data = JSON.parse(error.response.data);
            if (data.code === ApiTokenCodes.TokenUnknown) {
              commit('management/impersonate/resetState', null, {root: true});
            }
          }
        });
    },
    setHotelPublishStatus({commit}, data: any): any {
      return HotelService.setHotelPublishStatus(data).then((res) => {
        commit('hotelLoaded', res.data.hotel);
        return res.data.hotel;
      });
    },
    addHotel(context, data: HotelCreateInterface): any {
      return HotelService.addHotel(data);
    },
    updateHotel({commit}, data: UpdateHotelParamsInterface): any {
      return HotelService.updateHotel(data).then((res) => {
        commit('hotelLoaded', res.data);
        return res.data;
      });
    },
    getHotelRoomTypes(context, id: number): any {
      return HotelService.getHotelRoomTypes(id).then((res) => {
        return res.data;
      });
    },
    getDictionaries(context, params: DictionariesInterface): Promise<DictionariesPromiseInterface> {
      return HotelService.getDictionaries(params)
        .then(({data}: {data: DictionariesPromiseInterface}) => {
          return data;
        })
        .catch(() => {
          return {
            objects: [],
          };
        });
    },
    getHotelCancellation(context, id: number): any {
      return HotelService.getCancellationPolicy(id);
    },
    updateHotelCancellation(context, data: any): any {
      return HotelService.updateCancellationPolicy(data);
    },
    updateHotelAllowBookingToday(context, data: any): any {
      return HotelService.updateHotelAllowBookingToday(data);
    },
    getHotelCheckin(context, data: any): any {
      return HotelService.getHotelCheckin(data);
    },
    updateHotelCheckin(context, data: any): any {
      return HotelService.updateHotelCheckin(data);
    },
    getBeds24Auth(context, hotel_id: number): any {
      return Beds24.getAuth(hotel_id);
    },
    updateBeds24Auth(context, data: {hotel_id: number; username: string; password: string}): any {
      return Beds24.updateAuth(data);
    },
    verifyBeds24Auth(context, data: any): any {
      return Beds24.verifyAuth(data);
    },
    getTLLincoln(context, hotel_id: number): any {
      return HotelService.getTLLincoln({hotel_id});
    },
    checkTLLincoln(context, data: any): any {
      return HotelService.checkTLLincoln(data);
    },
    updateTLLincoln(context, data: any): any {
      return HotelService.updateTLLincoln(data);
    },
    checkHotelTLLincoln(context, data: any): any {
      return HotelService.checkHotelTLLincoln(data);
    },
    getTemairazu(context, hotel_id: number): any {
      return HotelService.getTemairazu({hotel_id});
    },
    getRakutsu(context, hotel_id: number): any {
      return HotelService.getRakutsu({hotel_id});
    },
    getNeppan(context, hotel_id: number): any {
      return HotelService.getNeppan({hotel_id});
    },
    getGMOInfo(context, hotel_id: number): any {
      return HotelService.getGMOInfo(hotel_id);
    },
    updateGMOInfo(context, data: any): any {
      return HotelService.updateGMOInfo(data);
    },
    getPriceSettings(context, data: any): any {
      return HotelService.getPriceSettings(data);
    },
    updatePriceSettings(context, data: any): any {
      return HotelService.updatePriceSettings(data);
    },
    updateRatePlanPriceSettings(context, data: any): any {
      return HotelService.updateRatePlanPriceSettings(data);
    },
    getBookingRoomOptions(context, data: any): any {
      return HotelService.getBookingRoomOptions(data);
    },
    getBookingPlanOptions(context, data: any): any {
      return HotelService.getBookingPlanOptions(data);
    },
  },
  getters: {
    searchHotels: (state) => (params: HotelSearchInterface) => {
      let result = state.list;
      if (params.prefecture !== 'all') {
        result = result.filter((hotel) => hotel.prefecture_code === parseInt(params.prefecture, 10));
      }
      if (params.search) {
        const searchRegExp = new RegExp(params.search, 'i');
        result = result.filter((hotel) => {
          return (
            (hotel.name.en && hotel.name.en.search(searchRegExp) !== -1) ||
            (hotel.name.ja && hotel.name.ja.search(searchRegExp) !== -1) ||
            (hotel.name.ko && hotel.name.ko.search(searchRegExp) !== -1) ||
            (hotel.name['zh-TW'] && hotel.name['zh-TW'].search(searchRegExp) !== -1) ||
            (hotel.name['zh-CN'] && hotel.name['zh-CN'].search(searchRegExp) !== -1) ||
            (hotel.address.en && hotel.address.en.search(searchRegExp) !== -1) ||
            (hotel.address.ja && hotel.address.ja.search(searchRegExp) !== -1) ||
            (hotel.address.ko && hotel.address.ko.search(searchRegExp) !== -1) ||
            (hotel.address['zh-TW'] && hotel.address['zh-TW'].search(searchRegExp) !== -1) ||
            (hotel.address['zh-CN'] && hotel.address['zh-CN'].search(searchRegExp) !== -1)
          );
        });
      }
      return result;
    },
  },
};

export default hotelStore;
