import {AppliedCampaignsInterface} from '@/types/components/common/appliedCampaignsInterface';
import {CampaignTypes} from '@/constants/campaignTypes';
import {groupByMixin} from '@/mixins/groupBy';

export const campaignsMixin = {
  mixins: [groupByMixin],
  methods: {
    filterCampaigns(appliedCampaigns: any, type?: string): AppliedCampaignsInterface[] | {} {
      if (appliedCampaigns) {
        const campaign_keys: any = Object.keys(appliedCampaigns);
        let filtered_campaigns: string[];
        if (!type) {
          type = 'cashback_voucher';
          filtered_campaigns = campaign_keys.filter((key: any) => appliedCampaigns[key].campaign_type !== type);
        } else {
          filtered_campaigns = campaign_keys.filter((key: any) => appliedCampaigns[key].campaign_type === type);
        }
        return filtered_campaigns.map((key: string) => appliedCampaigns[key]);
      }
      return {};
    },
    combineActivationCodeCampaigns(appliedCampaigns: AppliedCampaignsInterface[]): AppliedCampaignsInterface[] {
      const groupedCampaigns: {[key: string]: AppliedCampaignsInterface[]} = (this as any).groupBy(
        Object.values(appliedCampaigns),
        'campaign_type',
      );
      const keys: string[] = Object.keys(groupedCampaigns);
      let campaigns: AppliedCampaignsInterface[] = [];
      keys.map((key: string) => {
        let group: AppliedCampaignsInterface[] = [];
        if (key === CampaignTypes.amount_with_activation_code) {
          group.push({
            campaign_name: 'クーポン対象',
            discount_amount: 0,
          });
        } else {
          group = groupedCampaigns[key];
        }
        campaigns = campaigns.concat(group);
      });
      return campaigns;
    },
  },
};
