import ManagementApiResources from '../../constants/api/resources/management';
import {ApiScope} from '../../constants/apiScope';
import {ConnectStripeInterface, PasswordInterface, TosAcceptanceInterface} from '../../types/api/userInterface';
import {TokenService} from '../_helper/storage.service';
import ApiService from '../_helper/api.service';

const UserService = {
  login(data: any, rememberMe: boolean) {
    return ApiService.post(ManagementApiResources.signIn, data).then((res) => {
      if (res.data['access_token']) {
        if (res.data.scope[0] === ApiScope.MFALoginManager) {
          TokenService.saveToken(ApiScope.MFALoginManager, res.data['access_token']);
          return res.data;
        } else if (res.data.scope[0] === ApiScope.Manager) {
          TokenService.saveToken(ApiScope.Manager, res.data['access_token']);
          if (rememberMe && res.data['refresh_token']) {
            TokenService.saveRefreshToken(ApiScope.Manager, res.data['refresh_token']);
          }
          TokenService.removeScopeData(ApiScope.ImpersonateSession);
          TokenService.removeScopeData(ApiScope.MFALoginManager);
          return this.getAccountState();
        }
      }
      return res.data;
    });
  },

  authComplete(data: any, rememberMe: boolean) {
    TokenService.removeScopeData(ApiScope.ImpersonateSession);
    TokenService.removeScopeData(ApiScope.MFALoginManager);
    if (data['access_token']) {
      TokenService.saveToken(ApiScope.Manager, data['access_token']);
    }
    if (rememberMe && data['refresh_token']) {
      TokenService.saveRefreshToken(ApiScope.Manager, data['refresh_token']);
    }
    return this.getAccountState();
  },

  sendResetPassword(email: string) {
    return ApiService.post(ManagementApiResources.sendResetPassword, {email});
  },

  setNewPassword(data: PasswordInterface, isNew: boolean = true) {
    const point = isNew ? ManagementApiResources.setNewPassword : ManagementApiResources.completeSignUp;
    return ApiService.post(point, data).then((res) => {
      return this.afterSetPassword(res);
    });
  },

  updatePassword(data: any) {
    return ApiService.patch(ManagementApiResources.setPassword, data).then((res) => {
      return this.afterSetPassword(res);
    });
  },

  afterSetPassword(res: any) {
    if (res.data['access_token']) {
      if (res.data.scope[0] === ApiScope.MFALoginManager) {
        TokenService.saveToken(ApiScope.MFALoginManager, res.data['access_token']);
        return res.data;
      } else if (res.data.scope[0] === ApiScope.Manager) {
        TokenService.saveToken(ApiScope.Manager, res.data['access_token']);
        return this.getAccountState();
      }
    }
    return res.data;
  },

  authRevoke() {
    const refreshToken = TokenService.getRefreshToken(ApiScope.Manager);
    if (refreshToken) {
      ApiService.post(ManagementApiResources.signOut, {token: refreshToken});
    }
    const token = TokenService.getToken(ApiScope.Manager);
    if (token) {
      ApiService.post(ManagementApiResources.signOut, {token});
      TokenService.removeScopeData(ApiScope.Manager);
    }
    return Promise.resolve(true);
  },

  getUserInfo() {
    return ApiService.get(ManagementApiResources.user);
  },

  getAccountState() {
    return ApiService.get(ManagementApiResources.accountState);
  },

  connectStripe(data: ConnectStripeInterface) {
    return ApiService.post(ManagementApiResources.connectStripe, data);
  },

  tosAcceptance(data: TosAcceptanceInterface) {
    return ApiService.post(ManagementApiResources.tosAcceptance, data);
  },

  getStripeData() {
    return ApiService.get(ManagementApiResources.dataStripe);
  },

  generateUniquePlanCode() {
    return ApiService.get(ManagementApiResources.generateUniquePlanCode);
  },

  changeStripeData(data: ConnectStripeInterface) {
    return ApiService.put(ManagementApiResources.dataStripe, data);
  },

  stopImpersonate() {
    if (TokenService.hasScopeData(ApiScope.ImpersonateSession)) {
      TokenService.removeScopeData(ApiScope.ImpersonateSession);
      window.location.href = window.location.origin + '/wizard/';
    }
  },

  getSecretCode() {
    return ApiService.get(ManagementApiResources.appSecret);
  },

  appRegistration(params: any, rememberMe: boolean) {
    return ApiService.post(ManagementApiResources.appVerifyAndSave, params).then(({data}) => {
      return this.authComplete(data, rememberMe);
    });
  },

  appVerify(params: any, rememberMe: boolean) {
    return ApiService.post(ManagementApiResources.appAuth, params).then(({data}) => {
      return this.authComplete(data, rememberMe);
    });
  },

  phoneRegistration(params: any, rememberMe: boolean) {
    return ApiService.post(ManagementApiResources.smsVerifyAndSave, params).then(({data}) => {
      return this.authComplete(data, rememberMe);
    });
  },

  phoneVerify(params: any, rememberMe: boolean) {
    return ApiService.post(ManagementApiResources.smsAuth, params).then(({data}) => {
      return this.authComplete(data, rememberMe);
    });
  },

  sendSMS(params: any) {
    return ApiService.get(ManagementApiResources.smsSend, {params});
  },

  sendSecuritySMS(params: any) {
    return ApiService.get(ManagementApiResources.securitySMSSend, {params});
  },

  getCurrentMethod() {
    return ApiService.get(ManagementApiResources.securityMFA);
  },

  getSecurityToken(params: any) {
    return ApiService.post(ManagementApiResources.securityToken, params).then(({data}) => {
      if (data['access_token']) {
        TokenService.saveToken(ApiScope.ManagerSecurity, data['access_token']);
      }
      return data;
    });
  },

  updateSecurityApp(data: any) {
    return ApiService.post(ManagementApiResources.securityApp, data).then((res) => {
      TokenService.removeScopeData(ApiScope.ManagerSecurity);
      return res.status;
    });
  },

  updateSecurityPhone(data: any) {
    return ApiService.post(ManagementApiResources.securitySMS, data).then((res) => {
      TokenService.removeScopeData(ApiScope.ManagerSecurity);
      return res.status;
    });
  },

  sendMFAResetEmail() {
    return ApiService.get(ManagementApiResources.mfaResetEmail);
  },

  resetMFAMethod(data: any) {
    return ApiService.post(ManagementApiResources.resetMFA, data).then((res) => {
      if (res.data['access_token']) {
        if (res.data.scope[0] === ApiScope.MFALoginManager) {
          TokenService.saveToken(ApiScope.MFALoginManager, res.data['access_token']);
          return res.data;
        }
      }
      return res.data;
    });
  },
};

export default UserService;
