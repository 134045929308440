import Vue from 'vue';

Vue.mixin({
  methods: {
    resizeTabHeight(tabsID: string, tabID: string): void {
      const tab = document.querySelector(tabID) as Element;
      const settingsTabs = document.querySelector(`${tabsID} .md-content`) as Element;
      if (tab && settingsTabs) {
        settingsTabs.setAttribute('style', `height: ${tab.clientHeight}px`);
      }
    },
  },
});
