import {Module} from 'vuex';
import HotelService from '@/services/landing/hotel.service';
import {LandingHotelStoreInterface} from '@/types/store/hotelStoreInterface';
import {RatePlan, RoomInterface} from '@/types/roomInterface';
import {HotelReviewRequestInterface} from '@/types/api/common/hotelReviewInterface';
import {getRoomAvailabilityParams} from '@/types/api/landing/getRoomAvailabilityParams';

const getDefaultState = () => {
  return {
    item: {
      address: {
        en: '',
        ja: '',
      },
      name: {
        en: '',
        ja: '',
      },
      hotel_hid: '',
      logo_url: '',
      phone: '',
      updated_at: '',
      checkin_from: '',
      checkin_until: '',
      checkout_until: '',
      cancellation_policy: {
        default_rate: 0,
        higher_rate: 0,
        days_before_start: 0,
      },
      max_room_types_capacity: 0,
    },
    room: {
      hid: '',
      description: {
        en: '',
        ja: '',
      },
      name: {
        en: '',
        ja: '',
      },
      payment_details: {
        en: '',
        ja: '',
      },
      amenities: [],
      images: [],
      rate_plans: [],
    },
    reviewListLoading: false,
  } as LandingHotelStoreInterface;
};

const hotelStore: Module<LandingHotelStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setHotel(state, hotel) {
      state.item = {...hotel};
    },
    reviewLoading(state, payload: boolean) {
      state.reviewListLoading = payload;
    },
    setRoomInfo(state, room) {
      state.room = {...room};
    },
    updateRoomData(state, payload: {index: number; roomData: RatePlan}) {
      const clonedRoom: RoomInterface = {...state.room};
      clonedRoom.rate_plans![payload.index] = payload.roomData;
      state.room = {...clonedRoom};
    },
  },
  actions: {
    getPopularHotels(context, hid: string) {
      return HotelService.getPopularHotels(hid);
    },
    getRoomTypeInfo(context, data) {
      return HotelService.getRoomTypeInfo(data);
    },
    getRoomAvailabilityHash(context, data: getRoomAvailabilityParams) {
      return HotelService.getRoomAvailabilityHash(data);
    },
    getExpediaRoomAvailabilityHash(context, data: getRoomAvailabilityParams) {
      return HotelService.getExpediaRoomAvailabilityHash(data);
    },
    getRoomData(context, data: getRoomAvailabilityParams) {
      return HotelService.getRoomData(data);
    },
    getCancellationPolicy(context, id: string) {
      return HotelService.showCancellationPolicy(id);
    },
    getLegalInfo(context, hid: string) {
      return HotelService.getLegalInfo(hid);
    },
    getHotelReview({commit}, data: HotelReviewRequestInterface) {
      commit('reviewLoading', true);
      return HotelService.getHotelReview(data).finally(() => {
        commit('reviewLoading', false);
      });
    },
    updateHotel({commit}, data) {
      commit('setHotel', data);
    },
    resetState({commit}, data) {
      if (data) {
        commit('setHotel', data.current_hotel);
        commit('setRoomInfo', getDefaultState().room);
      }
    },
  },
};

export default hotelStore;
