import {Module} from 'vuex';
import CustomerService from '@/services/customer/customer.service';
import {HotelInterfaceCustomer} from '@/types/hotelInterface';
import {HotelsStoreInterface} from '@/types/store/customer/hotels/hotelsStoreInterface';
import {AxiosPromise} from 'axios';
import {HotelReviewInterface} from '@/types/api/common/hotelReviewInterface';
import {LandingSearchInterface} from '@/types/api/customer/landingSearchInterface';

const getDefaultState = () => {
  return {
    list: [] as HotelInterfaceCustomer[],
    nextList: [] as HotelInterfaceCustomer[],
    listLoading: false,
    total: 0,
    priceFilterRange: {
      min: 0,
      max: 0,
    },
  } as HotelsStoreInterface;
};

const hotelStore: Module<HotelsStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    hotelLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    hotelLoaded(state, payload: HotelInterfaceCustomer[]) {
      state.list = payload;
    },
    nextHotelLoaded(state, payload: HotelInterfaceCustomer[]) {
      state.nextList = payload;
    },
    setDailyPrice(state, payload: {min: number; max: number}) {
      state.priceFilterRange = payload;
    },
    totalPages(state, payload: number) {
      state.total = payload;
    },
  },
  actions: {
    getCategoriesHotels(context, data: {prefecture_id: string; small_area_id?: string}) {
      return CustomerService.getCategoriesHotels(data);
    },
    getHotelsGroup() {
      return CustomerService.getHotelsGroup();
    },
    getList({commit}, data: any): AxiosPromise<any> {
      return CustomerService.getHotels(data);
    },
    getFAQItems(context, data: LandingSearchInterface) {
      return CustomerService.getFAQItems(data);
    },
    checkReviewLink(context, booking_code: string): AxiosPromise<any> {
      return CustomerService.checkReviewLink(booking_code);
    },
    setHotelReview(context, data: HotelReviewInterface): AxiosPromise<any> {
      return CustomerService.setHotelReview(data);
    },
    resetState({commit}) {
      commit('resetState', getDefaultState());
    },
  },
};

export default hotelStore;
