import moment from 'moment';
import Vue from 'vue';

Vue.mixin({
  methods: {
    dateParser(date: string, useTime = false, lowerCase = false): string {
      const momentDate = moment(date);
      if (this.isEnglishLocale()) {
        const format = useTime ? 'ddd, MMM D h:mm A' : 'ddd, MMM D';
        return `${lowerCase ? 'b' : 'B'}efore ${momentDate.format(format)}`;
      } else {
        const year = momentDate.format('YYYY');
        const month = momentDate.format('M');
        const day = momentDate.format('D');
        const hours = momentDate.format('HH');
        const minutes = momentDate.format('mm');
        return `${year}年${month}月${day}日${hours}時${minutes}分 より前`;
      }
    },
  },
});
