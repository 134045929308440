import {Module} from 'vuex';
import hotelStore from './modules/hotel';
import bookingStore from './modules/booking';
import {LandingStoreInterface} from '@/types/store/landing/landingStoreInterface';

const defaultState: LandingStoreInterface = {
  settings: {
    booking_data: {
      checkin: '',
      checkout: '',
      guests_number: 0,
      incl_breakfast: false,
      room_type_hid: '',
    },
    booking_request_expiration_in: 0,
    amenities: [],
    prefectures: {},
    account_source: undefined,
    payment_system: undefined,
    payment_methods: [],
    locales: {},
    ab_testing_layout: 0,
  },
};

const store: Module<LandingStoreInterface, any> = {
  namespaced: true,
  modules: {
    hotel: hotelStore,
    booking: bookingStore,
  },
  state: defaultState,
  mutations: {
    setSettings(state, settings) {
      state.settings = {...state.settings, ...settings};
    },
    setContextSettings(state, settings) {
      state.settings = {...state.settings, ...settings};
    },
    setLayoutVariant(state, payload: 0 | 1) {
      state.settings.ab_testing_layout = payload;
    },
  },
  actions: {
    init({state, dispatch}) {
      const defaultKeys = Object.keys(defaultState.settings).sort();
      const currentKeys = Object.keys(state.settings).sort();
      defaultKeys.forEach((key: string) => {
        if (!currentKeys.includes(key)) {
          dispatch('clearAll');
          return;
        }
      });
    },
    clearAll({dispatch}, data) {
      dispatch('hotel/resetState', data);
      dispatch('booking/resetState');
    },
  },
};

export default store;
