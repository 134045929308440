const FileService = {
  saveCSV(content: string, name: string, content_type: string = 'data:text/csv;charset=utf-8,', bom: boolean = true) {
    content = content.replace(/[#?%$@&+=;:]/g, '');
    const csvContent = bom ? content_type + '\uFEFF' + content : content_type + content;
    const encodedUri = encodeURI(csvContent);
    this.saveFileByUrl(encodedUri, name);
  },

  saveFile(byteArray: any[], content_type: string, name: string) {
    const newUrl = window.URL.createObjectURL(new Blob(byteArray, {type: content_type}));
    this.saveFileByUrl(newUrl, name, true);
  },

  saveFileByUrl(url: string, name: string, needRemove: boolean = false) {
    const a: any = document.createElement('a');
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    if (needRemove) {
      window.URL.revokeObjectURL(url);
    }
    a.remove();
  },
};

export default FileService;
