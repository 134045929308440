import Vue from 'vue';
import { Module } from 'vuex';
import i18n from '../../plugins/i18n';

const store: Module<any, any> = {
  namespaced: true,
  actions: {
    changeMaterialLocation() {
      const locale = (Vue as any).material.locale;
      locale.days = [
        i18n.t('days.sunday'),
        i18n.t('days.monday'),
        i18n.t('days.tuesday'),
        i18n.t('days.wednesday'),
        i18n.t('days.thursday'),
        i18n.t('days.friday'),
        i18n.t('days.saturday'),
      ];
      locale.shortDays = [
        i18n.t('shortDays.sun'),
        i18n.t('shortDays.mon'),
        i18n.t('shortDays.tue'),
        i18n.t('shortDays.wed'),
        i18n.t('shortDays.thu'),
        i18n.t('shortDays.fri'),
        i18n.t('shortDays.sat'),
      ];
      locale.shorterDays = [
        i18n.t('shortDays.sun'),
        i18n.t('shortDays.mon'),
        i18n.t('shortDays.tue'),
        i18n.t('shortDays.wed'),
        i18n.t('shortDays.thu'),
        i18n.t('shortDays.fri'),
        i18n.t('shortDays.sat'),
      ];
      locale.months = [
        i18n.t('months.january'),
        i18n.t('months.february'),
        i18n.t('months.march'),
        i18n.t('months.april'),
        i18n.t('months.may'),
        i18n.t('months.june'),
        i18n.t('months.july'),
        i18n.t('months.august'),
        i18n.t('months.september'),
        i18n.t('months.october'),
        i18n.t('months.november'),
        i18n.t('months.december'),
      ];
      locale.shortMonths = [
        i18n.t('shortMonths.jan'),
        i18n.t('shortMonths.feb'),
        i18n.t('shortMonths.mar'),
        i18n.t('shortMonths.apr'),
        i18n.t('shortMonths.may'),
        i18n.t('shortMonths.june'),
        i18n.t('shortMonths.july'),
        i18n.t('shortMonths.aug'),
        i18n.t('shortMonths.sep'),
        i18n.t('shortMonths.oct'),
        i18n.t('shortMonths.nov'),
        i18n.t('shortMonths.dec'),
      ];
    },
  },
};

export default store;
