import ApiService from '../_helper/api.service';
import AdminApiResources from '../../constants/api/resources/wizard';
import { ApiScope } from '../../constants/apiScope';
import { TokenService } from '../_helper/storage.service';

const SecurityService = {
  getSecretCode() {
    return ApiService.get(AdminApiResources.appSecret);
  },

  sendSMS(params: any) {
    return ApiService.get(AdminApiResources.smsSend, { params });
  },

  sendSecuritySMS(params: any) {
    return ApiService.get(AdminApiResources.securitySMSSend, { params });
  },

  getCurrentMethod() {
    return ApiService.get(AdminApiResources.securityMFA);
  },

  getSecurityToken(data: any) {
    return ApiService.post(AdminApiResources.securityToken, data).then(({ data }) => {
      if (data['access_token']) {
        TokenService.saveToken(ApiScope.AdminSecurity, data['access_token']);
      }
      return data;
    });
  },

  updateSecurityApp(data: any) {
    return ApiService.post(AdminApiResources.securityApp, data).then(res => {
      TokenService.removeScopeData(ApiScope.AdminSecurity);
      return res.status;
    });
  },

  updateSecurityPhone(data: any) {
    return ApiService.post(AdminApiResources.securitySMS, data).then(res => {
      TokenService.removeScopeData(ApiScope.AdminSecurity);
      return res.status;
    });
  },

  sendMFAResetEmail() {
    return ApiService.get(AdminApiResources.mfaResetEmail);
  },
};

export default SecurityService;
