export enum AppScope {
  Dashboard = 'dashboard',
  Landing = 'landing',
  Management = 'management',
  Wizard = 'wizard',
  Customer = 'customer',
  Profile = 'profile',
}

export type AppScopeType = AppScope.Dashboard | AppScope.Landing | AppScope.Management | AppScope.Wizard;

export const AppScopes = [
  AppScope.Dashboard,
  AppScope.Landing,
  AppScope.Management,
  AppScope.Wizard,
  AppScope.Customer,
  AppScope.Profile,
];
export const AppPublicScopes = [AppScope.Dashboard, AppScope.Landing, AppScope.Customer, AppScope.Profile];
export const AppProtectedScopes = [AppScope.Management, AppScope.Wizard];
