import {Module} from 'vuex';
import {UserStoreInterface} from '../../../../types/store/userStoreInterface';
import {PermissionsInterface} from '../../../../types/permissionsInterface';
import {PasswordInterface, ConnectStripeInterface, TosAcceptanceInterface} from '../../../../types/api/userInterface';
import {ApiScope} from '../../../../constants/apiScope';
import UserService from '../../../../services/management/user.service';

const defaultState = {
  isLoggedIn: false,
  rememberMe: false,
  isRoot: false,
  paymentSystem: undefined,
  paymentMethods: undefined,
  paymentSystemConnect: false,
  source: undefined,
  companyName: undefined,
  permissions: undefined,
  MFAMethod: undefined,
  phoneNumber: '',
  isInMFARecoveryProcess: false,
  isMFARecoveryParamsExpired: false,
  tos_acceptance: '',
};

const userStore: Module<UserStoreInterface, any> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    resetState(state: any) {
      const s: any = {...defaultState};
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setUserData(state: any, payload: any) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    changeSign(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    setPermissions(state, payload: PermissionsInterface) {
      state.permissions = payload;
    },
    setMFAMethod(state, payload: string) {
      state.MFAMethod = payload;
    },
    setPhoneNumber(state, payload: string) {
      state.phoneNumber = payload;
    },
    setRememberMe(state, payload: boolean) {
      state.rememberMe = payload;
    },
    setMFARecoveryProcess(state, payload: boolean) {
      state.isInMFARecoveryProcess = payload;
    },
    setMFARecoveryExpired(state, payload: boolean) {
      state.isMFARecoveryParamsExpired = payload;
    },
    checkRoot(state, payload: boolean) {
      state.isRoot = payload;
    },
  },
  actions: {
    init({state, dispatch}) {
      const defaultKeys = Object.keys(defaultState).sort();
      const currentKeys = Object.keys(state).sort();
      defaultKeys.forEach((key: string) => {
        if (!currentKeys.includes(key)) {
          dispatch('resetState');
        }
      });
    },
    signIn({dispatch, commit}, data: any) {
      const {rememberMe, ...cred} = data;
      return UserService.login(cred, rememberMe).then((res) => {
        commit('setRememberMe', rememberMe);
        if (res.hasOwnProperty('is_mfa_recovery_params_expired')) {
          commit('setMFARecoveryExpired', res.is_mfa_recovery_params_expired);
        }
        if (res.hasOwnProperty('is_in_mfa_recovery_process')) {
          commit('setMFARecoveryProcess', res.is_in_mfa_recovery_process);
        }
        if (res.hasOwnProperty('is_a_root')) {
          commit('checkRoot', res.is_a_root);
        }
        return dispatch('authResponse', res);
      });
    },
    authResponse({dispatch}, res: any) {
      if (res.scope && res.scope[0] === ApiScope.MFALoginManager) {
        return dispatch('setMFAData', res);
      } else {
        return dispatch('getPermissions', res);
      }
    },
    signOut({commit}) {
      return UserService.authRevoke().then(() => {
        commit('management/impersonate/resetState', null, {root: true});
        commit('changeSign', false);
      });
    },
    getUserInfo() {
      return UserService.getUserInfo();
    },
    connectStripe(context, data: ConnectStripeInterface) {
      return UserService.connectStripe(data);
    },
    tosAcceptance(context, data: TosAcceptanceInterface) {
      return UserService.tosAcceptance(data);
    },
    getStripeData() {
      return UserService.getStripeData();
    },
    generateUniquePlanCode() {
      return UserService.generateUniquePlanCode();
    },
    changeStripeData(context, data: ConnectStripeInterface) {
      return UserService.changeStripeData(data);
    },
    sendResetPassword(context, email: string) {
      return UserService.sendResetPassword(email);
    },
    setNewPassword({dispatch}, data: PasswordInterface) {
      return UserService.setNewPassword(data).then((res) => {
        return dispatch('authResponse', res);
      });
    },
    updatePassword(context, data: PasswordInterface) {
      return UserService.updatePassword(data);
    },
    completeSignUp({dispatch}, data: PasswordInterface) {
      return UserService.setNewPassword(data, false).then((res) => {
        return dispatch('authResponse', res);
      });
    },
    stopImpersonate({commit}) {
      commit('management/impersonate/resetState', null, {root: true});
      UserService.stopImpersonate();
    },
    getSecretCode() {
      return UserService.getSecretCode();
    },
    appRegistration({dispatch, state}, data: any) {
      return UserService.appRegistration(data, state.rememberMe as boolean).then((res: any) => {
        return dispatch('getPermissions', res);
      });
    },
    appVerify({dispatch, state}, data: any) {
      return UserService.appVerify(data, state.rememberMe as boolean).then((res: any) => {
        return dispatch('getPermissions', res);
      });
    },
    sendSMS(context, data: any) {
      return UserService.sendSMS(data);
    },
    phoneRegistration({dispatch, state}, data: any) {
      return UserService.phoneRegistration(data, state.rememberMe as boolean).then((res: any) => {
        return dispatch('getPermissions', res);
      });
    },
    phoneVerify({dispatch, state}, data: any) {
      return UserService.phoneVerify(data, state.rememberMe as boolean).then((res: any) => {
        return dispatch('getPermissions', res);
      });
    },
    getCurrentMethod() {
      return UserService.getCurrentMethod();
    },
    getSecurityToken(context, data: any) {
      return UserService.getSecurityToken(data);
    },
    sendSecuritySMS(context, data: any) {
      return UserService.sendSecuritySMS(data);
    },
    updateSecurityApp(context, data: any) {
      return UserService.updateSecurityApp(data);
    },
    updateSecurityPhone(context, data: any) {
      return UserService.updateSecurityPhone(data);
    },
    getPermissions({dispatch, commit}, payload: any) {
      return dispatch('management/personnel/getPermissions', null, {root: true}).then(({data}) => {
        commit('setPermissions', data);
        commit('setUserData', {
          isLoggedIn: true,
          paymentSystem: payload.data.payment_system,
          paymentMethods: payload.data.payment_methods,
          companyName: payload.data.company_name,
          paymentSystemConnect: !!payload.data.payment_system_connect,
          source: payload.data.source,
        });
        return payload;
      });
    },
    setMFAData({commit}, data: any) {
      commit('setMFAMethod', data.mfa_method);
      commit('setPhoneNumber', data.phone_number);
      return data;
    },
    sendMFAResetEmail() {
      return UserService.sendMFAResetEmail();
    },
    resetMFAMethod({commit}, data: any) {
      return UserService.resetMFAMethod(data).then((res) => {
        commit('setMFAMethod', undefined);
        commit('setPhoneNumber', '');
        return res;
      });
    },
  },
};

export default userStore;
