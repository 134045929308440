export enum DateFormat {
  ServerDate = 'YYYY-MM-DD',
  VueDate = 'YYYY/MM/DD',
  AdDate = 'YYYY_MM_DD',
  VueDateTime = 'YYYY/MM/DD HH:mm',
  VueDateMonth = 'MMM YYYY',
  VueDateDayMonth = 'DD/MM',
  VueDateMonthDay = 'MM/DD',
  VueDayOfWeek = 'e',
  VueWeekDayMonthYear = 'ddd D MMM YYYY',
}
