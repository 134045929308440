import {Module} from 'vuex';
import {TypedTransactionStoreInterface} from '@/types/store/transactionStoreInterface';
import {TransactionInterface} from '@/types/transactionInterface';
import TransactionService from '@/services/wizard/transaction.service';

const getDefaultState = () => {
  return {
    'system-commission': {
      list: [],
      total_hotel_revenue_system: 0,
      total_payment_service_fee: 0,
      total_application_fee: 0,
      total_system_fee: 0,
      total: 0,
      listLoading: false,
    },
    'transaction-commission': {
      list: [],
      total_hotel_revenue: 0,
      total_payment_service_fee: 0,
      total_application_fee: 0,
      total_system_fee: 0,
      total: 0,
      listLoading: false,
    },
  };
};

const transactionStore: Module<TypedTransactionStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    listLoading(state, payload: {loading: boolean; type: 'transaction-commission' | 'system-commission'}) {
      state[payload.type].listLoading = payload.loading;
    },
    listLoaded(
      state,
      payload: {transactions: TransactionInterface[]; type: 'transaction-commission' | 'system-commission'},
    ) {
      state[payload.type].list = payload.transactions;
    },
    totalsLoaded(state, payload: {data: any; type: 'transaction-commission' | 'system-commission'}) {
      if (payload.type === 'system-commission') {
        state[payload.type].total_hotel_revenue_system = payload.data.total_hotel_revenue_system;
      } else {
        state[payload.type].total_hotel_revenue = payload.data.total_hotel_revenue;
      }
      state[payload.type].total_payment_service_fee = payload.data.total_payment_service_fee;
      state[payload.type].total_application_fee = payload.data.total_application_fee;
      state[payload.type].total_system_fee = payload.data.total_system_fee;
      state[payload.type].total = payload.data.total;
    },
  },
  actions: {
    getList({commit}, data: any): any {
      commit('listLoading', {loading: true, type: data.type});
      return TransactionService.getTransactions(data)
        .then((res: any) => {
          commit('listLoaded', {transactions: res.data.transactions, type: data.type});
          commit('totalsLoaded', {data: res.data, type: data.type});
          return res;
        })
        .catch((error: any) => {
          return error;
        })
        .finally(() => {
          commit('listLoading', {loading: false, type: data.type});
        });
    },
    downloadCSV(context, data: any): any {
      return TransactionService.downloadTransactionsCSV(data);
    },
    downloadExpediaCSV(context, data: any): any {
      return TransactionService.downloadTransactionsCSV(data, true);
    },
  },
};

export default transactionStore;
