import ApiService from '../_helper/api.service';
import AdminApiResources from '../../constants/api/resources/wizard';
import { ApiScope } from '../../constants/apiScope';
import { PasswordInterface } from '../../types/api/userInterface';
import { TokenService } from '../_helper/storage.service';

const UserService = {
  getUserInfo() {
    return ApiService.get(AdminApiResources.user);
  },

  getAdmins(params: any = {}) {
    return ApiService.get(AdminApiResources.admins, { params: params });
  },

  createAdmin(data: any) {
    return ApiService.post(AdminApiResources.admins, data);
  },

  adminResetPassword(params: any = {}) {
    return ApiService.get(AdminApiResources.adminResetPassword, { params: params });
  },

  deactivateAdmin(data: any) {
    return ApiService.post(AdminApiResources.adminDeactivate, data);
  },

  activateAdmin(data: any) {
    return ApiService.post(AdminApiResources.adminActivate, data);
  },

  updateAdmin(data: any) {
    return ApiService.put(AdminApiResources.adminUpdate, data);
  },

  deactivateMFA(data: any) {
    return ApiService.put(AdminApiResources.admins + '/mfa_deactivate', data);
  },

  sendResetPassword(email: string) {
    return ApiService.post(AdminApiResources.sendResetPassword, { email });
  },

  setNewPassword(data: PasswordInterface) {
    return ApiService.post(AdminApiResources.setNewPassword, data).then(res => {
      return this.afterSetPassword(res);
    });
  },

  updatePassword(data: any) {
    return ApiService.patch(AdminApiResources.setPassword, data).then(res => {
      return this.afterSetPassword(res);
    });
  },

  afterSetPassword(res: any) {
    if (res.data['access_token']) {
      if (res.data.scope[0] === ApiScope.MFALoginAdmin) {
        TokenService.saveToken(ApiScope.MFALoginAdmin, res.data['access_token']);
        return res.data;
      } else if (res.data.scope[0] === ApiScope.Admin) {
        TokenService.saveToken(ApiScope.Admin, res.data['access_token']);
      }
    }
    return res.data;
  },

  getPermissions() {
    return ApiService.get(AdminApiResources.permissions);
  },

  resetMFAMethod(data: any) {
    return ApiService.post(AdminApiResources.resetMFA, data).then(res => {
      if (res.data['access_token']) {
        if (res.data.scope[0] === ApiScope.MFALoginAdmin) {
          TokenService.saveToken(ApiScope.MFALoginAdmin, res.data['access_token']);
          return res.data;
        }
      }
      return res.data;
    });
  },
};

export default UserService;
