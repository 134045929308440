import ApiService from '@/services/_helper/api.service';
import AdminApiResources from '@/constants/api/resources/wizard';
import FileService from '../_helper/file.service';
import {PaginationGroupInterface} from '@/types/api/common/paginationGroupInterface';
import {PerPage} from '@/constants/paginationEnums';
import {BudgetHistoryInterface} from '@/types/api/wizard/campaigns/budgetInterface';

const CampaignService = {
  getCampaign(page: number) {
    return ApiService.get(AdminApiResources.campaigns, {
      params: {
        page,
        per_page: PerPage.default,
      },
    });
  },
  getCampaignsBookings(params: PaginationGroupInterface) {
    return ApiService.get(AdminApiResources.campaignsBookings, {params});
  },
  getCampaignsDetails(campaign_id: number) {
    return ApiService.get(AdminApiResources.campaignsDetails, {
      params: {campaign_id},
    });
  },
  getBudget(campaign_id: number) {
    return ApiService.get(AdminApiResources.campaignsBudget, {
      params: {campaign_id},
    });
  },
  changeBudget(data: {campaign_id: number; new_budget: number}) {
    return ApiService.patch(AdminApiResources.campaignsBudget, data);
  },
  getBudgetHistoryList(data: BudgetHistoryInterface) {
    return ApiService.get(AdminApiResources.getBudgetHistoryList, {
      params: {
        ...data,
        per_page: PerPage.default,
      },
    });
  },
  createCampaign(data: any) {
    return ApiService.post(AdminApiResources.campaigns, data);
  },
  editCampaign(data: any) {
    return ApiService.put(AdminApiResources.campaigns, data);
  },
  deleteCampaign(campaign_id: number) {
    return ApiService.delete(AdminApiResources.campaigns, {
      params: {campaign_id},
    });
  },
  campaignsActivation(data: {campaign_id: number; is_active: boolean}) {
    return ApiService.put(AdminApiResources.campaignsActivation, data);
  },
  toggleCampaignState(data: any) {
    return ApiService.patch(AdminApiResources.toggleCampaignState, data);
  },
  downloadCSV() {
    return ApiService.get(AdminApiResources.campaignsCSV, {
      headers: {
        Accept: 'text/csv; charset=utf-8',
        'Content-Type': 'text/csv; charset=utf-8',
      },
      responseType: 'text',
    }).then((res) => {
      FileService.saveCSV(res.data, 'go_to_campaign.csv');
      return res;
    });
  },
};

export default CampaignService;
