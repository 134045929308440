import Vue from 'vue';

Vue.mixin({
  methods: {
    toggleBodyClass(): void {
      const isFixed = document.body.classList.contains('body--fixed');
      if (!isFixed) {
        document.body.classList.add('body--fixed');
      } else {
        document.body.classList.remove('body--fixed');
      }
    },
  },
});
