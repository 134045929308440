import Vue from 'vue';
import {BookingDataInterface, BookingUserInterface} from '@/types/bookingInterface';
import functionsWithPrefectures from '@/mixins/functionsWithPrefectures';
import {locale} from 'moment/moment';

Vue.mixin({
  mixins: [functionsWithPrefectures],
  methods: {
    setBookingState(bookingDetails: any): void {
      bookingDetails['use_discount'] = !!bookingDetails.discount_amount && bookingDetails.discount_amount > 0;
      this.$store.dispatch('landing/booking/setBookingDetails', bookingDetails);
      const customerKeys: Array<string> = ['name', 'email', 'phone', 'address', 'prefecture_code'];
      const guestsKeys: Array<string> = bookingDetails.guest_name ? ['customer', 'guest'] : ['customer'];
      guestsKeys.forEach((guest: string, index: number) => {
        const params: BookingUserInterface | any = {};
        customerKeys.forEach((key: string) => {
          const combinedKey = `${guest}_${key}`;
          if (bookingDetails.hasOwnProperty(combinedKey)) {
            params[key] = bookingDetails[combinedKey];
          }
        });
        if (!params.prefecture_code) {
          params.prefecture_code = '0';
        }
        if (guest === 'customer') {
          if (bookingDetails.customer_surname) {
            params['surname'] = bookingDetails.customer_surname;
          }
          this.$store.dispatch('landing/booking/setCustomer', params);
          this.$store.dispatch('landing/booking/setCustomerBackup', params);
        } else {
          if (bookingDetails.guest_surname) {
            params['surname'] = bookingDetails.guest_surname;
          }
          this.$store.dispatch('landing/booking/setGuest', params);
        }
      });
      const bookingData: BookingDataInterface | any = {
        room_type_hid: bookingDetails.rate_plan_hid,
        incl_breakfast: bookingDetails.incl_breakfast,
        checkin: bookingDetails.checkin,
        checkout: bookingDetails.checkout,
        guests_number: bookingDetails.guests_number,
        rooms_number: bookingDetails.rooms_number,
      };
      this.$store.dispatch('landing/booking/setBooking', {...bookingData});
      const roomTypes = {...bookingData};
      delete roomTypes.room_type_hid;
      delete roomTypes.incl_breakfast;
      roomTypes['hotel_hid'] = bookingDetails.hotel_hid;
      this.$store.dispatch('landing/hotel/getRoomTypeInfo', roomTypes).then((res) => {
        if (res.data && res.data.length) {
          const filtered_room = res.data.filter(
            (f: any) =>
              f.rate_plans.length &&
              f.rate_plans.map((m: any) => m.rate_plan_hid).includes(bookingDetails.rate_plan_hid),
          );
          if (filtered_room.length) {
            this.$store.commit('landing/hotel/setRoomInfo', filtered_room[0]);
          }
        }
      });
    },
  },
});
