import moment from 'moment';
import {Module} from 'vuex';

import {DateFormat} from '@/constants/dateFormat';
import {LandingBookingStoreInterface} from '@/types/store/bookingStoreInterface';
import {BookingDataInterface, BookingDetailsInterface, BookingUserInterface} from '@/types/bookingInterface';
import BookingService from '@/services/landing/booking.service';
import {BookingPaymentMethod} from '@/constants/paymentSystem';
import {CheckCampaignActivationCodeInterface} from '@/types/api/landing/checkCampaignActivationCodeInterface';

export const booking_data_default = {
  room_type_hid: '',
  incl_breakfast: false,
  checkin: moment(moment().add(1, 'days')).format(DateFormat.VueDate),
  checkout: moment(moment().add(2, 'days')).format(DateFormat.VueDate),
  guests_number: 1,
  rooms_number: 1,
};

export const customer_data_default = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  zip_code: '',
  address: '',
  prefecture_code: '',
};

export const booking_details_default = {
  booking_code: '',
  payment_method: '',
  total_amount: 0,
  use_discount: false,
};

const getDefaultState = () => {
  return {
    initial: booking_data_default,
    data: booking_data_default,
    customer: customer_data_default,
    guest: customer_data_default,
    customerBackup: customer_data_default,
    bookingDetails: booking_details_default,
    genders: {
      male: 0,
      female: 0,
    },
    isUnitedPlan: false,
    selectedPaymentMethod: null,
    bedsType: '',
    discountCouponCode: '',
    billingContract: {},
  };
};

const bookingStore: Module<LandingBookingStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setInitBooking(state, data) {
      if (data.checkin.indexOf('/') === -1) {
        data.checkin = moment(data.checkin).format(DateFormat.VueDate);
      }
      if (data.checkout.indexOf('/') === -1) {
        data.checkout = moment(data.checkout).format(DateFormat.VueDate);
      }
      state.initial = {...state.initial, ...data};
    },
    setBooking(state, data) {
      state.data = data;
    },
    setCustomer(state, data) {
      state.customer = data;
    },
    setGuest(state, data) {
      state.guest = data;
    },
    setCustomerBackup(state, data) {
      state.customerBackup = data;
    },
    setGenderCount(state, data) {
      state.genders = data;
    },
    setBookingDetails(state, data) {
      state.bookingDetails = data;
    },
    setDiscountStatus(state, data) {
      state.bookingDetails.use_discount = data;
    },
    setPaymentMethod(state, data) {
      state.selectedPaymentMethod = data;
    },
    setBedsType(state, payload: string) {
      state.bedsType = payload;
    },
    setRefundState(state, data: boolean) {
      state.selectedRefundState = data;
    },
    setRatePlanHid(state, data: string) {
      state.selectedRatePlanHid = data;
    },
    setUnitedPlan(state, payload: boolean) {
      state.isUnitedPlan = payload;
    },
    setDiscountCouponCode(state, payload: string) {
      state.discountCouponCode = payload;
    },
    setBillingData(state, payload: any) {
      state.billingContract = payload;
    },
    resetBookingDetails(state) {
      state.bookingDetails = booking_details_default;
    },
    resetCustomerData(state: any) {
      state.customer = customer_data_default;
      state.guest = customer_data_default;
      state.customerBackup = customer_data_default;
      state.bookingDetails = booking_details_default;
      state.genders = {
        male: 0,
        female: 0,
      };
      state.isUnitedPlan = false;
      state.selectedPaymentMethod = null;
      state.bedsType = '';
      state.discountCouponCode = '';
    },
  },
  // @ts-ignore
  actions: {
    setBooking({commit}, data: BookingDataInterface = booking_data_default) {
      commit('setBooking', data);
    },
    setCustomer({commit}, data: BookingUserInterface = customer_data_default) {
      commit('setCustomer', data);
    },
    setGuest({commit}, data: BookingUserInterface = customer_data_default) {
      commit('setGuest', data);
    },
    setCustomerBackup({commit}, data: BookingUserInterface = customer_data_default) {
      commit('setCustomerBackup', data);
    },
    setBookingDetails({commit}, data: BookingDetailsInterface = booking_details_default) {
      commit('setBookingDetails', data);
    },
    setDiscountStatus({commit}, data: boolean) {
      commit('setDiscountStatus', data);
    },
    setPaymentMethod({commit}, payment: BookingPaymentMethod) {
      commit('setPaymentMethod', payment);
    },
    checkCampaignActivationCode(context, data: CheckCampaignActivationCodeInterface) {
      return BookingService.checkCampaignActivationCode(data);
    },
    createBooking(context, data) {
      return BookingService.createBooking(data);
    },
    createExpBooking(context, data) {
      return BookingService.createExpBooking(data);
    },
    verifyBooking(context, data) {
      return BookingService.verifyBooking(data);
    },
    rejectBookingRequest(context, id) {
      return BookingService.rejectBookingRequest(id);
    },
    showBookingDetails(context, data) {
      return BookingService.showBookingDetails(data);
    },
    authorizeCharge(context, data) {
      return BookingService.authorizeCharge(data);
    },
    smsVerification(context, data) {
      return BookingService.smsVerification(data);
    },
    smsCodeVerification(content, data) {
      return BookingService.smsCodeVerification(data);
    },
    passwordInputScreenGMO(context, data) {
      return BookingService.passwordInputScreenGMO(data.url, data.params);
    },
    resetState({commit, dispatch}, booking_data: BookingDataInterface = booking_data_default) {
      commit('setInitBooking', booking_data);
      dispatch('setBooking');
      commit('resetCustomerData');
    },
    getCustomerData() {
      return BookingService.getCustomerData();
    },
    paymentOptions(context, link: string[]) {
      return BookingService.paymentOptions(link);
    },
  },
};

export default bookingStore;
