import {Module} from 'vuex';

import {DashboardStoreInterface} from '@/types/store/dashboardStoreInterface';
import bookingStore from './modules/booking';
import hotelStore from './modules/hotel';

const getDefaultState = () => {
  return {
    settings: {
      booking_request_expiration_in: 0,
      amenities: [],
      prefectures: {},
      account_source: undefined,
      payment_system: undefined,
      locales: {},
    },
  };
};

const store: Module<DashboardStoreInterface, any> = {
  namespaced: true,
  modules: {
    booking: bookingStore,
    hotel: hotelStore,
  },
  state: getDefaultState(),
  mutations: {
    setSettings(state, settings) {
      state.settings = {...state.settings, ...settings};
    },
    setContextSettings(state, settings) {
      state.settings = {...state.settings, ...settings};
    },
  },
  actions: {
    init({state, dispatch}) {
      const defaultState = getDefaultState();
      const defaultKeys = Object.keys(defaultState.settings).sort();
      const currentKeys = Object.keys(state.settings).sort();
      defaultKeys.forEach((key: string) => {
        if (!currentKeys.includes(key)) {
          dispatch('clearAll');
          return;
        }
      });
    },
    clearAll({commit}) {
      commit('booking/resetState');
      commit('hotel/resetState');
    },
  },
};

export default store;
