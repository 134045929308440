import {Module} from 'vuex';
import ReviewService from '@/services/management/review.service';
import {
  FilterReviewInterface,
  ReviewDetailsInterface,
  ReviewInterface,
  ReviewStoreInterface,
} from '@/types/store/management/reviewStoreInterface';
import {PaginationParams} from '@/types/pages/common/paginationParams';

const getDefaultState = () => {
  return {
    reviewList: {
      data: [],
      filter: [],
      total: 0,
      loading: false,
    },
    reviewDetails: {
      data: {} as ReviewDetailsInterface,
      loading: false,
    },
  };
};

enum reviewMutationPath {
  reviewList = 'reviewList',
  reviewDetails = 'reviewDetails',
}

const reviewStore: Module<ReviewStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    loading(state, payload: {path: reviewMutationPath; loading: boolean}) {
      state[payload.path].loading = payload.loading;
    },
    reviewLoaded(state, payload: ReviewInterface[]) {
      state.reviewList.data = payload;
    },
    reviewDetailsLoaded(state, payload: ReviewDetailsInterface) {
      state.reviewDetails.data = payload;
    },
    setFilter(state, payload: FilterReviewInterface[]) {
      state.reviewList.filter = payload;
    },
    totalPages(state, payload: number) {
      state.reviewList.total = payload;
    },
  },
  actions: {
    getList({commit}, data: {params: PaginationParams}) {
      commit('setFilter', data);
      commit('loading', {path: reviewMutationPath.reviewList, loading: true});
      ReviewService.getHotelReviews(data)
        .then((res) => {
          commit('reviewLoaded', res.data.data);
          commit('totalPages', res.data.total);
        })
        .finally(() => {
          commit('loading', {path: reviewMutationPath.reviewList, loading: false});
        });
    },
    getReviewDetails({commit}, data: {id: number}) {
      commit('loading', {path: reviewMutationPath.reviewDetails, loading: true});
      ReviewService.getReviewDetails(data)
        .then((res) => {
          if (res.data) {
            commit('reviewDetailsLoaded', res.data);
          }
        })
        .finally(() => {
          commit('loading', {path: reviewMutationPath.reviewDetails, loading: false});
        });
    },
    publishReview({dispatch, state}, data: {id: number}) {
      ReviewService.publishReview(data).then(() => {
        dispatch('getList', state.reviewList.filter);
        dispatch('getReviewDetails', data);
      });
    },
    unpublishReview({dispatch, state}, data: {id: number}) {
      ReviewService.unpublishReview(data).then(() => {
        dispatch('getList', state.reviewList.filter);
        dispatch('getReviewDetails', data);
      });
    },
    sentReviewReplies({commit, dispatch, state}, data: {review_id: number; text: string}) {
      commit('loading', {path: reviewMutationPath.reviewList, loading: true});
      ReviewService.sentReviewReplies(data)
        .then((resp) => {
          if (resp.data) {
            dispatch('getList', state.reviewList.filter);
          }
        })
        .catch((error: any) => {
          dispatch('getList', state.reviewList.filter);
          return error;
        })
        .finally(() => {
          commit('loading', {path: reviewMutationPath.reviewList, loading: false});
        });
    },
    editReviewReplies({commit, dispatch, state}, data: {review_id: number; text: string}) {
      commit('loading', {path: reviewMutationPath.reviewList, loading: true});
      ReviewService.editReviewReplies(data)
        .then((resp) => {
          if (resp.data.success) {
            dispatch('getList', state.reviewList.filter);
          }
        })
        .catch((error: any) => {
          dispatch('getList', state.reviewList.filter);
          return error;
        })
        .finally(() => {
          commit('loading', {path: reviewMutationPath.reviewList, loading: false});
        });
    },
  },
};

export default reviewStore;
