import ApiService from '../_helper/api.service';
import ManagementApiResources from '../../constants/api/resources/management';
import {PerPage} from '@/constants/paginationEnums';
import {PaginationParams} from '@/types/pages/common/paginationParams';

const ReviewService = {
  getHotelReviews(data: {params: PaginationParams}) {
    data.params.per_page = PerPage.default;
    return ApiService.get(ManagementApiResources.getHotelReviews, data);
  },
  getReviewDetails(data: {id: number}) {
    return ApiService.get(ManagementApiResources.getReviewDetails, {params: data});
  },
  publishReview(data: {id: number}) {
    return ApiService.put(ManagementApiResources.publishReview, data);
  },
  unpublishReview(data: {id: number}) {
    return ApiService.put(ManagementApiResources.unpublishReview, data);
  },
  sentReviewReplies(data: {review_id: number; text: string}) {
    return ApiService.post(ManagementApiResources.reviewReplies, data);
  },
  editReviewReplies(data: {review_id: number; text: string}) {
    return ApiService.patch(ManagementApiResources.reviewReplies, data);
  },
};

export default ReviewService;
