import {VueRouter} from 'vue-router/types/router';
import {Route} from 'vue-router';

declare module 'vue/types/vue' {
  interface Vue {
    checkPageRefreshState(route: Route, router: VueRouter): void;
  }
}
export const routeEventsMixin = {
  methods: {
    checkPageRefreshState(route: Route, router: VueRouter): void {
      if (route.name) {
        if (['hotel-landing', 'search-area-old', 'search-area'].includes(route.name)) {
          router.go(0);
        }
      }
    },
  },
};
