import ApiService from '../_helper/api.service';
import AdminApiResources from '../../constants/api/resources/wizard';
import {PerPage} from '@/constants/paginationEnums';
import {RakutenInterface} from '@/types/api/wizard/rakuten/rakutenInterface';

const RakutenService = {
  getList(page: number) {
    return ApiService.get(AdminApiResources.rakuten, {params: {page, per_page: PerPage.default}});
  },

  createRakutenType(data: RakutenInterface) {
    return ApiService.post(AdminApiResources.rakuten, data);
  },

  updateRakutenType(params: RakutenInterface) {
    return ApiService.put(AdminApiResources.rakuten, params);
  },

  activateRakutenType(params: {id: number; is_active: boolean}) {
    return ApiService.put(AdminApiResources.activateRakuten, params);
  },

  deleteRakutenType(params: {id: number}) {
    return ApiService.delete(AdminApiResources.rakuten, {params});
  },
};

export default RakutenService;
