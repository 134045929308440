import {Module} from 'vuex';
import CustomerService from '../../../../services/customer/customer.service';
import {SearchStoreInterface} from '@/types/store/customer/search/searchStoreInterface';
import {SearchParamsInterface} from '@/types/store/customer/search/searchParamsInterface';
import {GoogleAutocompleteInterface} from '@/types/store/customer/search/googleAutocompleteInterface';
import {PriceFilterInterface} from '@/types/store/customer/search/priceFilterInterface';
import {FacilityFilterInterface} from '@/types/store/customer/search/facilityFilterInterface';

export const autocomplete_params_default: GoogleAutocompleteInterface = {
  place_id: '',
  name: '',
  address_components: [],
  geometry: [],
};

export const search_params_default: SearchParamsInterface = {
  checkin: '',
  checkout: '',
  guests_number: 1,
  autocomplete_params: autocomplete_params_default,
};

const getDefaultState = () => {
  return {
    autocompleteAddress: [
      {
        formatted_address: '日本、東京都東京',
        place_id: 'ChIJXSModoWLGGARILWiCfeu2M0',
      },
      {
        formatted_address: '日本、大阪府大阪市',
        place_id: 'ChIJ4eIGNFXmAGAR5y9q5G7BW8U',
      },
      {
        formatted_address: '日本、京都府京都市',
        place_id: 'ChIJ8cM8zdaoAWARPR27azYdlsA',
      },
      {
        formatted_address: '日本、北海道札幌市',
        place_id: 'ChIJMzaXWnXUCl8R1bqHRp1-kzM',
      },
      {
        formatted_address: '日本、沖縄県',
        place_id: 'ChIJ51ur7mJw9TQR79H9hnJhuzU',
      },
    ],
    search_params: search_params_default,
    price_filter: {
      min: 0,
      max: 0,
    } as PriceFilterInterface,
    breadcrumbs: [],
    facilities: {
      smoking: [],
      hotel: [],
      room: [],
    } as FacilityFilterInterface,
  };
};

const searchStore: Module<SearchStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setSearchParams(state, payload) {
      state.search_params = {...payload};
    },
    setDatePickerParams(state, payload: {checkin: string; checkout: string}) {
      state.search_params.checkin = payload.checkin;
      state.search_params.checkout = payload.checkout;
    },
    setCheckinDate(state, payload: string) {
      state.search_params.checkin = payload;
    },
    setCheckoutDate(state, payload: string) {
      state.search_params.checkout = payload;
    },
    setGuestsParams(state, payload: number) {
      state.search_params.guests_number = payload;
    },
    setPlaceId(state, payload: string) {
      state.search_params.autocomplete_params.place_id = payload;
    },
    setAutocompleteParams(state, payload: GoogleAutocompleteInterface) {
      state.search_params.autocomplete_params = payload;
    },
    setPriceFilter(state, payload: PriceFilterInterface) {
      state.price_filter = payload;
    },
    setFacilitiesFilter(state, payload: FacilityFilterInterface) {
      state.facilities = payload;
    },
    resetPriceFilter(state) {
      state.price_filter = {
        min: 0,
        max: 0,
      };
    },
    resetFacilitiesFilter(state) {
      state.facilities = {
        smoking: [],
        hotel: [],
        room: [],
      };
    },
  },
  getters: {
    getAddressComponents: (state) => state.search_params.autocomplete_params.address_components!,
  },
  actions: {
    places(_context, {place_id, locale}) {
      return CustomerService.getPlaceDetails(place_id, locale);
    },
    setCheckinDate({commit}, checkin: string) {
      commit('setCheckinDate', checkin);
    },
    setCheckoutDate({commit}, checkout: string) {
      commit('setCheckoutDate', checkout);
    },
    resetAutocomplete({commit}) {
      commit('setAutocompleteParams', autocomplete_params_default);
    },
    resetState({commit}) {
      commit('setSearchParams', search_params_default);
      commit('resetPriceFilter');
      commit('resetFacilitiesFilter');
    },
  },
};

export default searchStore;
