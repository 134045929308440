import {Module} from 'vuex';
import {AccountStoreInterface} from '@/types/store/accountStoreInterface';
import {AccountInterface} from '@/types/accountInterface';
import ClientService from '@/services/wizard/client.service';

const getDefaultState = () => {
  return {
    list: [],
    total: 0,
    listLoading: false,
  };
};

const userStore: Module<AccountStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    accountsLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    accountsLoaded(state, payload: AccountInterface[]) {
      state.list = payload;
    },
    accountsTotal(state, payload: number) {
      state.total = payload;
    },
  },
  actions: {
    getList({commit}, data: any): any {
      commit('accountsLoading', true);
      return ClientService.getAccounts(data)
        .then((res: any) => {
          commit('accountsLoaded', res.data.data);
          commit('accountsTotal', res.data.total);
          return res.data;
        })
        .catch((error: any) => {
          return error;
        })
        .finally(() => {
          commit('accountsLoading', false);
        });
    },
    getInfo(state, id: number): any {
      return ClientService.getAccountDetails(id);
    },
    updateInfo(state, data: object): any {
      return ClientService.updateAccount(data);
    },
    createUser(context, data): any {
      return ClientService.createAccount(data);
    },
    sendInvitation(context, id: number): any {
      return ClientService.sendInvitation(id);
    },
    sendPasswordUpdate(context, id: number): any {
      return ClientService.sendPasswordUpdate(id);
    },
    impersonate({dispatch}, id: number): any {
      return ClientService.impersonate(id).then((data: any) => {
        return dispatch('management/impersonate/setUserData', data, {root: true}).then(() => {
          window.location.href = window.location.origin + '/management/';
        });
      });
    },
  },
};

export default userStore;
