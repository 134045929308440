import Vue from 'vue';

Vue.mixin({
  methods: {
    getValidationClass(formName: string, fieldName: string, arrayName?: string, arrayIndex?: number): any {
      if (
        !this.$v || !this.$v[formName] || (arrayName! && arrayIndex! >= 0)
          ? !this.$v[formName][arrayName!]!.$each![arrayIndex!]
          : false
      ) {
        return false;
      }
      let field: any =
        arrayName! && arrayIndex! >= 0 ? this.$v[formName][arrayName!]!.$each![arrayIndex!] : this.$v[formName];
      const keys = fieldName.split('.');
      keys.forEach((key) => {
        field = field[key];
      });
      if (field) {
        return {
          'md-invalid': field.$invalid && !field.$pending && field.$dirty,
        };
      }
    },
    forceValidation(formName: string, fieldName: string): void {
      if (!this.$v || !this.$v[formName]) {
        return;
      }
      let field: any = this.$v[formName];
      const keys = fieldName.split('.');
      keys.forEach((key) => {
        field = field[key];
      });
      if (field) {
        field.$touch();
      }
    },
    validateBeforeSubmit(formName: string, arrayName?: string, arrayLength?: number): boolean {
      if (!this.$v || !this.$v[formName]) {
        return false;
      }
      if (arrayName! && arrayLength! >= 0) {
        const item = this.$v[formName][arrayName!];
        for (let i = 0; i < arrayLength!; i++) {
          if (!item!.$each![i]) {
            return false;
          }
        }
        for (let i = 0; i < arrayLength!; i++) {
          item!.$each![i]!.$touch();
        }
        return !(item!.$pending || item!.$error);
      } else {
        const form = this.$v[formName];
        form.$touch();
        return !(form.$pending || form.$error);
      }
    },
  },
});
