import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import calendarStore from './modules/calendar';
import dashboardStore from './modules/dashboard';
import landingStore from './modules/landing';
import customerStore from './modules/customer';
import managementStore from './modules/management';
import wizardStore from './modules/wizard/';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    calendar: calendarStore,
    dashboard: dashboardStore,
    landing: landingStore,
    customer: customerStore,
    management: managementStore,
    wizard: wizardStore,
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.RAILS_ENV !== 'production',
  plugins: [
    createPersistedState({
      paths: [
        'calendar',
        'dashboard',
        'landing',
        'customer',
        'management.settings',
        'management.user',
        'management.impersonate',
        'management.sidebarCollapsed',
        'management.lastSignSession',
        'wizard.settings',
        'wizard.user',
        'wizard.sidebarCollapsed',
        'wizard.landingCMS',
      ],
    }),
  ],
  actions: {
    clearAll({dispatch}) {
      dispatch('dashboard/clearAll');
      dispatch('landing/clearAll');
      dispatch('management/clearAll');
      dispatch('wizard/clearAll');
    },
  },
});

if (module.hot) {
  module.hot.accept(
    [
      './modules/calendar',
      './modules/dashboard',
      './modules/landing',
      './modules/customer',
      './modules/management',
      './modules/wizard',
    ],
    () => {
      store.hotUpdate({
        modules: {
          calendar: require('./modules/calendar').default,
          dashboard: require('./modules/dashboard').default,
          landing: require('./modules/landing').default,
          customer: require('./modules/customer').default,
          management: require('./modules/management').default,
          wizard: require('./modules/wizard').default,
        },
      });
    },
  );
}

export default store;
