import ApiService from '@/services/_helper/api.service';
import AdminApiResources from '@/constants/api/resources/wizard';
import apiService from '@/services/_helper/api.service';

const LandingCMSService = {
  getHotelGroups() {
    return ApiService.get(AdminApiResources.landingCMSHotelGroups);
  },
  postHotelGroup(data: string) {
    return ApiService.post(AdminApiResources.landingCMSHotelGroups, data);
  },
  putHotelGroup(data: any) {
    const params = {id: String(data.id), title: data.title};
    return ApiService.put(AdminApiResources.editHotelGroup, params);
  },
  publishHotelGroup(id: any) {
    const params = {id: String(id)};
    return ApiService.put(AdminApiResources.publishHotelGroup, params);
  },
  unpublishHotelGroup(id: any) {
    const params = {id: String(id)};
    return ApiService.put(AdminApiResources.unpublishHotelGroup, params);
  },
  deleteHotelGroup(id: any) {
    const params = {params: {id: id}};
    return ApiService.delete(AdminApiResources.landingCMSHotelGroups, params);
  },
  getHotelBlock(data: string) {
    const params = {params: {hotel_secret_token: data}};
    return ApiService.get(AdminApiResources.getHotelBlock, params);
  },
  postHotelBlock(data: any) {
    return ApiService.post(AdminApiResources.hotelBlock, data);
  },
  deleteHotelBlock(id: any) {
    const params = {params: {id: id}};
    return ApiService.delete(AdminApiResources.hotelBlock, params);
  },
  putHotelBlock(data: any) {
    return ApiService.put(AdminApiResources.hotelBlock, data);
  },
  hotelGroupOrder(data: any) {
    return ApiService.put(AdminApiResources.hotelGroupOrder, data);
  },
  hotelBlockOrder(data: any) {
    return ApiService.put(AdminApiResources.hotelBlockOrder, data);
  },
};

export default LandingCMSService;
