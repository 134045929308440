import {Module} from 'vuex';
import {HotelGroupStoreInterface} from '@/types/store/hotelGroupStoreInterface';
import {HotelGroupInterface, HotelGroupItemInteface, UngroupedHotelItemInterface} from '@/types/hotelGroupInterface';
import HotelGroupService from '@/services/wizard/hotelGroup.service';

const getDefaultState = () => {
  return {
    list: [],
    total: 0,
    listLoading: false,
    ungroupedTotal: null,
    ungroupedHotels: [],
    current: null,
  };
};

const hotelGroupStore: Module<HotelGroupStoreInterface, any> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state: any) {
      const s: any = getDefaultState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    hotelGroupsLoading(state, payload: boolean) {
      state.listLoading = payload;
    },
    hotelGroupsLoaded(state, payload: HotelGroupInterface[]) {
      state.list = payload;
    },
    hotelGroupsTotal(state, payload: number) {
      state.total = payload;
    },
    ungroupedHotelsTotal(state, payload: number) {
      state.ungroupedTotal = payload;
    },
    ungroupedHotels(state, payload: UngroupedHotelItemInterface[]) {
      state.ungroupedHotels = payload;
    },
    current(state, payload: HotelGroupInterface) {
      state.current = payload;
    },
  },
  getters: {
    getSelectedHotels({current}): HotelGroupItemInteface[] {
      if (current) {
        return [...current.accounts].reduce((a, b) => a.concat(b.hotels!), [] as HotelGroupItemInteface[]);
      }
      return [];
    },
  },
  actions: {
    getList({commit}, data: any): any {
      commit('hotelGroupsLoading', true);
      return HotelGroupService.getHotelGroups(data)
        .then((res: any) => {
          commit('hotelGroupsLoaded', res.data.hotel_groups);
          commit('hotelGroupsTotal', res.data.total);
          return res.data.hotel_groups;
        })
        .catch((error: any) => {
          return error;
        })
        .finally(() => {
          commit('hotelGroupsLoading', false);
        });
    },
    getUngroupedList({commit}, data: any): any {
      return HotelGroupService.getUngroupedHotelsList(data)
        .then(({data}) => {
          if (data) {
            commit('ungroupedHotelsTotal', data.total);
            commit('ungroupedHotels', data.data);
          }
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    },
    filterUngroupedList(context, data: any): any {
      return HotelGroupService.getUngroupedHotelsList(data);
    },
    create(context, data): any {
      return HotelGroupService.createHotelGroup(data)
        .then((res: any) => {
          return res.data;
        })
        .catch((error: any) => {
          return {error: error};
        });
    },
    update({commit}, data): any {
      return HotelGroupService.updateHotelGroup(data)
        .then(({data}) => {
          commit('current', data);
          return data;
        })
        .catch((error: any) => {
          return {error: error};
        });
    },
    delete(context, data): any {
      return HotelGroupService.delete(data);
    },
    getInvoice(context, data): any {
      return HotelGroupService.getInvoice(data);
    },
    getReport(context, data): any {
      return HotelGroupService.getReport(data);
    },
    resendInvoice(context, data): any {
      return HotelGroupService.resendInvoice(data);
    },
    removeHotel(context, data): any {
      return HotelGroupService.removeHotel(data.groupId, data.hotelId);
    },
    getInfo({commit}, data): any {
      return HotelGroupService.getInfo(data)
        .then(({data}) => {
          commit('current', data);
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    },
  },
};

export default hotelGroupStore;
